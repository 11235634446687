import { ParsedUrlQueryInput } from 'querystring';

import { createSelector } from 'reselect';

import api from '../../api';
import { Dispatch, PaginatedData, State } from '../../types';
import { Produto } from '../../types/api';
import { mergeIndexedBy } from '../../util/listas';

export const DADOS_RECEBIDOS = 'transacionais/produtos/DADOS_RECEBIDOS';
export const PRODUTO_RECEBIDO = 'transacionais/produtos/PRODUTO_RECEBIDO';

const INITIAL_STATE = {
  porId: {} as Record<string, Produto>,
  porCodigo: {} as Record<string, Produto>,
} as const;

type ProdutosActions = ReturnType<typeof dadosRecebidos | typeof produtoRecebido>;

export default function reducer(state = INITIAL_STATE, action?: ProdutosActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case DADOS_RECEBIDOS:
      return {
        ...state,
        porId: mergeIndexedBy(state.porId, action.dados.items, '_id'),
        porCodigo: mergeIndexedBy(state.porCodigo, action.dados.items, 'codigo'),
      };
    case PRODUTO_RECEBIDO:
      return {
        ...state,
        porId: mergeIndexedBy(state.porId, [action.dados], '_id'),
        porCodigo: mergeIndexedBy(state.porCodigo, [action.dados], 'codigo'),
      };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.transacionais.produtos;

export const todosSelector = createSelector(rootSelector, (root) => Object.values(root.porId));

export const porIdSelector = createSelector(rootSelector, (root) => root.porId);

export const porCodigoSelector = createSelector(rootSelector, (root) => root.porCodigo);

// ACTION CREATORS

export function dadosRecebidos(dados: PaginatedData<Produto>) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

export function produtoRecebido(dados: Produto) {
  return { type: PRODUTO_RECEBIDO, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query?: ParsedUrlQueryInput) {
  return async function (dispatch: Dispatch) {
    const r = await api.produtos.index(query); // chama a API
    dispatch(dadosRecebidos(r)); // armazena sucesso na store

    return r;
  };
}

export function carregaProduto(id: string) {
  return async function (dispatch: Dispatch) {
    const r = await api.produtos.show(id); // chama a API
    dispatch(produtoRecebido(r)); // armazena sucesso na store
    return r;
  };
}

export function cria(dados: Partial<Produto>) {
  return async function (dispatch: Dispatch) {
    const r = await api.produtos.create(dados); // chama a API
    dispatch(produtoRecebido(r)); // armazena sucesso na store
    return r;
  };
}

export function atualiza(produto: Partial<Produto>) {
  return async function (dispatch: Dispatch) {
    const r = await api.produtos.update(produto); // chama a API
    dispatch(produtoRecebido(r)); // armazena sucesso na store
    return r;
  };
}
