import { ParsedUrlQueryInput } from 'querystring';
import url from 'url';

import {
  Cadastro,
  ConfiguracoesEmitente,
  ContaERegistrosRelacionados,
  Contas,
  Destinatario,
  Emissao,
  Emitente,
  ExclusaoRegistro,
  FormConfiguracaoEmitente,
  Inutilizacao,
  InutilizacaoERegistrosRelacionados,
  Itens,
  ItensPaginados,
  Municipio,
  Municipios,
  PaginatedContaWithNfe,
  Pais,
  Paises,
  Pong,
  Produto,
  RequisicaoCancelamento,
  Servico,
  SolicitacaoInutilizacao,
  Transportador,
  Uf,
  Ufs,
  ValidacaoCadastro,
  ValidacaoEdicaoConta,
  ValidacaoImportacao,
  WhoAmI,
} from '../types/api';
import { AmbienteNfe, ConsultaNfe, DestinatarioNfe, EmitenteNfe, EventoNfe, Nfe, NfeComAlertas, ProdutoServico, UploadNfe, ValidarNfe } from '../types/apiNfe';

import { HttpError, PopupBlockedError, ServerValidationError, UnauthorizedError } from './errors';
import { backendUri, fetchJSON, formData, json } from './fetchJSON';

export { UnauthorizedError, ServerValidationError, HttpError, PopupBlockedError };

export default {
  ping() {
    return fetchJSON('/api/ping') as Promise<Pong>;
  },
  async login() {
    return new Promise<void>(function (resolve, reject) {
      try {
        const win = window.open(backendUri('/api/login'), 'ConnectWithOAuth', 'location=0,status=0,width=1000,height=400');
        const int = window.setInterval(() => {
          if (!win) {
            window.clearInterval(int);
            reject(new PopupBlockedError('Popup de login foi bloqueada'));
          } else if (win.closed) {
            window.clearInterval(int);
            window.location.reload();
            resolve();
          }
        }, 500);
      } catch (e) {
        reject(new PopupBlockedError('Popup de login foi bloqueada'));
      }
    });
  },
  logout() {
    return fetchJSON('/api/logout') as Promise<number>;
  },
  whoami() {
    return fetchJSON('/api/whoami') as Promise<WhoAmI>;
  },

  destinatarios: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/clientes', query })) as Promise<ItensPaginados<Destinatario>>;
    },
    show(id: string) {
      return fetchJSON(`/api/clientes/${id}`) as Promise<Destinatario>;
    },
    create(cliente: Partial<Destinatario>) {
      return fetchJSON(`/api/clientes`, json('POST', { cliente })) as Promise<Destinatario>;
    },
    update(cliente: Partial<Destinatario>) {
      return fetchJSON(`/api/clientes/${cliente._id}`, json('PATCH', { cliente })) as Promise<Destinatario>;
    },
    excluir(id: string) {
      return fetchJSON(`/api/clientes/${id}`, json('DELETE', {})) as Promise<ExclusaoRegistro>;
    },
    validar(cliente: Partial<Destinatario>) {
      return fetchJSON(`/api/clientes/validar`, json('PATCH', { cliente })) as Promise<ValidacaoCadastro<Destinatario>>;
    },
    opcoesSeletor(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/clientes/opcoes-para-filtro', query })) as Promise<Destinatario[]>;
    },
    importar(dados: any) {
      return fetchJSON(`/api/clientes/importar`, formData('POST', dados)) as Promise<ValidacaoImportacao>;
    },
  },
  municipios: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/municipios', query })) as Promise<Municipios>;
    },
    show(id: string) {
      return fetchJSON(`/api/municipios/${id}`) as Promise<Municipio>;
    },
  },
  ufs: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/ufs', query })) as Promise<Ufs>;
    },
    show(id: string) {
      return fetchJSON(`/api/ufs/${id}`) as Promise<Uf>;
    },
  },
  paises: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/paises', query })) as Promise<Paises>;
    },
    show(id: string) {
      return fetchJSON(`/api/paises/${id}`) as Promise<Pais>;
    },
  },
  contas: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/contas', query })) as Promise<Contas>;
    },
    show(id: string) {
      return fetchJSON(`/api/contas/${id}`) as Promise<ContaERegistrosRelacionados>;
    },
    idsPermitidosParaCadastro(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/contas/ids-permitidos-para-cadastro', query })) as Promise<Cadastro>;
    },
    contasComQuantidadeNfe(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/contas/contas-com-quantidade-nfe', query })) as Promise<PaginatedContaWithNfe>;
    },
    update(conta: any, id: string) {
      return fetchJSON(`/api/contas/${id}`, formData('PATCH', conta)) as Promise<ValidacaoEdicaoConta>;
    },
  },
  emitentes: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/emitentes', query })) as Promise<Emitente[]>;
    },
    show(id: string) {
      return fetchJSON(`/api/emitentes/${id}`) as Promise<Emitente>;
    },
    opcoesParaFiltro(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/emitentes/opcoes-para-filtro', query })) as Promise<Emitente[]>;
    },
    configuracoes(id: string) {
      return fetchJSON(`/api/emitentes/${id}/configuracoes`) as Promise<ConfiguracoesEmitente>;
    },
    salvaConfiguracao(dados: FormConfiguracaoEmitente) {
      return fetchJSON(`/api/emitentes/${dados.emitenteId}/salva-configuracao`, json('PATCH', dados)) as Promise<Emitente>;
    },
  },
  inutilizacoes: {
    index(query: { emitenteId?: string }) {
      return fetchJSON(url.format({ pathname: '/api/inutilizacoes', query })) as Promise<ItensPaginados<Inutilizacao>>;
    },
    show(id: string) {
      return fetchJSON(`/api/inutilizacoes/${id}`) as Promise<InutilizacaoERegistrosRelacionados>;
    },
    create(dados: any) {
      return fetchJSON(`/api/inutilizacoes`, json('POST', { dados })) as Promise<SolicitacaoInutilizacao>;
    },
  },
  produtos: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/produtos', query })) as Promise<ItensPaginados<Produto>>;
    },
    show(id: string) {
      return fetchJSON(`/api/produtos/${id}`) as Promise<Produto>;
    },
    create(produto: any) {
      return fetchJSON(`/api/produtos`, json('POST', { produto })) as Promise<Produto>;
    },
    update(produto: any) {
      return fetchJSON(`/api/produtos/${produto._id}`, json('PATCH', { produto })) as Promise<Produto>;
    },
    excluir(id: string) {
      return fetchJSON(`/api/produtos/${id}`, json('DELETE', {})) as Promise<ExclusaoRegistro>;
    },
    validar(produto: any) {
      return fetchJSON(`/api/produtos/validar`, json('PATCH', { produto })) as Promise<ValidacaoCadastro<Produto>>;
    },
    importar(dados: any) {
      return fetchJSON(`/api/produtos/importar`, formData('POST', dados)) as Promise<ValidacaoImportacao>;
    },
  },
  servicos: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/servicos', query })) as Promise<ItensPaginados<Servico>>;
    },
    show(id: string) {
      return fetchJSON(`/api/servicos/${id}`) as Promise<Servico>;
    },
    create(servico: any) {
      return fetchJSON(`/api/servicos`, json('POST', { servico })) as Promise<Servico>;
    },
    update(servico: any) {
      return fetchJSON(`/api/servicos/${servico._id}`, json('PATCH', { servico })) as Promise<Servico>;
    },
    excluir(id: string) {
      return fetchJSON(`/api/servicos/${id}`, json('DELETE', {})) as Promise<ExclusaoRegistro>;
    },
    validar(servico: Partial<Servico>) {
      return fetchJSON(`/api/servicos/validar`, json('PATCH', { servico })) as Promise<ValidacaoCadastro<Servico>>;
    },
  },
  transportadores: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/transportadoras', query })) as Promise<ItensPaginados<Transportador>>;
    },
    emissao() {
      return fetchJSON(`/api/transportadoras/emissao`) as Promise<Emissao>;
    },
    show(id: string) {
      return fetchJSON(`/api/transportadoras/${id}`) as Promise<Transportador>;
    },
    create(transportador: any) {
      return fetchJSON(`/api/transportadoras`, json('POST', { transportadora: transportador })) as Promise<Transportador>;
    },
    update(transportador: any) {
      return fetchJSON(`/api/transportadoras/${transportador._id}`, json('PATCH', { transportadora: transportador })) as Promise<Transportador>;
    },
    excluir(id: string) {
      return fetchJSON(`/api/transportadoras/${id}`, json('DELETE', {})) as Promise<ExclusaoRegistro>;
    },
    validar(transportadora: Partial<Transportador>) {
      return fetchJSON(`/api/transportadoras/validar`, json('PATCH', { transportadora })) as Promise<ValidacaoCadastro<Transportador>>;
    },
  },
  nfe: {
    index(query?: ParsedUrlQueryInput) {
      return fetchJSON(url.format({ pathname: '/api/nfes', query })) as Promise<ItensPaginados<Nfe>>;
    },
    show(id: string) {
      return fetchJSON(`/api/nfes/${id}`) as Promise<Nfe>;
    },
    create() {
      return fetchJSON(`/api/nfes`, json('POST', { nfe: {} })) as Promise<Nfe>;
    },
    update(nfe: any) {
      return fetchJSON(`/api/nfes/${nfe._id}`, json('PATCH', { nfe })) as Promise<NfeComAlertas>;
    },
    emitir(nfe: any) {
      return fetchJSON(`/api/nfes/${nfe._id}/emitir`, json('PATCH', { nfe })) as Promise<NfeComAlertas>;
    },
    excluir(id: string) {
      return fetchJSON(`/api/nfes/${id}`, json('DELETE')) as Promise<ExclusaoRegistro>;
    },
    validar(nfe: any) {
      return fetchJSON(`/api/nfes/${nfe._id}/validar`, json('PATCH', { nfe })) as Promise<ValidarNfe>;
    },
    consulta(id: string) {
      return fetchJSON(`/api/nfes/${id}/consulta`) as Promise<ConsultaNfe>;
    },
    cancelar({ nfeId, ...dados }: RequisicaoCancelamento) {
      return fetchJSON(`/api/nfes/${nfeId}/cancelar`, json('PATCH', dados)) as Promise<EventoNfe>;
    },
    cartaCorrecao({ nfeId, ...dados }: { nfeId: string; correcaoASerConsiderada: string; envioXmlDestinatario: boolean; envioXmlTransportador: boolean }) {
      return fetchJSON(`/api/nfes/${nfeId}/carta_correcao`, json('PATCH', dados)) as Promise<EventoNfe>;
    },
    downloadXml(id: string) {
      const win = window.open(backendUri(`/api/nfes/${id}/download_xml`), 'DownloadXML', 'location=0,status=0,width=1000,height=400');
      if (!win) throw new PopupBlockedError('Popup de download XML foi bloqueada');
    },
    imprimirDanfe(id: string) {
      const win = window.open(backendUri(`/api/nfes/${id}/danfe`), '_self');
      if (!win) throw new PopupBlockedError('Impressão de DANFE foi bloqueada');
    },
    validarImpressaoDanfe(id: string) {
      return fetchJSON(`/api/nfes/${id}/validar-impressao-danfe`) as Promise<string>;
    },
    ambiente() {
      return fetchJSON(`/api/nfes/ambiente`) as Promise<AmbienteNfe>;
    },
    emitirUpload(dados: any) {
      return fetchJSON(`/api/nfes/emitir-upload`, formData('POST', dados)) as Promise<UploadNfe>;
    },
    destinatarios() {
      return fetchJSON(`/api/nfes/destinatarios`) as Promise<Itens<Destinatario>>;
    },
    dadosDestinatario(id: string) {
      return fetchJSON(`/api/nfes/destinatarios/${id}/dados`) as Promise<DestinatarioNfe>;
    },
    emitentes() {
      return fetchJSON(`/api/nfes/emitentes`) as Promise<Itens<Emitente>>;
    },
    dadosEmitente(id: string) {
      return fetchJSON(`/api/nfes/emitentes/${id}/dados`) as Promise<EmitenteNfe>;
    },
    produtos() {
      return fetchJSON('/api/nfes/produtos') as Promise<Itens<ProdutoServico>>;
    },
    dadosProduto(id: string) {
      return fetchJSON(`/api/nfes/produtos/${id}/dados`) as Promise<ProdutoServico>;
    },
    servicos() {
      return fetchJSON('/api/nfes/servicos') as Promise<Itens<ProdutoServico>>;
    },
    dadosServico(id: string) {
      return fetchJSON(`/api/nfes/servicos/${id}/dados`) as Promise<ProdutoServico>;
    },
  },
  protocolo: {
    downloadXml(id: string) {
      const win = window.open(backendUri(`/api/protocolos/${id}/download_xml`), 'DownloadXML', 'location=0,status=0,width=1000,height=400');
      if (!win) throw new PopupBlockedError('Popup de download XML foi bloqueada');
    },
  },
};
