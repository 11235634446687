import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import { permissaoSelector } from '../../reducers/gerais/whoami';
import type { Permissao } from '../../util/permissoes';

import HomeLogado from './HomeLogado';
import HomeSuporte from './HomeSuporte';
import HomeDeslogado from './HomeDeslogado';
import HomeSemAcesso from './HomeSemAcesso';

const POR_PERMISSAO: { [k in Permissao]: ComponentType } = {
  deslogado: HomeDeslogado,
  suporte: HomeSuporte,
  com_acesso: HomeLogado,
  sem_acesso: HomeSemAcesso,
};

export default function Home() {
  const permissao = useSelector(permissaoSelector);

  return <HomeComponent permissao={permissao} />;
}

export function HomeComponent({ permissao }: { permissao: Permissao }) {
  const Comp = POR_PERMISSAO[permissao] || HomeSemAcesso;

  return (
    <article className="container avisos">
      <Comp />
    </article>
  );
}
