/* eslint-disable operator-assignment */

import _ from 'lodash';
import moment from 'moment';

export function numberIndex(index: number | string | null) {
  switch (typeof index) {
    case 'number':
      return index;
    case 'string':
      return Number(index);
    default:
      return index;
  }
}

export function noop(v: string | null | undefined) {
  return v || '';
}

export function inteiro(v: unknown): string {
  return v ? String(v) : '';
}

export function cnpj(v: string | null | undefined): string {
  return String(v).replace(/^(..)(...)(...)(....)(..)/, '$1.$2.$3/$4-$5');
}

export function cpf(v: string | null | undefined): string {
  return String(v).replace(/^(...)(...)(...)(..)/, '$1.$2.$3-$4');
}

export function moeda(v: string | number | null | undefined): string {
  return toNumberDecimalType(v, 2);
}

export function fci(value: any) {
  if (!value) return '';

  const valorSemUnderscore = String(value).replace(/_/g, ''); // remove underscore da máscara

  if (!valorSemUnderscore.replace(/-/g, ''))
    // se valor com máscara
    return '';

  return valorSemUnderscore;
}

export function moedaValorUnitario(v: any): string {
  return toNumberDecimalType(v, 10);
}

export function aliquota(v: any): string {
  return toNumberDecimalType(v, 4);
}

export function aliquotaDecimal(v: any): string {
  return toNumberDecimalType(v, 2);
}

export function peso(v: any): string {
  return toNumberDecimalType(v, 3);
}

export function livre(v: any): string {
  if (!v) return v;

  return String(v).replace(/\r?\n|\r/g, ''); // remove quebra de linha (\r\n ou \r ou \n)
}

/**
 * Função para converter valor '12345600' para número no formato brasileiro '123.456,00'.
 * @param v é o número em formato '12345600'
 * @param numCasasDecimais define o número de digitos da parte decimal
 */
export function toNumberDecimalType(v: unknown, numCasasDecimais: number) {
  if (!v && typeof v !== 'number') return '';

  const numNegativo = Number(v) < 0;

  const comoString =
    typeof v === 'number' || (typeof v === 'string' && ['e-', 'E-'].some((el) => v.includes(el)))
      ? (typeof v === 'string' ? +v.replace(',', '.') : +v).toFixed(numCasasDecimais)
      : String(v);

  const semFormatacao = comoString.replace(/\D/g, '');
  // remove qualquer caracter que não seja dígito
  const comZeros = _.padStart(semFormatacao, numCasasDecimais + 1, '0');
  // adiciona zeros na frente, até ter pelo menos o numCasasDecimais mais um em dígitos
  const parteInt = comZeros.slice(0, -numCasasDecimais);
  // separa em duas partes: inteira e decimal
  const parteDec = comZeros.slice(-numCasasDecimais);

  return `${numNegativo ? '-' : ''}${fmtNumBr().format(+parteInt)},${parteDec}`;
}

export function decimal(v: unknown): string {
  switch (typeof v) {
    case 'number':
      return toNumberBrDecimal2(v.toFixed(2));
    case 'string':
      return v;
    default:
      return String(v || '');
  }
}

export type PessoaType =
  | { tipoDocumento: '1'; nomeRazaoSocial: string; nomeFantasia: string; cnpj: string }
  | { tipoDocumento: '2'; nomeRazaoSocial: string; nomeFantasia: string; cpf: string }
  | { tipoDocumento: '3'; nomeRazaoSocial?: string; nomeFantasia?: string; identificacaoDestinatarioEstrangeiro: string }
  | {
      tipoDocumento?: string;
      nomeRazaoSocial?: string;
      nomeFantasia?: string;
      cpf?: string;
      cnpj?: string;
      identificacaoDestinatarioEstrangeiro?: string;
    };

export function pessoa(p: PessoaType | null | undefined, nome: keyof PessoaType & ('nomeRazaoSocial' | 'nomeFantasia') = 'nomeRazaoSocial'): string {
  if (!p) return '';

  let doc: string | undefined;
  if (p.tipoDocumento === '1') doc = cnpj(p.cnpj);
  else if (p.tipoDocumento === '2') doc = cpf(p.cpf);
  else if (p.tipoDocumento === '3') doc = p.identificacaoDestinatarioEstrangeiro;

  return [doc, p[nome]].filter((v) => v).join(' - ');
}

export type PessoaEmitenteType = { razaoSocial?: string; cnpj?: string };

export function pessoaEmitente(p: PessoaEmitenteType | null | undefined): string {
  if (!p) return '';

  return [cnpj(p.cnpj), p.razaoSocial].filter((v) => v).join(' - ');
}

/**
 * Converte a data recebida do backend, no formato ISO-8601 (ou um objeto Date) para um objeto moment.
 *
 * Retorna undefined se a data for inválida.
 */
export function parseMoment(dh: (Date | string) | null | undefined) {
  let d: moment.Moment | undefined;

  if (typeof dh === 'string') d = moment(dh, moment.ISO_8601, moment.locale(), true);
  else if (dh instanceof Date) d = moment(dh);

  return d && d.isValid() ? d : undefined;
}

/**
 *  Converte a data recebida do backend para o formato utilizado na lista de nfes.
 *  recebe data: 2017-03-29T20:44:00.000Z ou 2017-03-29T17:44:00-03:00
 *  retorna: 29/03/2017 17:44
 */
export function dataHora(dh: (Date | string) | null | undefined) {
  const d = parseMoment(dh);
  return d ? d.format('DD/MM/YYYY HH:mm') : '';
}

/**
 *  Converte valor booleano para 'Sim' ou 'Não'.
 */
export function tipoBooleano(a?: boolean) {
  return a ? 'Sim' : 'Não';
}

/**
 * Função para converter valor para número no formato 123.456.
 */
export function toNumber(valor: string | number) {
  return Number(toNumberUsa(valor));
}

/**
 * Função para converter valor '123456.1234567890' para número no formato brasileiro '123.456,1234567890'.
 * Considera que o valor sempre tem um ponto como separador entre a parte inteira e a decimal.
 */
export function toNumberBrDecimal10(num?: string | number) {
  return toNumberBrDecimal(num, 10);
}

/**
 * Função para converter valor '123456.0000' ou '12345' para número no formato brasileiro '123.456,0000'.
 */
export function toNumberBrDecimal4(num?: string | number) {
  return toNumberBrDecimal(num, 4);
}

/**
 * Função para converter valor '123456.00' ou '12345' para número no formato brasileiro '123.456,00'.
 */
export function toNumberBrDecimal2(num?: string | number) {
  return toNumberBrDecimal(num, 2);
}

/**
 * Função para converter valor '123456.00' ou '12345' para número no formato brasileiro '123.456,00'.
 * @param num é o número em formatao '123456.00' ou '12345'.
 * @param numCasasDecimais define o número de digitos da parte decimal. Quando é nulo ou zero, usa 2.
 */
export function toNumberBrDecimal(num: string | number | undefined, numCasasDecimais: number) {
  if (!num) return fmtNumBr(numCasasDecimais, numCasasDecimais).format(0);

  if (typeof num === 'number') return fmtNumBr(numCasasDecimais, numCasasDecimais).format(num);

  if (Number.isNaN(+num)) return fmtNumBr(numCasasDecimais, numCasasDecimais).format(+num.replace(/[^,\d]/g, '').replace(/[,]/g, '.'));

  return fmtNumBr(numCasasDecimais, numCasasDecimais).format(+num);
}

export function ajustarDigitosParteDecimal(parteDecimal: string, numCasasDecimais: number) {
  let r = parteDecimal;

  if (r.length < numCasasDecimais) r += '0'.repeat(numCasasDecimais - r.length);
  if (r.length > numCasasDecimais) r = r.substring(0, numCasasDecimais);

  return r;
}

/**
 * Função para converter valor para número no formato '123.456'.
 */
export function toNumberUsa(valor: string | number | undefined): string {
  if (!valor) return '0';

  let valorStr = typeof valor === 'string' && ['e-', 'E-'].some((el) => valor.includes(el)) ? String((+valor).toFixed(10)) : String(valor);

  const numPontos = valorStr.split('.').length - 1;
  const numVirgulas = valorStr.split(',').length - 1;

  if (numVirgulas === 1) {
    // verifica se número está no formato 123.456,2345
    const partes = valorStr.split(',');
    // remove qualquer caracter que não seja dígito
    valorStr = `${partes[0].replace(/\D/g, '')}.${partes[1].replace(/\D/g, '')}`;
  } else if (numPontos === 1) {
    // verifica se número está no formato 123.456
    const partes = valorStr.split('.');
    // remove qualquer caracter que não seja dígito
    valorStr = `${partes[0].replace(/\D/g, '')}.${partes[1].replace(/\D/g, '')}`;
  } else {
    // remove qualquer caracter que não seja dígito
    valorStr = valorStr.replace(/\D/g, '');
  }

  if (valorStr === '') return '0';

  return valorStr;
}

/**
 * Função que retorna "codigo - mensagem".
 */
export function codMensagemComunicacao(codigo?: number, mensagem?: string) {
  if (!codigo || !mensagem) return '';

  return `${String(codigo)} - ${mensagem}`;
}

/**
 * Função que retorna "codigo - mensagem" de protocolo.
 */
export function codMensagemProtocolo(codigo?: number, mensagem?: string) {
  if (codigo && mensagem) return `${String(codigo)} - ${mensagem}`;

  if (!codigo) return mensagem || '';

  return undefined;
}

/**
 * Função para verificar se o schema é válido (não possui erros).
 * Método de verificação da validação do schema semelhante ao utilizado no
 * BG 03: Preparação para Consulta de Processamento de NF-e Emitida.
 */
export function schema(validacaoSchema?: string) {
  if (validacaoSchema && validacaoSchema.length !== 0) return 'Inválido';
  return 'Válido';
}

/**
 * Função que retorna informação do tipo de comunicação.
 */
export function tipoComunicacao(tipo?: number) {
  if (tipo === 1) return '1 - Emissão';

  if (tipo === 2) return '2 - Consulta';

  return '';
}

/**
 * Função que retorna informação do tipo de ambiente.
 */
export function tipoAmbiente(tipo?: number) {
  if (tipo === 1) return 'Produção';

  if (tipo === 2) return 'Homologação';

  return '';
}

/**
 * Função que converte data no formato DDMMAAAA para AAAA-MM-DD
 */
export function anoMesDia(dma?: string) {
  if (!dma || dma.length !== 8) return dma;

  const dia = dma.substring(0, 2);
  const mes = dma.substring(2, 4);
  const ano = dma.substring(4, 8);

  return `${ano}-${mes}-${dia}`;
}

/**
 * Função que converte data no formato AAAA-MM-DD para DDMMAAAA
 */
export function diaMesAno(amd?: string) {
  if (!amd || amd.length !== 10) return amd;

  const ano = amd.substring(0, 4);
  const mes = amd.substring(5, 7);
  const dia = amd.substring(8, 10);

  return `${dia}${mes}${ano}`;
}

export function valorFormData(v: any | null | undefined) {
  if (!v && typeof v !== 'number') return '';

  return v;
}

const numberFormatFunction = _.memoize(
  (locale: string, minimumFractionDigits = 0, maximumFractionDigits = 0) => new Intl.NumberFormat(locale, { maximumFractionDigits, minimumFractionDigits }),
  (...args: unknown[]) => args.join(':') // necessário para usar todos os argumentos na memoização
);

/**
 * Cria instância de NumberFormat com padrão brasileiro e recebe como parâmetro número minimo e máximo de casas decimais.
 */
export const fmtNumBr = _.partial(numberFormatFunction, 'pt-BR');
