import { takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import Bugsnag from '../config/bugsnag';
import { LOGADO } from '../reducers/gerais/whoami';

export default function* () {
  // eslint-disable-next-line require-yield
  yield takeLatest(LOGADO, function* ({ dados }: AnyAction) {
    Bugsnag.setUser(dados.id, dados.email, dados.nome);
  });
}
