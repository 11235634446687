/* eslint-disable max-classes-per-file */

export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

export class ServerValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ServerValidationError';
  }
}

export class HttpError extends Error {
  constructor(message: string, public readonly response: Response) {
    super(message);
    this.name = 'HttpError';
  }
}

export class PopupBlockedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PopupBlockedError';
  }
}
