import { ReactNode, SyntheticEvent } from 'react';
import { WrappedFieldMetaProps } from 'redux-form';

import { Expr } from '../../../util/expressoes';
import { NomeConstante } from '../../../util/constantes';

export type SelectOption<T = any> = { label: string; value: T };

export type SelectOptions<T = any> = readonly SelectOption<T>[];

export type TipoCampo =
  | 'string'
  | 'livre'
  | 'selecao'
  | 'inteiro'
  | 'decimal'
  | 'email'
  | 'telefone'
  | 'booleano'
  | 'moeda'
  | 'moedaValorUnitario'
  | 'aliquota'
  | 'aliquotaDecimal'
  | 'peso'
  | 'percentual'
  | 'data'
  | 'dataHora'
  | 'cpf'
  | 'cnpj'
  | 'cep'
  | 'mesAno'
  | 'fci'
  | 'arquivo'
  | 'chaveAcesso';

export type CampoProps = {
  id?: string;
  name?: string;
  label?: string;
  hint?: string;
  className?: string;
  children?: ReactNode;
  tamanhoMaximo?: number;
  tamanhoMinimo?: number;
  tamanhosOpcoes?: number[];
  antes?: number;
  depois?: number;
  mascara?: string | null | undefined;
  obrigatorio?: boolean;
  somenteLeitura?: boolean;
  value?: any;
  percentual?: boolean;
  moeda?: boolean;
  filtro?: boolean;
  mensagem?: string;
  semPontuacao?: boolean;

  // eventos informados pelo componente consumidor do campo
  onChange?: (...args: any[]) => void;
  onKeyDown?: (...args: any[]) => void;
  botaoRecarregar?: (...args: any[]) => void;

  input?: {
    name?: string;
    value?: any;

    // eventos informados pelo redux-select
    onChange?: (...args: any[]) => void;
    onBlur?: (...args: any[]) => void;
    onFocus?: (...args: any[]) => void;
  };
  meta?: Partial<WrappedFieldMetaProps>;
  tipo?: TipoCampo;
  listaItens?: ListaItens;

  expr?: Expr<never>;
};

export type ListaItens<T = any> = SelectOptions | NomeConstante | FilterableOptions<T>;

export class FilterableOptions<T = string> {
  constructor(public readonly options: SelectOptions) {}

  /**
   * filterOption do react-select utiliza indexOf para filtrar valores,
   * o que não é muito útil para busca de múltiplas palavras.
   * Filtro customizado para react-select
   * Fonte: https://github.com/JedWatson/react-select/issues/3067#issue-363771398
   */
  // eslint-disable-next-line class-methods-use-this
  filterOptions(option: { value: T; label: string }, rawInput: string) {
    const words = rawInput.split(' ');
    return words.reduce((acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()), true);
  }
}

export interface CampoWrapperProps {
  tipo?: TipoCampo;
  meta: { form: string };
  onInputChange?: (eventOrValue: SyntheticEvent<any> | any) => void;
  input: {
    name: string;
    value?: any;
    onChange(eventOrValue: SyntheticEvent<any> | any): void;
    onKeyDown(eventOrValue: SyntheticEvent<any> | any): void;
  };
}
