import _ from 'lodash';
import { createSelector, Selector } from 'reselect';
import { DefaultRootState } from 'react-redux';

import { ItensPaginados } from '../types/api';

import { objComValorSeletor } from './seletor';

/**
 * Cria um dicionário contendo os mesmos elementos da lista, indexados pela propriedade especificada.
 *
 * @param list a lista de itens a indexar
 * @param indexer a propriedade a utilizar
 */
export function indexBy<T extends Record<string, any>, K extends PropertyKey>(list: readonly T[], indexer: keyof T | ((value: T) => K)): Record<K, T> {
  let indexFn: (value: T) => K;

  if (typeof indexer === 'function') indexFn = indexer;
  else indexFn = (v: T) => v[indexer];

  return list.reduce((map, item) => Object.assign(map, { [indexFn(item)]: item }), {} as Record<K, T>);
}

/**
 * Similar ao {@link indexBy}, porém já faz o merge com o dicionário em `dest`.
 *
 * @param dest dicionário a retornar, em adição aos itens que serão indexados
 * @param list os itens a indexar
 * @param indexer a propriedade a ser utilizada para indexar
 */
export function mergeIndexedBy<T extends Record<string, any>, K extends PropertyKey>(
  dest: Record<string, T>,
  list: readonly T[],
  indexer: keyof T | ((value: T) => K)
): Record<K, T> {
  return { ...dest, ...indexBy(list, indexer) };
}

/**
 * Verifica se valor é nulo, indefinido ou vazio.
 */
export function optionalEmpty(v: any) {
  return v === null || v === undefined || v === '' || (typeof v === 'object' && _.isEmpty(v));
}

/**
 * Função que retorna formulário como objeto JS removendo propriedades vazias e propriedade page utilizada na paginação.
 */
export function formularioJS(form: any) {
  if (!_.isPlainObject(form)) return {};

  return _(formToJS(form)).omitBy(optionalEmpty).omit('page').value();
}

/**
 * Interpreta um objeto utilizado em formulário, e ajusta para que fique pronto para submissão.
 */
export function formToJS<T>(form: T) {
  return objComValorSeletor<T>(form);
}

type EntityStore<T> = { readonly carregando: boolean; readonly resultado: ItensPaginados<T> };

/**
 * Faz a desnormalização de uma lista de resultados, ou seja, monta uma lista de objetos com base em uma lista de IDs.
 *
 * Inspirado no projeto {@link https://github.com/paularmstrong/normalizr|normalizr}
 *
 * @param lista a lista que contém os IDs
 * @param ref o dicionário de entidades, indexadas por ID
 *
 * @see createDenormalizationSelector
 */
export function denormalize<T extends string, R>(lista: EntityStore<T>, ref: Record<string, R>): EntityStore<R> {
  return {
    ...lista,
    resultado: { ...lista.resultado, items: lista.resultado.items.map((id) => ref[id]).filter(_.identity) },
  };
}

/**
 * Cria um seletor que faz a desnormalização de uma lista de resultados, ou seja,
 * monta uma lista de objetos com base em uma lista de IDs.
 *
 * Inspirado no projeto {@link https://github.com/paularmstrong/normalizr|normalizr}
 *
 * @param idsSelector um seletor que resolve para um resultado de lista de IDs
 * @param entitiesSelector um seletor que resolve para um dicionário de entidades, indexadas por ID
 *
 * @see denormalize
 */
export function createDenormalizationSelector<T, K extends string>(
  idsSelector: Selector<DefaultRootState, EntityStore<K>>,
  entitiesSelector: Selector<DefaultRootState, Record<K, T>>
) {
  return createSelector(idsSelector, entitiesSelector, denormalize);
}

/**
 * Cria uma nova lista com os elementos da lista que estão presentes em `ids`.
 */
export function extraiElementosLista<T extends { _id?: string }>(lista: readonly T[], ids: readonly string[]): T[] {
  return lista.filter((e) => e._id && ids.includes(e._id));
}
