/**
 * Reducer das expressões para cálculos automáticos.
 *
 * @module reducers/expr
 */

import produce from 'immer';

import { calculaPropsParaExpr, Expr } from '../util/expressoes';
import { createRootSelector } from '../types';

export const REGISTER_EXPR = 'expr/REGISTER_EXPR';
export const UNREGISTER_EXPR = 'expr/UNREGISTER_EXPR';
export const CALCULA = 'expr/CALCULA';

interface ExprState {
  readonly [k: string]: {
    readonly [k: string]: {
      readonly uid: number;
      readonly expr: Expr;
      readonly depois?: number;
      readonly camposExpr: readonly any[];
    };
  };
}

const INITIAL_STATE: ExprState = {};

export type CalculaAction = ReturnType<typeof calcula>;

type ExprActions = ReturnType<typeof registerExpr> | ReturnType<typeof unregisterExpr>;

/**
 * Este reducer será combinado com o reducer do redux-form, para adicionar alguns comportamentos extras
 * a todos os formulários.
 */
export default function reducer(state = INITIAL_STATE, action?: ExprActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case REGISTER_EXPR:
      return produce(state, (draft) => {
        if (!(action.form in draft)) {
          draft[action.form] = {};
        }
        draft[action.form][action.name] = {
          uid: action.uid,
          expr: action.expr,
          depois: action.depois,
          camposExpr: calculaPropsParaExpr(action.expr),
        };
      });
    case UNREGISTER_EXPR:
      return produce(state, (draft) => {
        const form = draft[action.form];
        const expr = form && form[action.name];
        if (expr && action.uid === expr.uid) {
          delete form[action.name];
        }
      });
    default:
      return state;
  }
}

// SELECTORS

export const todasExpressoes = createRootSelector((state) => state.expr);

// ACTION CREATORS

export function registerExpr<T>(uid: number, form: string, name: string, depois: number | undefined, expr: Expr<T>) {
  return { type: REGISTER_EXPR, uid, form, name, depois, expr } as const;
}

export function unregisterExpr(uid: number, form: string, name: string) {
  return { type: UNREGISTER_EXPR, uid, form, name } as const;
}

export function calcula<T>(form: string, name: string, depois: number | undefined, expr: Expr<T>) {
  return { type: CALCULA, form, name, depois, expr } as const;
}
