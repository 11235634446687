import React from 'react';

export default function HomeSuporte() {
  return (
    <div>
      <p>Cadastre seus clientes e produtos pelo menu Cadastros.</p>
      <p>Após, basta clicar em Emitir NF-e para começar a emitir suas notas fiscais.</p>
    </div>
  );
}
