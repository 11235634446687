import { createSelector } from 'reselect';

import api from '../../../../api';
import { makeThunk, State } from '../../../../types';
import * as itemActions from '../emissao/item';
import * as conta from '../../../transacionais/contas';
import * as emitente from '../../../transacionais/emitentes';
import { ConsultaNfe } from '../../../../types/apiNfe';

const NOVO = 'telas/nfe/consulta/detalhes/NOVO';
const DEFINE_ITEM_ATUAL = 'telas/nfe/consulta/detalhes/DEFINE_ITEM_ATUAL';
const ALTERA_ATUALIZA_TELA = 'telas/nfe/consulta/detalhes/ALTERA_ATUALIZA_TELA ';

const ITEM_VAZIO = {} as Readonly<ConsultaNfe>;

const INITIAL_STATE = {
  item: ITEM_VAZIO,
  atualizaTela: false as boolean,
} as const;

const DELAY_10_SEGUNDOS = 10000;

type Actions = ReturnType<typeof alteraAtualizaTela | typeof defineItemAtual | typeof defineItemNovo>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case ALTERA_ATUALIZA_TELA:
      return { ...state, atualizaTela: action.valor };
    case DEFINE_ITEM_ATUAL:
      return { ...state, item: action.dados };
    case NOVO:
      return { ...state, item: ITEM_VAZIO };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.consulta.detalhes;

export const nfeIdSelector = createSelector(rootSelector, (root) => (root.item && root.item.nfe ? root.item.nfe._id : null));

export const dadosAtuaisSelector = createSelector(rootSelector, (root) => root.item);

export const atualizaTelaSelector = createSelector(rootSelector, (root) => root.atualizaTela || false);

// ACTION CREATORS

export function defineItemAtual(dados: ConsultaNfe) {
  return { type: DEFINE_ITEM_ATUAL, dados } as const;
}

export function defineItemNovo() {
  return { type: NOVO } as const;
}

export function alteraAtualizaTela(valor: boolean) {
  return { type: ALTERA_ATUALIZA_TELA, valor } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega o cliente com o ID selecionado na store transacional, e copia os dados para a tela.
 */
export function carregaRegistro(id: string) {
  return makeThunk(async (dispatch) => {
    await dispatch(defineItemNovo()); // Para não apresentar dados de item antigo na tela

    const r = await api.nfe.consulta(id);

    await Promise.all([dispatch(conta.carregaDados()), dispatch(emitente.carregaDados()), dispatch(itemActions.abreNota(r.nfe))]);

    await dispatch(defineItemAtual(r));

    dispatch(alteraAtualizaTela(true));
    dispatch(agendaRequisicao(id)); // atualiza dados da NF-e a cada 30 segundos

    return r;
  });
}

let requestTimeout: NodeJS.Timeout;

export function agendaRequisicao(id: string, delay?: number) {
  return makeThunk(async (dispatch) => {
    const fn = () => dispatch(carregaDadosNfe(id));
    requestTimeout = setTimeout(fn, delay || DELAY_10_SEGUNDOS);
  });
}

export function carregaDadosNfe(id: string) {
  return makeThunk(async (dispatch, getState) => {
    if (requestTimeout) clearTimeout(requestTimeout);

    if (!atualizaTelaSelector(getState())) return;

    // se a nota atual for diferente da nota definida na atualização de tela, agenda requisição para nota atual
    const nfeIdAtual = nfeIdSelector(getState());
    if (nfeIdAtual !== id) {
      if (nfeIdAtual) await dispatch(agendaRequisicao(nfeIdAtual));
      return;
    }

    const r = await api.nfe.consulta(id);

    dispatch(defineItemAtual(r));
    dispatch(itemActions.abreNota(r.nfe));

    await dispatch(agendaRequisicao(id));
  });
}
