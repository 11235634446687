import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import api from '../api';
import { deslogado, DESLOGADO, LOGIN, LOGOUT } from '../reducers/gerais/whoami';

import { buscaESalvaInformacoes } from './whoami';

/**
 * Esta saga é responsável pelo fluxo de autenticação.
 *
 * @see module:reducers/whoami
 */
export default function* sagaAuth() {
  yield takeLatest(LOGIN, onLogin);
  yield takeLatest(LOGOUT, onLogout);
  yield takeLatest(DESLOGADO, onDeslogado);
}

/**
 * Chamado quando o sistema detecta que o usuário quer se logar.
 */
function* onLogin() {
  yield call(api.login); // abre popup para logar no backend, e aguarda que ela seja fechada
  yield call(buscaESalvaInformacoes); // busca informações do usuário logado
}

/**
 * Chamado quando o sistema detecta que o usuário quer se deslogar.
 */
function* onLogout() {
  yield call(api.logout); // desloga no backend
  yield put(deslogado()); // limpa a store
}

/**
 * Chamado quando a store recebe a informação que o usuário está deslogado.
 */
function* onDeslogado() {
  yield put(push('/')); // redireciona ao raiz
}
