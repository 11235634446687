/**
 * Abre um diálogo de confirmação "modal", ou seja, o usuário não consegue mais interagir
 * com a aplicação enquanto não responder ao diálogo.
 *
 * A implementação básica apenas chama apenas a API "confirm" do navegador, mas será
 * substituída por uma implementação utiizando toastr no futuro.
 *
 * @param {string} mensagem a mensagem
 * @return {Promise.<bool>} verdadeiro se a opção foi "ok", falso se foi "cancelar".
 */
export async function confirmaModal(mensagem: string): Promise<boolean> {
  return global.confirm(mensagem);
}

/**
 * Abre um alerta "modal", ou seja, o usuário não consegue mais interagir com a aplicação
 * enquanto não responder ao diálogo.
 *
 * A implementação básica apenas chama apenas a API "alert" do navegador, mas será
 * substituída por uma implementação utiizando toastr no futuro.
 *
 * @param {string} mensagem a mensagem
 */
export async function alertaModal(mensagem: string): Promise<void> {
  return global.alert(mensagem);
}
