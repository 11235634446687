import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import * as whoamiActions from '../reducers/gerais/whoami';
import BaseApp from '../components/layout/BaseApp';
import Home from '../components/home/Home';
import { PaginaNaoEncontrada } from '../components/layout/PaginaNaoEncontrada';

import { tela } from './tela';

const Ajuda = lazy(() => import('../components/ajuda'));
const CadastroDestinatarios = lazy(() => import('../components/cadastro/destinatario'));
const CadastroProdutos = lazy(() => import('../components/cadastro/produto'));
const CadastroServicos = lazy(() => import('../components/cadastro/servico'));
const CadastroTransportadores = lazy(() => import('../components/cadastro/transportador'));
const ConsultaNfe = lazy(() => import('../components/nfe/consulta'));
const EmissaoNfe = lazy(() => import('../components/nfe/emissao'));
const Configuracoes = lazy(() => import('../components/ferramentas/configuracoes'));
const Importacoes = lazy(() => import('../components/ferramentas/importacoes'));

const routes = (
  <BaseApp>
    <Switch>
      <Route exact path="/" component={Home} />
      <Redirect exact from="/app" to="/" />
      <Route path="/app/cadastro">
        <Switch>
          <Route path="/app/cadastro/destinatarios" component={CadastroDestinatarios} />
          <Route path="/app/cadastro/produtos" component={CadastroProdutos} />
          <Route path="/app/cadastro/servicos" component={CadastroServicos} />
          <Route path="/app/cadastro/transportadores" component={CadastroTransportadores} />
          <Redirect exact from="/app/cadastro" to="/app/cadastro/destinatarios" />
          <Route component={PaginaNaoEncontrada} />
        </Switch>
      </Route>
      <Route path="/app/nfe">
        <Switch>
          <Route path="/app/nfe/emissao" component={EmissaoNfe} />
          <Route path="/app/nfe/consulta" component={ConsultaNfe} />
          <Redirect exact from="/app/nfe" to="/app/nfe/consulta" />
          <Route component={PaginaNaoEncontrada} />
        </Switch>
      </Route>
      <Route path="/app/ferramentas">
        <Switch>
          <Route path="/app/ferramentas/configuracoes" component={Configuracoes} />
          <Route path="/app/ferramentas/importacoes" component={Importacoes} />
          <Redirect exact from="/app/ferramentas" to="/app/ferramentas/configuracoes" />
          <Route component={PaginaNaoEncontrada} />
        </Switch>
      </Route>
      <Route path="/app/ajuda" component={Ajuda} />
      <Route exact path="/app/sair" component={tela(Home, () => whoamiActions.logout())} />
      <Route component={PaginaNaoEncontrada} />
    </Switch>
  </BaseApp>
);

export default routes;
