import { ParsedUrlQueryInput } from 'querystring';

import { createSelector } from 'reselect';

import api from '../../api';
import { createRootSelector, makeThunk, PaginatedData } from '../../types';
import { Destinatario } from '../../types/api';
import { mergeIndexedBy } from '../../util/listas';

const DADOS_RECEBIDOS = 'transacionais/destinatarios/DADOS_RECEBIDOS';
const DESTINATARIO_RECEBIDO = 'transacionais/destinatarios/DESTINATARIO_RECEBIDO';

const INITIAL_STATE = {
  porId: {} as Record<string, Destinatario>,
} as const;

type Actions = ReturnType<typeof dadosRecebidos | typeof destinatarioRecebido>;

export default function reducer(state = INITIAL_STATE, action?: Actions): typeof INITIAL_STATE {
  switch (action?.type) {
    case DADOS_RECEBIDOS:
      return { ...state, porId: mergeIndexedBy(state.porId, action.dados.items, '_id') };
    case DESTINATARIO_RECEBIDO:
      return { ...state, porId: mergeIndexedBy(state.porId, [action.dados], '_id') };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = createRootSelector((state) => state.transacionais.destinatarios);

export const porIdSelector = createSelector(rootSelector, (root) => root.porId);

// ACTION CREATORS

export function dadosRecebidos(dados: PaginatedData<Destinatario>) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

export function destinatarioRecebido(dados: Destinatario) {
  return { type: DESTINATARIO_RECEBIDO, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query?: ParsedUrlQueryInput) {
  return makeThunk(async (dispatch) => {
    const r = await api.destinatarios.index(query); // chama a API
    dispatch(dadosRecebidos(r)); // armazena sucesso na store

    return r;
  });
}

export function carregaDestinatario(id: string) {
  return makeThunk(async (dispatch) => {
    const r = await api.destinatarios.show(id); // chama a API
    dispatch(destinatarioRecebido(r)); // armazena sucesso na store
    return r;
  });
}

export function cria(dados: Partial<Destinatario>) {
  return makeThunk(async (dispatch) => {
    const r = await api.destinatarios.create(dados); // chama a API
    dispatch(destinatarioRecebido(r)); // armazena sucesso na store
    return r;
  });
}

export function atualiza(destinatario: Partial<Destinatario>) {
  return makeThunk(async (dispatch) => {
    const r = await api.destinatarios.update(destinatario); // chama a API
    dispatch(destinatarioRecebido(r)); // armazena sucesso na store
    return r;
  });
}
