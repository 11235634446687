import { call, put, take, takeLatest } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';
import { autofill, formValueSelector } from 'redux-form';
import { ARRAY_PUSH, ARRAY_REMOVE, BLUR } from 'redux-form/lib/actionTypes';
import { Action, AnyAction } from 'redux';

import { carregaTela, finalizaCarregamentoTela, mudaFormaPagamentoAtiva } from '../../reducers/telas/nfe/emissao/pagamento';
import { toNumberUsa } from '../../util/formatacao';
import { ABRE_NOTA } from '../../reducers/telas/nfe/emissao/item';
import { FormaPagamento } from '../../types/apiNfe';

import { converteDecimal2 } from './itens';

const PREFIXO_FORMAS_PAGAMENTO = 'pagamentos';

const nfeSelector = formValueSelector('nfe');

export default function* sagaFormasPagamentoEmissao() {
  yield call(carregaDados);

  // cada vez que uma nova forma de pagamento for adicionada, tornamos ela a forma de pagamento ativa
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_FORMAS_PAGAMENTO,
    function* (_ac: Action) {
      const arr: FormaPagamento[] = yield* select(nfeSelector, PREFIXO_FORMAS_PAGAMENTO);
      yield put(mudaFormaPagamentoAtiva(arr.length - 1));
    }
  );

  // cada vez que adicionar uma nova forma de pagamento, insere valor padrão de valorTotalNfe
  yield takeLatest((ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_FORMAS_PAGAMENTO, alteraValorPagamento);

  // RN cobr.02
  // cada vez que o campo valorPagamento for modificado, calcula o valor do campo valorTroco
  yield takeLatest((ac: AnyAction) => ac.type === BLUR && ac.meta.form === 'nfe' && ac.meta.field.endsWith('.valorPagamento'), calculaValorTroco);

  // cada vez que uma forma de pagamento for removida, atualiza valorTroco
  yield takeLatest((ac: AnyAction) => ac.type === ARRAY_REMOVE && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_FORMAS_PAGAMENTO, calculaValorTroco);
}

function calculaValorPago(pagamentos: FormaPagamento[]) {
  if (!pagamentos || pagamentos.length === 0) return 0;

  let totalValorPag = 0;
  pagamentos.forEach((p: FormaPagamento) => {
    totalValorPag += Number(toNumberUsa(p.valorPagamento));
  });

  return converteDecimal2(totalValorPag);
}

export function* calculaValorTroco() {
  const valorTotalNfe = yield select(nfeSelector, 'valorTotalNfe');
  const pagamentos: FormaPagamento[] = yield* select(nfeSelector, 'pagamentos');

  const pago = calculaValorPago(pagamentos);
  const total = converteDecimal2(valorTotalNfe);

  if (pago && total) return yield put(autofill('nfe', 'valorTroco', converteDecimal2(pago - total)));

  // Caso não tenha nenhum pagamento ou o valorTotalNfe seja indefinido, altera valorTroco para nulo
  return yield put(autofill('nfe', 'valorTroco', null));
}

export function* alteraValorPagamento() {
  const valorTotalNfe = yield* select(nfeSelector, 'valorTotalNfe');
  const pagamentos: FormaPagamento[] = yield* select(nfeSelector, PREFIXO_FORMAS_PAGAMENTO);

  const pago = calculaValorPago(pagamentos);
  const total = converteDecimal2(valorTotalNfe);

  if (pago && total) {
    if (pago > total) yield put(autofill('nfe', `pagamentos[${pagamentos.length - 1}].valorPagamento`, 0));
    else yield put(autofill('nfe', `pagamentos[${pagamentos.length - 1}].valorPagamento`, converteDecimal2(total - pago)));
    return yield calculaValorTroco();
  }

  // caso seja a primeira forma de pagamento inserido, altera valorPagamento para valorTotalNfe
  yield put(autofill('nfe', `pagamentos[${pagamentos.length - 1}].valorPagamento`, converteDecimal2(valorTotalNfe) || 0));
  return yield calculaValorTroco();
}

function* carregaDados() {
  yield put(carregaTela());

  // o formulário de pagamento é atualizado após abrir nota
  // aguarda até abrir a nota para finalizar carregamento da janela
  yield take(ABRE_NOTA);

  yield put(finalizaCarregamentoTela());
}
