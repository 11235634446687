import { autofill, formValueSelector } from 'redux-form';
import { put, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { CHANGE } from 'redux-form/lib/actionTypes';
import { AnyAction } from 'redux';

import { carregaOpcoesSelect, porIdSelector } from '../../reducers/telas/cadastro/destinatario/opcoesSeletor';
import { Opcao } from '../../util/seletor';

const filtroSelector = formValueSelector('destinatarioFiltrar');

export default function* sagaDestinatario() {
  // cada vez que o campo contaId for modificado, atualiza lista de destinatários em clienteId
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'destinatarioFiltrar' && ac.meta.field === 'contaId',
    function* (ac: AnyAction) {
      yield call(atualizaNomeRazaoSocial, ac.payload);
    }
  );
}

export function* atualizaNomeRazaoSocial(opcaoContaId: Opcao<string> | null | undefined) {
  // Se usuário apagou conta selecionada, atualiza lista de destinatário.
  if (!opcaoContaId) {
    yield put(carregaOpcoesSelect());
    return;
  }

  // Se o destinatário de clienteId possuir contaId diferente do parâmetro, reinicia clienteId
  const opcaoClienteId: Opcao<string> = yield* select(filtroSelector, 'clienteId');
  const destinatariosSelect = yield* select(porIdSelector);
  const destSelecionado = opcaoClienteId ? destinatariosSelect[opcaoClienteId.value] : null;

  if (!destSelecionado || destSelecionado.contaId !== opcaoContaId.value) {
    yield put(autofill('destinatarioFiltrar', 'clienteId', null));
  }

  // Se usuário selecionou uma conta, atualiza lista de destinatário.
  yield put(carregaOpcoesSelect({ contaId: opcaoContaId.value }));
}
