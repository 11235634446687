import { createRootSelector, makeThunk } from '../../../../../../types';
import { InutilizacaoERegistrosRelacionados } from '../../../../../../types/api';
import api from '../../../../../../api';

export const DEFINE_ITEM_ATUAL = 'telas/ferramentas/configuracoes/emitentes/inutilizacoes/detalhes/DEFINE_ITEM_ATUAL';
export const NOVO = 'telas/ferramentas/configuracoes/emitentes/inutilizacoes/detalhes/NOVO';

const INITIAL_STATE = {
  item: undefined as InutilizacaoERegistrosRelacionados | undefined,
} as const;

type Actions = ReturnType<typeof defineItemAtual | typeof defineItemNovo>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_ITEM_ATUAL:
      return { ...state, item: action.detalhesInutilizacao };
    case NOVO:
      return { ...state, item: undefined };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = createRootSelector((s) => s.telas.ferramentas.configuracoes.emitentes.inutilizacoes.detalhes.item);

// ACTION CREATORS

export function defineItemAtual(detalhesInutilizacao: InutilizacaoERegistrosRelacionados) {
  return { type: DEFINE_ITEM_ATUAL, detalhesInutilizacao } as const;
}

export function defineItemNovo() {
  return { type: NOVO } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega os detalhes da conta a partir do ID da conta.
 */
export function carregaRegistro(id: string) {
  return makeThunk(async (dispatch) => {
    await dispatch(defineItemNovo()); // Para não apresentar dados de item antigo na tela

    const detalhesInutilizacao = await api.inutilizacoes.show(id);
    await dispatch(defineItemAtual(detalhesInutilizacao));

    return detalhesInutilizacao;
  });
}
