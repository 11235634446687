import { ParsedUrlQueryInput } from 'querystring';

import api from '../../api';
import { Dispatch, State } from '../../types';
import { ItensPaginados } from '../../types/api';
import { Nfe } from '../../types/apiNfe';
import { mergeIndexedBy } from '../../util/listas';

const DADOS_RECEBIDOS = 'transacionais/nfes/DADOS_RECEBIDOS';
const NOVA_NOTA = 'transacionais/nfes/NOVA_NOTA';

const INITIAL_STATE = {
  porId: {} as Record<string, Nfe>,
} as const;

type NfesActions = ReturnType<typeof dadosRecebidos | typeof novaNota>;

export default function reducer(state = INITIAL_STATE, action?: NfesActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case NOVA_NOTA:
      return { ...state, porId: mergeIndexedBy(state.porId, [action.nfe], '_id') };
    case DADOS_RECEBIDOS:
      return { ...state, porId: mergeIndexedBy(state.porId, action.dados.items, '_id') };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.transacionais.nfes.porId;

// ACTION CREATORS

export function novaNota(nfe: Nfe) {
  return { type: NOVA_NOTA, nfe } as const;
}

export function dadosRecebidos(dados: ItensPaginados<Nfe>) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

// THUNK ACTION CREATORS

/**
 * Esta ação consultará o backend para incluir uma nova nota de "rascunho" para emissão.
 *
 * Essa nova nota já terá um ID fixo, bem como alguns dados padrão que podem ser alterados (como número e série).
 */
export function iniciaNovaNota() {
  return async function (dispatch: Dispatch) {
    const nfe = await api.nfe.create(); // monta o rascunho da nova nota
    await dispatch(novaNota(nfe)); // avisa a store que chegou a nova nota

    return nfe; // retorna a nova nota
  };
}

export function abreNota(id: string) {
  return async function (dispatch: Dispatch) {
    const nfe = await api.nfe.show(id);
    if (!nfe) throw new Error('Not found');

    await dispatch(novaNota(nfe)); // avisa a store que chegou a nova nota

    return nfe;
  };
}

export function carregaLista(params?: ParsedUrlQueryInput) {
  return async function (dispatch: Dispatch) {
    const lista = await api.nfe.index(params); // consulta a API

    await dispatch(dadosRecebidos(lista)); // avisa a store que chegaram novas notas

    return lista;
  };
}
