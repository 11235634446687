import { delay, put, takeEvery } from 'redux-saga/effects';
import { autofill, submit } from 'redux-form';

import { VOLTAR } from '../../reducers/telas/nfe/emissao/item';

export default function* sagaVoltarEmissao() {
  yield takeEvery(VOLTAR, voltar);
}

export function* voltar() {
  yield put(autofill('nfe', 'botaoVoltar', true));
  yield delay(100); // tempo para permitir que o autofill preencha o formulário
  yield put(submit('nfe')); // chama o submit do formulário
}
