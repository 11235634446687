import { Pong } from '../../types/api';

export const DADOS_RECEBIDOS = 'gerais/ping/DADOS_RECEBIDOS';
export const ERRO = 'gerais/ping/ERRO';

const INITIAL_STATE = {
  resultado: null as Pong | null,
  erro: null as { mensagem: string } | null,
} as const;

type Actions = ReturnType<typeof dadosRecebidos | typeof erro>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DADOS_RECEBIDOS:
      return { ...state, erro: null, resultado: action.resultado };
    case ERRO:
      return { ...state, erro: action.erro };
    default:
      return state;
  }
}

// ACTION CREATORS

export function dadosRecebidos(resultado: Pong) {
  return { type: DADOS_RECEBIDOS, resultado } as const;
}

export function erro(e: Error) {
  return { type: ERRO, erro: { mensagem: e.message } } as const;
}
