import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { login } from '../../reducers/gerais/whoami';

export default function HomeDeslogado() {
  const dispatch = useDispatch();
  const handleLogin = useCallback(() => dispatch(login()), [dispatch]);

  return (
    <div>
      <ul className="nav nav-pills pull-right">
        <li role="presentation">
          <Button type="submit" onClick={handleLogin}>
            <FontAwesomeIcon icon={['fas', 'sign-in-alt']} size="lg" />
            Entrar
          </Button>
        </li>
      </ul>

      <p>Para acessar o Emissor, você deve utilizar o mesmo usuário e senha do Portal Consyst-e.</p>
      <p>Se você já possui uma conta no Portal, clique no botão ao lado.</p>
      <p>
        Caso ainda não seja cliente,{' '}
        <a href="http://consyste.com.br/comprar.html" rel="noopener noreferrer" target="_blank">
          clique aqui
        </a>
        .
      </p>
    </div>
  );
}
