import { ParsedUrlQueryInput } from 'querystring';

import { createSelector } from 'reselect';

import { Dispatch, State } from '../../../../types';
import { Destinatario } from '../../../../types/api';
import api from '../../../../api';
import { indexBy } from '../../../../util/listas';

const DADOS_SELECT_RECEBIDOS = 'telas/cadastro/destinatarios/opcoesSeletor/DADOS_SELECT_RECEBIDOS';

const INITIAL_STATE = {
  todos: [] as Destinatario[],
  porId: {} as Record<string, Destinatario>,
} as const;

type Actions = ReturnType<typeof dadosSelectRecebidos>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DADOS_SELECT_RECEBIDOS:
      return { ...state, todos: action.dados, porId: { ...state.porId, ...indexBy(action.dados, '_id') } };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.cadastro.destinatario.opcoesSeletor;

export const todosSelector = createSelector(dadosAtuaisSelector, (root) => root.todos);

export const porIdSelector = createSelector(dadosAtuaisSelector, (root) => root.porId);

// ACTION CREATORS

export function dadosSelectRecebidos(dados: Destinatario[]) {
  return { type: DADOS_SELECT_RECEBIDOS, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaOpcoesSelect(query?: ParsedUrlQueryInput) {
  return async function (dispatch: Dispatch) {
    const destinatarios = await api.destinatarios.opcoesSeletor(query);
    dispatch(dadosSelectRecebidos(destinatarios));

    return destinatarios;
  };
}
