import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';

import logo from '../../img/logo.png';
import logoTrans from '../../img/logoTrans.png';

export default function Header() {
  return (
    <header>
      <Navbar>
        <Navbar.Header>
          <Link className="navbar-brand home" to="/">
            <img src={logo} alt="Emissor NF-e Consyst-e" />
            <span className="sr-only">Emissor NF-e Consyst-e</span>
          </Link>
        </Navbar.Header>

        <Navbar.Text pullRight>
          <img src={logoTrans} alt="Consyst-e" />
        </Navbar.Text>
      </Navbar>
    </header>
  );
}
