import _ from 'lodash';

export type Opcao<T> = { value: T; label: string };
export type OpcaoValorString = string | Opcao<string>;
export type OpcaoValorNumero = number | Opcao<number>;

export type OpcaoSeletor = OpcaoValorString | OpcaoValorNumero | null | undefined;

type DeepValorSeletorExtractor<T> = T extends Date | null
  ? T
  : T extends Opcao<infer V>
  ? V
  : T extends Array<infer I>
  ? DeepValorSeletorArrayExtractor<I>
  : T extends object // eslint-disable-line @typescript-eslint/ban-types
  ? DeepValorSeletorObjectExtractor<T>
  : T;
type DeepValorSeletorArrayExtractor<T> = Array<DeepValorSeletorExtractor<T>>;
type DeepValorSeletorObjectExtractor<T> = { [P in keyof T]: DeepValorSeletorExtractor<T[P]> };

type ValorSeletorExtractor<T> = T extends { value: infer V; label: string } ? V : T;

/**
 * Retorna objeto com valor de seletor nos campos com opção de seletor.
 */
export function objComValorSeletor<T>(obj: T): DeepValorSeletorExtractor<T> {
  if (!obj) return obj as DeepValorSeletorExtractor<T>;

  if (isOpcao(obj)) return obj.value as DeepValorSeletorExtractor<T>;

  if (_.isArray(obj)) return obj.map((v) => objComValorSeletor(v)) as DeepValorSeletorExtractor<T>;

  if (!_.isPlainObject(obj)) return obj as DeepValorSeletorExtractor<T>;

  return _.mapValues(obj as any, (v: unknown) => objComValorSeletor(v)) as DeepValorSeletorExtractor<T>;
}

/**
 * Retorna valor do seletor.
 */
export function valorSeletor<T extends OpcaoSeletor>(opcao: T) {
  if (!opcao) return null;

  if (typeof opcao === 'number' || typeof opcao === 'string') return opcao as ValorSeletorExtractor<T>;

  if (isOpcao(opcao)) return opcao.value as ValorSeletorExtractor<T>;

  return null;
}

export function isOpcao<T>(v: any): v is Opcao<T> {
  return v && _.isPlainObject(v) && (v.value || typeof v.value === 'number');
}
