import React from 'react';
import { Router } from 'react-router';
import { Provider } from 'react-redux';

import store, { history } from './store';
import routes from './routes';
import ScrollToTop from './components/common/ScrollToTop';

export default function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>{routes}</ScrollToTop>
      </Router>
    </Provider>
  );
}
