import { all, fork, join } from 'redux-saga/effects';

import sagaCadastro from './cadastro';
import sagaEmissao from './emissao';
import sagaConsulta from './consulta';
import sagaExpr from './expr';
import sagaAuth from './auth';
import sagaBugsnag from './bugsnag';
import sagaWhoAmI from './whoami';
import sagaPing from './ping';
import sagaReiniciarCampo from './reiniciarCampo';

export default function* rootSaga() {
  const sagas = yield all([
    fork(sagaCadastro),
    fork(sagaEmissao),
    fork(sagaConsulta),
    fork(sagaBugsnag),
    fork(sagaReiniciarCampo),
    fork(sagaExpr),
    fork(sagaAuth),
    fork(sagaWhoAmI),
    fork(sagaPing),
  ]);

  yield join(sagas);
}
