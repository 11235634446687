import { takeLatest } from 'redux-saga/effects';

import { CARREGANDO as ABRE_LISTA_DESTINATARIO } from '../../reducers/telas/cadastro/destinatario/lista';
import { DEFINE_TITULO as ABRE_EDITA_DESTINATARIO } from '../../reducers/telas/cadastro/destinatario/detalhes';
import { DEFINE_TITULO as ABRE_EDITA_PRODUTO } from '../../reducers/telas/cadastro/produto/detalhes';

import sagaFiltrosDestinatario from './filtrosDestinatario';
import sagaCadastroDestinatario from './destinatario';
import sagaCadastroProduto from './produto';

export default function* sagaCadastro() {
  yield takeLatest(ABRE_LISTA_DESTINATARIO, sagaFiltrosDestinatario);
  yield takeLatest(ABRE_EDITA_DESTINATARIO, sagaCadastroDestinatario);
  yield takeLatest(ABRE_EDITA_PRODUTO, sagaCadastroProduto);
}
