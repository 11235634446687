import { ParsedUrlQueryInput } from 'querystring';

import { createSelector } from 'reselect';

import api from '../../api';
import { Dispatch, PaginatedData, State } from '../../types';
import { Servico } from '../../types/api';
import { mergeIndexedBy } from '../../util/listas';

export const DADOS_RECEBIDOS = 'transacionais/servicos/DADOS_RECEBIDOS';
export const SERVICO_RECEBIDO = 'transacionais/servicos/SERVICO_RECEBIDO';

const INITIAL_STATE = {
  porId: {} as Record<string, Servico>,
  porCodigo: {} as Record<string, Servico>,
} as const;

type ServicosActions = ReturnType<typeof dadosRecebidos | typeof servicoRecebido>;

export default function reducer(state = INITIAL_STATE, action?: ServicosActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case DADOS_RECEBIDOS:
      return {
        ...state,
        porId: mergeIndexedBy(state.porId, action.dados.items, '_id'),
        porCodigo: mergeIndexedBy(state.porCodigo, action.dados.items, 'codigo'),
      };
    case SERVICO_RECEBIDO:
      return {
        ...state,
        porId: mergeIndexedBy(state.porId, [action.dados], '_id'),
        porCodigo: mergeIndexedBy(state.porCodigo, [action.dados], 'codigo'),
      };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.transacionais.servicos;

export const todosSelector = createSelector(rootSelector, (root) => Object.values(root.porId));

export const porIdSelector = createSelector(rootSelector, (root) => root.porId);

export const porCodigoSelector = createSelector(rootSelector, (root) => root.porCodigo);

// ACTION CREATORS

export function dadosRecebidos(dados: PaginatedData<Servico>) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

export function servicoRecebido(dados: Servico) {
  return { type: SERVICO_RECEBIDO, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query?: ParsedUrlQueryInput) {
  return async function (dispatch: Dispatch) {
    const r = await api.servicos.index(query); // chama a API
    dispatch(dadosRecebidos(r)); // armazena sucesso na store

    return r;
  };
}

export function carregaServico(id: string) {
  return async function (dispatch: Dispatch) {
    const r = await api.servicos.show(id); // chama a API
    dispatch(servicoRecebido(r)); // armazena sucesso na store
    return r;
  };
}

export function cria(dados: Partial<Servico>) {
  return async function (dispatch: Dispatch) {
    const r = await api.servicos.create(dados); // chama a API
    dispatch(servicoRecebido(r)); // armazena sucesso na store
    return r;
  };
}

export function atualiza(servico: Partial<Servico>) {
  return async function (dispatch: Dispatch) {
    const r = await api.servicos.update(servico); // chama a API
    dispatch(servicoRecebido(r)); // armazena sucesso na store
    return r;
  };
}
