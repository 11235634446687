import { createSelector } from 'reselect';

import { State } from '../../../../types';
import { numberIndex } from '../../../../util/formatacao';

export const CARREGA_DADOS = 'telas/nfe/emissao/itens/CARREGA_DADOS';
export const TELA_CARREGANDO = 'telas/nfe/emissao/itens/TELA_CARREGANDO';
export const TELA_CARREGADA = 'telas/nfe/emissao/itens/TELA_CARREGADA';
export const MUDA_ITEM_ATIVO = 'telas/nfe/emissao/itens/MUDA_ITEM_ATIVO';
export const MUDA_LOTE_ATIVO = 'telas/nfe/emissao/itens/MUDA_LOTE_ATIVO';
export const MUDA_DECLARACAO_ATIVA = 'telas/nfe/emissao/itens/MUDA_DECLARACAO_ATIVA';
export const MUDA_ADICAO_ATIVA = 'telas/nfe/emissao/itens/MUDA_ADICAO_ATIVA';

const INITIAL_STATE = {
  itemAtivo: null as number | null,
  loteAtivo: null as number | null,
  declaracaoAtiva: null as number | null,
  adicaoAtiva: null as number | null,
  carregandoTela: false as boolean,
} as const;

type Actions =
  | ReturnType<typeof mudaItemAtivo>
  | ReturnType<typeof mudaLoteAtivo>
  | ReturnType<typeof mudaDeclaracaoAtiva>
  | ReturnType<typeof mudaAdicaoAtiva>
  | ReturnType<typeof carregaTela>
  | ReturnType<typeof finalizaCarregamentoTela>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case MUDA_ITEM_ATIVO:
      // cada vez que muda o item, o primeiro item das listas inclusas no corpo do painel é definido como ativo
      return { ...state, itemAtivo: action.index, loteAtivo: 0, declaracaoAtiva: 0, adicaoAtiva: 0 };
    case MUDA_LOTE_ATIVO:
      return { ...state, loteAtivo: action.index };
    case MUDA_DECLARACAO_ATIVA:
      // cada vez que muda a declaração, a primeira adição é definida como ativa
      return { ...state, declaracaoAtiva: action.index, adicaoAtiva: 0 };
    case MUDA_ADICAO_ATIVA:
      return { ...state, adicaoAtiva: action.index };
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.itens;

export const itemAtivoSelector = createSelector(rootSelector, (s) => s.itemAtivo);

export const loteAtivoSelector = createSelector(rootSelector, (s) => s.loteAtivo);

export const declaracaoAtivaSelector = createSelector(rootSelector, (s) => s.declaracaoAtiva);

export const adicaoAtivaSelector = createSelector(rootSelector, (s) => s.adicaoAtiva);

export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

// ACTION CREATORS

export function mudaItemAtivo(index: number | string | null) {
  return { type: MUDA_ITEM_ATIVO, index: numberIndex(index) } as const;
}

export function mudaLoteAtivo(index: number | string | null) {
  return { type: MUDA_LOTE_ATIVO, index: numberIndex(index) } as const;
}

export function mudaDeclaracaoAtiva(index: number | string | null) {
  return { type: MUDA_DECLARACAO_ATIVA, index: numberIndex(index) } as const;
}

export function mudaAdicaoAtiva(index: number | string | null) {
  return { type: MUDA_ADICAO_ATIVA, index: numberIndex(index) } as const;
}

export function carregaDados() {
  return { type: CARREGA_DADOS } as const;
}

export function carregaTela() {
  return { type: TELA_CARREGANDO } as const;
}

export function finalizaCarregamentoTela() {
  return { type: TELA_CARREGADA } as const;
}
