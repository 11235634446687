import _ from 'lodash';
import { actionTypes, autofill, FormAction, getFormValues } from 'redux-form';
import { all, put, takeEvery } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

import { calcula, CALCULA, CalculaAction, todasExpressoes } from '../reducers/expr';
import { numProxy } from '../util/numProxy';

/**
 * Esta saga é responsável por fazer os cálculos dos campos com cálculo automático
 * (propriedade expr).
 *
 * @see module:reducers/expr
 * @see CampoRedux
 * @see CampoProps
 */
export default function* sagaExpr() {
  yield takeEvery(actionTypes.BLUR, onBlur);
  yield takeEvery(CALCULA, onCalcula);
}

type ReduxFormBlurAction = FormAction & { meta: { form: string; field: string } };

/**
 * Gerador chamado sempre que um campo do formulário perde o foco.
 * Percorre todas as expressões registradas e roda o cálculo.
 */
export function* onBlur({ meta: { form, field } }: ReduxFormBlurAction) {
  const todasExpr = yield* select(todasExpressoes);
  const exprList = todasExpr[form] && Object.entries(todasExpr[form]);

  if (!exprList || exprList.length === 0) return;

  // nome do campo de BLUR sem parte anterior ao ponto
  // exemplo: utiliza 'qtdComercial' de 'produtoServico[0].qtdComercial'
  let fieldName = field;
  if (field.includes('.')) {
    const partes = field.split('.');
    fieldName = partes[partes.length - 1];
  }

  // percorre as expressões registradas e inicializa um generator "onCalcula"
  // somente se a expressão não possui camposExpr ou camposExpr é vazio ou o campo do BLUR está presente em camposExpr
  const gens: Generator[] = [];
  exprList.forEach(([name, { expr, depois, camposExpr }]) => {
    if (!camposExpr || camposExpr.length === 0 || (fieldName && camposExpr.includes(fieldName))) {
      gens.push(onCalcula(calcula(form, name, depois, expr)));
    }
  });

  // repassa os generators para execução pelo redux-saga
  yield all(gens);
}

/**
 * Gerador chamado sempre que um cálculo é necessário. Pode ser chamado tanto pelo cálculo automático
 * quanto pelo manual.
 *
 * @param form - o formulário a calcular
 * @param name - o nome do campo a calcular
 * @param depois - o parâmetro depois com número de casas decimais
 * @param expr - a expressão a utilizar
 */
function* onCalcula({ form, name, depois, expr }: CalculaAction) {
  const formValues = yield* select(getFormValues(form)) || {};
  const formValuesProxy = numProxy(formValues);

  const nameSectionPath = name.match(/[^.[\]]+/g);
  const sectionPath = nameSectionPath ? nameSectionPath.slice(0, -1) : [];
  const sectionValuesProxy = sectionPath.length === 0 ? formValuesProxy : numProxy(_.get(formValues, sectionPath, {}));

  const calc = _.attempt(() => expr(sectionValuesProxy, formValuesProxy));

  yield put(autofill(form, name, formataResultadoExpressao(calc, depois)));
}

function formataResultadoExpressao(valor: unknown, decimais?: number) {
  if (!_.isNumber(valor)) return '';

  if (decimais && decimais > 0) return roundingNumber(valor, decimais);

  return valor;
}

export function roundingNumber(n: number, casasDecimais: number) {
  return +n.toFixed(casasDecimais);
}
