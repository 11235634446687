export type NumberProxy<T> = { [k in keyof T]: number } & {
  o: T;
};

function numberPropsExtractor<T>(target: T, property: keyof T) {
  if (property === 'o') return target;

  const v = target[property];
  if (typeof v === 'string' && /^[\d,.]+$/.test(v)) {
    return parseFloat(v.replace(/\./g, '').replace(/,/g, '.'));
  }

  return v;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function numProxy<T extends object>(obj: T) {
  return new Proxy(obj, { get: numberPropsExtractor }) as NumberProxy<T>;
}
