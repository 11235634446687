import { autofill, formValueSelector } from 'redux-form';

import { Dispatch, GetState, State } from '../../../../types';

import * as itens from './itens';

const ABRE_MODAL_IMPORTACAO = 'telas/nfe/emissao/modal/ABRE_MODAL_IMPORTACAO';
const FECHA_MODAL_IMPORTACAO = 'telas/nfe/emissao/modal/FECHA_MODAL_IMPORTACAO';

const INITIAL_STATE = {
  open: false as boolean,
} as const;

type Actions = ReturnType<typeof abreModal | typeof fechaModal>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case ABRE_MODAL_IMPORTACAO:
      return { ...state, open: true };
    case FECHA_MODAL_IMPORTACAO:
      return { ...state, open: false };
    default:
      return state;
  }
}

// SELECTORS

export const openModalSelector = (state: State): boolean => state.telas.nfe.emissao.modalImportacao.open;

// ACTION CREATORS

export function abreModal() {
  return { type: ABRE_MODAL_IMPORTACAO } as const;
}

export function fechaModal() {
  return { type: FECHA_MODAL_IMPORTACAO } as const;
}

const nfeSelector = formValueSelector('nfe');

export function abreModalImportacao() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const itemAtivo = itens.itemAtivoSelector(getState());

    if (typeof itemAtivo === 'number') {
      const declaracoesImportacao = nfeSelector(getState(), `produtoServico[${itemAtivo}].declaracoesImportacao`);
      if (declaracoesImportacao) {
        dispatch(autofill('nfe', `produtoServico[${itemAtivo}].dadosImportacao.declaracoesImportacao`, declaracoesImportacao));
      }
    }

    dispatch(abreModal());
  };
}

export function fechaModalESalva() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const itemAtivo = itens.itemAtivoSelector(getState());

    if (typeof itemAtivo === 'number') {
      const dadosImportacao = nfeSelector(getState(), `produtoServico[${itemAtivo}].dadosImportacao`);

      // Fecha o modal e depois atualiza o formulário com o valor de declaracoesImportacao
      await dispatch(fechaModal());

      if (dadosImportacao) {
        dispatch(autofill('nfe', `produtoServico[${itemAtivo}].declaracoesImportacao`, dadosImportacao.declaracoesImportacao || null));
      }
    } else {
      dispatch(fechaModal());
    }
  };
}

export function fechaModalENaoSalva() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const itemAtivo = itens.itemAtivoSelector(getState());

    if (typeof itemAtivo === 'number') {
      const declaracoesImportacao = nfeSelector(getState(), `produtoServico[${itemAtivo}].declaracoesImportacao`);

      // Fecha o modal e depois atualiza o formulário com o valor de declaracoesImportacao
      // Porque, após fechar o modal, o valor de declaracoesImportacao é atualizado com nulo pela ação CHANGE do reduxform
      await dispatch(fechaModal());

      dispatch(autofill('nfe', `produtoServico[${itemAtivo}].declaracoesImportacao`, declaracoesImportacao));
    } else {
      dispatch(fechaModal());
    }
  };
}
