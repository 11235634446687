import React from 'react';

export default function HomeSemAcesso() {
  return (
    <div>
      <p>Você não tem acesso ao Emissor.</p>
      <p>Entre em contato com o suporte para solicitar seu acesso.</p>
    </div>
  );
}
