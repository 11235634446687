import { useSelector } from 'react-redux';

import { usuarioAtualSelector } from '../../../reducers/gerais/whoami';

import BarraUsuario from './BarraUsuario';

export default function () {
  const usuarioAtual = useSelector(usuarioAtualSelector);

  return <BarraUsuario usuarioAtual={usuarioAtual} />;
}
