import { push } from 'react-router-redux';
import { createSelector } from 'reselect';

import { createRootSelector, makeThunk } from '../../../../../types';
import { Conta } from '../../../../../types/api';
import * as contasActions from '../../../../transacionais/contas';
import api from '../../../../../api';
import { alertaModal } from '../../../../../util/dialogos';
import { formToJS } from '../../../../../util/listas';

export const DEFINE_CONTA_ATUAL = 'telas/ferramentas/configuracoes/contas/edicao/DEFINE_CONTA_ATUAL';

export const CONTA_VAZIA: Conta = {
  codigo: '',
  nome: '',
  ativa: false,
  imprimeLogoDanfe: false,
  logomarca: '',
} as const;

const INITIAL_STATE = {
  conta: CONTA_VAZIA,
} as const;

type Actions = ReturnType<typeof defineContaAtual>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_CONTA_ATUAL:
      return { ...state, conta: { ...CONTA_VAZIA, ...action.conta } };
    default:
      return state;
  }
}

// SELECTORS

const rootSelector = createRootSelector((s) => s.telas.ferramentas.configuracoes.contas.edicao);

export const dadosAtuaisSelector = createSelector(rootSelector, (d) => d.conta);

// ACTION CREATORS

export function defineContaAtual(conta?: Conta) {
  return { type: DEFINE_CONTA_ATUAL, conta } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega a conta com o ID selecionado na store transacional, e copia os dados para a tela.
 */
export function carregaRegistro(id: string) {
  return makeThunk(async (dispatch) => {
    const conta: Conta = await dispatch(contasActions.carregaConta(id));

    await Promise.all([dispatch(defineContaAtual(conta))]);

    return conta;
  });
}

export function salva(form: any) {
  const formInJS = formToJS(form);

  const formData = new FormData();
  formData.append('logomarca', formInJS.logomarca);
  formData.append('id', formInJS._id);
  formData.append('imprime_logo_danfe', formInJS.imprimeLogoDanfe);

  return makeThunk(async (dispatch) => {
    const r = await api.contas.update(formData, formInJS._id);

    if (r.alertas.length > 0) await alertaModal(r.alertas.join('\n'));
    else {
      await alertaModal(r.msgSucesso);
      await dispatch(push(`/app/ferramentas/configuracoes/contas/${formInJS._id}`));
    }
  });
}
