import { createSelector } from 'reselect';
import { autofill } from 'redux-form';

import { Dispatch, GetState, State } from '../../../../types';
import * as municipios from '../../../transacionais/municipios';
import * as ufs from '../../../transacionais/ufs';
import api from '../../../../api';
import { numberIndex } from '../../../../util/formatacao';
import { Municipio, Uf } from '../../../../types/api';

export const CARREGA_DADOS = 'telas/nfe/emissao/transporte/CARREGA_DADOS';
export const MUDA_VOLUME_ATIVO = 'telas/nfe/emissao/transporte/MUDA_VOLUME_ATIVO';
export const TELA_CARREGANDO = 'telas/nfe/emissao/transporte/TELA_CARREGANDO';
export const TELA_CARREGADA = 'telas/nfe/emissao/transporte/TELA_CARREGADA';

const INITIAL_STATE = {
  volumeAtivo: null as number | null,
  carregandoTela: false as boolean,
} as const;

type Actions = ReturnType<typeof mudaVolumeAtivo | typeof carregaTela | typeof finalizaCarregamentoTela>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case MUDA_VOLUME_ATIVO:
      return { ...state, volumeAtivo: action.index };
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.transporte;

export const volumeAtivoSelector = createSelector(rootSelector, (s) => s.volumeAtivo);

export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

// ACTION CREATORS

export function mudaVolumeAtivo(index: number | string | null) {
  return { type: MUDA_VOLUME_ATIVO, index: numberIndex(index) } as const;
}

export function carregaDados() {
  return { type: CARREGA_DADOS } as const;
}

export function carregaTela() {
  return { type: TELA_CARREGANDO } as const;
}

export function finalizaCarregamentoTela() {
  return { type: TELA_CARREGADA } as const;
}

// THUNK ACTION CREATORS

export function trocaTransportador(opcao: { value: string; label: string } | null | undefined) {
  return async function (dispatch: Dispatch, getState: GetState) {
    if (!opcao || !opcao.value) return dispatch(autofill('nfe', 'transporte.nomeRazaoSocial', null));

    // obtém os dados da transportadora do backend
    const transportador = await api.transportadores.show(opcao.value);

    dispatch(autofill('nfe', 'transporte.nomeRazaoSocial', transportador.nomeRazaoSocial));
    dispatch(autofill('nfe', 'transporte.tipoDocumento', transportador.tipoDocumento));
    dispatch(autofill('nfe', 'transporte.cnpj', transportador.cnpj));
    dispatch(autofill('nfe', 'transporte.cpf', transportador.cpf));

    dispatch(autofill('nfe', 'transporte.tipoContribuinte', transportador.tipoContribuinte));
    dispatch(autofill('nfe', 'transporte.inscricaoEstadual', transportador.inscricaoEstadual));

    dispatch(autofill('nfe', 'transporte.logradouro', transportador.endereco?.logradouro));
    dispatch(autofill('nfe', 'transporte.numero', transportador.endereco?.numero));
    dispatch(autofill('nfe', 'transporte.complemento', transportador.endereco?.complemento));
    dispatch(autofill('nfe', 'transporte.bairro', transportador.endereco?.bairro));
    dispatch(autofill('nfe', 'transporte.cep', transportador.endereco?.cep));

    const municipioCodIbge = transportador.endereco?.municipioCodIbge;

    const municipio = (municipioCodIbge && municipios.municipioPorCodigoSelector(getState())[municipioCodIbge]) || ({} as Municipio);
    dispatch(autofill('nfe', 'transporte.codMunicipio', Number(municipioCodIbge)));
    dispatch(autofill('nfe', 'transporte.nomeMunicipio', municipio.nome));

    const uf = ufs.ufPorIdSelector(getState())[municipio.ufId] || ({} as Uf);
    dispatch(autofill('nfe', 'transporte.uf', uf.sigla));
    dispatch(autofill('nfe', 'transporte.codPais', '1058'));
    dispatch(autofill('nfe', 'transporte.nomePais', 'Brasil'));
    dispatch(autofill('nfe', 'transporte.telefone', transportador.telefone));
    return dispatch(autofill('nfe', 'transporte.email', transportador.email));
  };
}
