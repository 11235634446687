import { ParsedUrlQueryInput } from 'querystring';

import { createSelector } from 'reselect';

import api from '../../api';
import { Dispatch, PaginatedData, State } from '../../types';
import { Emissao, Transportador } from '../../types/api';
import { extraiElementosLista, mergeIndexedBy } from '../../util/listas';

export const DADOS_RECEBIDOS = 'transacionais/transportadores/DADOS_RECEBIDOS';
export const IDS_EMISSAO_RECEBIDOS = 'transacionais/transportadores/IDS_EMISSAO_RECEBIDOS';
export const TRANSPORTADOR_RECEBIDO = 'transacionais/transportadores/TRANSPORTADOR_RECEBIDO';

const INITIAL_STATE = {
  porId: {} as { [k: string]: Transportador },
  idEmissao: [] as string[],
} as const;

type TransportadoresActions = ReturnType<typeof dadosRecebidos> | ReturnType<typeof idsEmissaoRecebidos> | ReturnType<typeof transportadorRecebido>;

export default function reducer(state = INITIAL_STATE, action?: TransportadoresActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case DADOS_RECEBIDOS:
      return { ...state, porId: mergeIndexedBy(state.porId, action.dados.items, '_id') };
    case TRANSPORTADOR_RECEBIDO:
      return { ...state, porId: mergeIndexedBy(state.porId, [action.dados], '_id') };
    case IDS_EMISSAO_RECEBIDOS:
      return { ...state, idEmissao: action.dados.items.map((i) => i._id) };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.transacionais.transportadores;

export const porIdSelector = createSelector(rootSelector, (root) => root.porId);

export const porIdEmissaoSelector = createSelector(rootSelector, (root) => root.idEmissao);

export const todosSelector = createSelector(porIdSelector, (porId) => Object.values(porId));

export const transportadoresAtivosSelector = createSelector(todosSelector, porIdEmissaoSelector, extraiElementosLista);

// ACTION CREATORS

export function dadosRecebidos(dados: PaginatedData<Transportador>) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

export function idsEmissaoRecebidos(dados: Emissao) {
  return { type: IDS_EMISSAO_RECEBIDOS, dados } as const;
}

export function transportadorRecebido(dados: Transportador) {
  return { type: TRANSPORTADOR_RECEBIDO, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query?: ParsedUrlQueryInput) {
  return async function (dispatch: Dispatch) {
    const r = await api.transportadores.index(query); // chama a API
    dispatch(dadosRecebidos(r)); // armazena sucesso na store

    return r;
  };
}

export function carregaOpcoesSelect() {
  return async function (dispatch: Dispatch) {
    const todas = await api.transportadores.index({ pageSize: 9999 });
    // chama a API
    const emissao = await api.transportadores.emissao();
    dispatch(dadosRecebidos(todas)); // armazena sucesso na store
    dispatch(idsEmissaoRecebidos(emissao)); // armazena sucesso na store

    return todas;
  };
}

export function carregaTransportador(id: string) {
  return async function (dispatch: Dispatch) {
    const r = await api.transportadores.show(id); // chama a API
    dispatch(transportadorRecebido(r)); // armazena sucesso na store
    return r;
  };
}

export function cria(dados: Partial<Transportador>) {
  return async function (dispatch: Dispatch) {
    const r = await api.transportadores.create(dados); // chama a API
    dispatch(transportadorRecebido(r)); // armazena sucesso na store
    return r;
  };
}

export function atualiza(transportador: Partial<Transportador>) {
  return async function (dispatch: Dispatch) {
    const r = await api.transportadores.update(transportador); // chama a API
    dispatch(transportadorRecebido(r)); // armazena sucesso na store
    return r;
  };
}
