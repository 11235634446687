import { autofill } from 'redux-form';
import { createSelector } from 'reselect';

import { createRootSelector, makeThunk } from '../../../../types';
import { Emitente } from '../../../../types/api';
import * as municipios from '../../../transacionais/municipios';
import * as ufs from '../../../transacionais/ufs';
import api from '../../../../api';
import { indexBy } from '../../../../util/listas';

const DADOS_SELECT_RECEBIDOS = 'telas/nfe/emissao/emitente/DADOS_SELECT_RECEBIDOS';
const TELA_CARREGANDO = 'telas/nfe/emissao/emitente/TELA_CARREGANDO';
const TELA_CARREGADA = 'telas/nfe/emissao/emitente/TELA_CARREGADA';

const INITIAL_STATE = {
  porId: {} as Record<string, Emitente>,
  carregandoTela: false as boolean,
} as const;

type Actions = ReturnType<typeof dadosSelectRecebidos> | { type: typeof TELA_CARREGANDO } | { type: typeof TELA_CARREGADA };

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DADOS_SELECT_RECEBIDOS:
      // recebemos os dados como um array, e fazemos a indexação por ID
      return { ...state, porId: { ...state.porId, ...indexBy(action.dados, '_id') } };
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = createRootSelector((state) => state.telas.nfe.emissao.emitente);

export const todosSelector = createSelector(rootSelector, (root) => Object.values(root.porId));

export const porIdSelector = createSelector(rootSelector, (root) => root.porId);

export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

// ACTION CREATORS

export function dadosSelectRecebidos(dados: readonly Emitente[]) {
  return { type: DADOS_SELECT_RECEBIDOS, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaDados() {
  return makeThunk(async (dispatch) => {
    dispatch({ type: TELA_CARREGANDO });

    await Promise.all([dispatch(carregaOpcoesSelect()), dispatch(ufs.carregaDados()), dispatch(municipios.carregaDados())]);

    dispatch({ type: TELA_CARREGADA });
  });
}

export function carregaOpcoesSelect() {
  return makeThunk(async (dispatch) => {
    const emitentes = await api.nfe.emitentes();
    dispatch(dadosSelectRecebidos(emitentes.items));

    return emitentes;
  });
}

export function trocaEmitente(opcao: { value: string; label: string } | null | undefined) {
  return makeThunk(async (dispatch) => {
    // para executar 'get' somente quando id é definido
    if (!opcao) return dispatch(autofill('nfe', 'emitente', null));

    // obtém estrutura no formato de emitente da NF-e do backend
    const { informacoesAdicFisco, informacoesAdicContribuinte, ...emitente } = await api.nfe.dadosEmitente(opcao.value);

    // preenche informações adicionais relacionadas ao emitente
    if (informacoesAdicFisco) dispatch(autofill('nfe', 'informacoesAdicionaisFisco', informacoesAdicFisco));

    if (informacoesAdicContribuinte) dispatch(autofill('nfe', 'informacoesAdicionaisContribuinte', informacoesAdicContribuinte));

    return dispatch(autofill('nfe', 'emitente', emitente));
  });
}
