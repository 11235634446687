import { createSelector } from 'reselect';

import { State } from '../../../../types';
import { numberIndex } from '../../../../util/formatacao';

export const CARREGA_DADOS = 'telas/nfe/emissao/pagamento/CARREGA_DADOS';
export const MUDA_FORMA_PAGAMENTO_ATIVA = 'telas/nfe/emissao/pagamento/MUDA_FORMA_PAGAMENTO_ATIVA';
export const TELA_CARREGANDO = 'telas/nfe/emissao/pagamento/TELA_CARREGANDO';
export const TELA_CARREGADA = 'telas/nfe/emissao/pagamento/TELA_CARREGADA';

const INITIAL_STATE = {
  formaPagamentoAtiva: null as number | null,
  carregandoTela: false as boolean,
} as const;

type Actions = ReturnType<typeof mudaFormaPagamentoAtiva | typeof carregaTela | typeof finalizaCarregamentoTela>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case MUDA_FORMA_PAGAMENTO_ATIVA:
      return { ...state, formaPagamentoAtiva: action.index };
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.pagamento;

export const formaPagamentoAtivaSelector = createSelector(rootSelector, (s) => s.formaPagamentoAtiva);

export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

// ACTION CREATORS

export function mudaFormaPagamentoAtiva(index: number | string | null) {
  return { type: MUDA_FORMA_PAGAMENTO_ATIVA, index: numberIndex(index) } as const;
}

export function carregaDados() {
  return { type: CARREGA_DADOS } as const;
}

export function carregaTela() {
  return { type: TELA_CARREGANDO } as const;
}

export function finalizaCarregamentoTela() {
  return { type: TELA_CARREGADA } as const;
}
