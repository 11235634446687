import { createSelector } from 'reselect';
import { push } from 'react-router-redux';
import produce from 'immer';

import { Dispatch, State } from '../../../../../types';
import { ConfiguracoesEmitente } from '../../../../../types/api';
import api from '../../../../../api';
import { alertaModal } from '../../../../../util/dialogos';
import { formToJS } from '../../../../../util/listas';

import * as aba from './aba';

const DEFINE_ITEM_ATUAL = 'telas/ferramentas/configuracoes/emitentes/DEFINE_ITEM_ATUAL';

const INITIAL_STATE = {
  emitenteId: '' as string,
  controlaSeqNfe: false as boolean,
  contaId: '' as string,
  informacoesAdicFisco: '' as string,
  informacoesAdicContribuinte: '' as string,
} as const;

type Actions = ReturnType<typeof defineItemAtual>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_ITEM_ATUAL:
      return produce(state, (draft) => {
        draft.controlaSeqNfe = action.dados.controlaSeqNfe || false;
        draft.emitenteId = action.dados._id || '';
        draft.contaId = action.dados.contaId || '';
        draft.informacoesAdicFisco = action.dados.informacoesAdicFisco || '';
        draft.informacoesAdicContribuinte = action.dados.informacoesAdicContribuinte || '';
      });
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.ferramentas.configuracoes.emitentes.configurar;

export const contaIdSelector = createSelector(dadosAtuaisSelector, (root) => root.contaId);

// ACTION CREATORS

export function defineItemAtual(dados: ConfiguracoesEmitente) {
  return { type: DEFINE_ITEM_ATUAL, dados } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega a ID da nfe na store transacional.
 */
export function carregaRegistro(id: string) {
  return async function (dispatch: Dispatch) {
    await dispatch(aba.defineAbaAtual({ emitenteId: id, abaAtiva: 'configuracoes' }));

    const r = await api.emitentes.configuracoes(id);
    await dispatch(defineItemAtual(r));

    return r;
  };
}

export function salva(form: any) {
  return async function (dispatch: Dispatch) {
    const r = await api.emitentes.salvaConfiguracao(formToJS(form));

    if (r) {
      await alertaModal('Emitente alterado com sucesso.');
      dispatch(push(`/app/ferramentas/configuracoes`));
    }
  };
}
