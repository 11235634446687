import { push } from 'react-router-redux';

import { Dispatch, State } from '../../../../types';
import api from '../../../../api/index';
import { alertaModal, confirmaModal } from '../../../../util/dialogos';
import { formToJS } from '../../../../util/listas';

import { desabilitaBotaoComTimeout } from './botaoComCliqueUnico';

const DEFINE_ITEM_ATUAL = 'telas/nfe/consulta/cartacorrecao/DEFINE_ITEM_ATUAL';

const INITIAL_STATE = {
  nfeId: '' as string,
  correcaoASerConsiderada: '',
  envioXmlDestinatario: true,
  envioXmlTransportador: false,
  condicoesUso:
    'A Carta de Correção é disciplinada pelo § 1º-A do art. 7º do Convênio S/N, de 15 de dezembro de 1970 ' +
    'e pode ser utilizada para regularização de erro ocorrido na emissão de documento fiscal, ' +
    'desde que o erro não esteja relacionado com: I - as variáveis que determinam o valor do imposto tais como: ' +
    'base de cálculo, alíquota, diferença de preço, quantidade, valor da operação ou da prestação; ' +
    'II - a correção de dados cadastrais que implique mudança do remetente ou do destinatário; ' +
    'III - a data de emissão ou de saída.',
} as const;

type Actions = ReturnType<typeof defineItemAtual>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_ITEM_ATUAL:
      return { ...state, nfeId: action.dados };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.nfe.consulta.cartaCorrecao;

// ACTION CREATORS

export function defineItemAtual(nfeId: string) {
  return { type: DEFINE_ITEM_ATUAL, dados: nfeId } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega a ID da nfe na store transacional.
 */
export function carregaRegistro(id: string) {
  return async function (dispatch: Dispatch) {
    await dispatch(defineItemAtual(id));

    return id;
  };
}

export function navegaParaCartaCorrecao(id: string) {
  return async function (dispatch: Dispatch) {
    const r = await confirmaModal(
      'Se já existir alguma Carta de Correção para esta NF-e, ' +
        'a nova Carta de Correção deve conter todas as correções a serem consideradas. ' +
        'Deseja prosseguir?'
    );

    if (r) {
      await dispatch(push(`/app/nfe/consulta/cartacorrecao/${id}`));
    } else {
      // navega para a página de carta de correção
      await alertaModal('Operação cancelada.');
    }
  };
}

export function salva(form: any) {
  return async function (dispatch: Dispatch) {
    if (await confirmaModal('Confirma envio da Carta de Correção?')) {
      await dispatch(desabilitaBotaoComTimeout(5000));

      const r = await api.nfe.cartaCorrecao(formToJS(form));

      if (r.alertas.length !== 0) {
        const alertas = r.alertas.join('\n');

        await alertaModal(alertas);
      } else if (r.comunicacao.envioValidacaoSchema && r.comunicacao.envioValidacaoSchema.length !== 0)
        await alertaModal('Ocorreu um erro de schema. Favor contatar o suporte técnico.');
      else {
        await alertaModal('Carta de correção enviada. Verifique a resposta da SEFAZ através do menu Consulta.');
        dispatch(push(`/app/nfe/consulta/detalhes/${form.nfeId}`)); // navega para os detalhes da NFe
      }
    } else await alertaModal('Operação cancelada.');
  };
}
