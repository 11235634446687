import _ from 'lodash';
import { createSelector } from 'reselect';

import api from '../../api';
import { Cadastro, Conta } from '../../types/api';
import { extraiElementosLista, mergeIndexedBy } from '../../util/listas';
import { createRootSelector, makeThunk } from '../../types';
import { FilterableOptions } from '../../components/common/Campo/CampoProps';

export const DADOS_RECEBIDOS = 'transacionais/contas/DADOS_RECEBIDOS';
export const CONTA_RECEBIDA = 'transacionais/contas/CONTA_RECEBIDA';
export const IDS_CADASTRO_RECEBIDOS = 'transacionais/contas/IDS_CADASTRO_RECEBIDOS';

const INITIAL_STATE = {
  todas: [] as readonly Conta[],
  porId: {} as { readonly [k: string]: Conta },
  porCodigo: {} as { readonly [k: string]: Conta },
  idCadastro: [] as readonly string[],
} as const;

type ContasActions = ReturnType<typeof dadosRecebidos | typeof idsCadastroRecebidos | typeof contaRecebida>;

export default function contasReducer(state = INITIAL_STATE, action?: ContasActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case DADOS_RECEBIDOS: {
      const { contas: todas } = action.dados;
      return {
        ...state,
        todas,
        porId: mergeIndexedBy(state.porId, todas, '_id'),
        porCodigo: mergeIndexedBy(state.porCodigo, todas, 'codigo'),
      };
    }
    case IDS_CADASTRO_RECEBIDOS:
      return { ...state, idCadastro: action.dados.contas.map((c) => c._id) };
    case CONTA_RECEBIDA:
      return { ...state, porId: mergeIndexedBy(state.porId, [action.conta], '_id') };
    default:
      return state;
  }
}

// SELECTORS

const rootSelector = createRootSelector((state) => state.transacionais.contas);

export const todasContasSelector = createSelector(rootSelector, (contas) => contas.todas);

export const contasAtivasSelector = createSelector(todasContasSelector, (todas): readonly Conta[] => todas.filter((c) => c.ativa));

export const porIdCadastroSelector = createSelector(rootSelector, (root) => root.idCadastro);

export const contasCadastroSelector = createSelector(contasAtivasSelector, porIdCadastroSelector, extraiElementosLista);

export const contasPorIdSelector = createSelector(rootSelector, (contas) => contas.porId);

export const contasParaSelect = createSelector(contasCadastroSelector, (contas) => {
  const options = _(contas)
    .sortBy((c) => c.nome)
    .map((c) => {
      const { codigo } = c;
      const id = c._id;
      const { nome } = c;
      return { value: id, label: `${codigo} - ${nome}` };
    })
    .value();

  return new FilterableOptions(options);
});

export const contasParaFiltro = createSelector(todasContasSelector, (contas) => opcoesFiltroContas(contas));

function opcoesFiltroContas(contas: readonly Conta[]) {
  return _(contas)
    .sortBy('nome')
    .map(({ _id: value, codigo, nome }) => ({ value, label: `${codigo} - ${nome}` }))
    .value();
}

// ACTION CREATORS

export function idsCadastroRecebidos(dados: Cadastro) {
  return { type: IDS_CADASTRO_RECEBIDOS, dados } as const;
}

export function dadosRecebidos(dados: { contas: readonly Conta[] }) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

export function contaRecebida(conta: Conta) {
  return { type: CONTA_RECEBIDA, conta } as const;
}

// THUNK ACTION CREATORS

export function carregaDados() {
  return makeThunk(async function (dispatch) {
    const [, cadastro] = await Promise.all([dispatch(carregaContas()), api.contas.idsPermitidosParaCadastro()]);

    dispatch(idsCadastroRecebidos(cadastro));
  });
}

export function carregaContas() {
  return makeThunk(async function (dispatch) {
    const todas = await api.contas.index(); // chama a API
    dispatch(dadosRecebidos(todas)); // armazena sucesso na store
  });
}

export function carregaConta(id: string) {
  return makeThunk(async (dispatch) => {
    const r = await api.contas.show(id); // chama a API
    dispatch(contaRecebida(r.conta)); // armazena sucesso na store
    return r.conta;
  });
}
