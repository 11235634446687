import { combineReducers } from 'redux';

import emitentes from './emitentes';
import produtos from './produtos';
import destinatarios from './destinatarios';
import servicos from './servicos';
import transportadores from './transportadores';
import nfes from './nfes';
import municipios from './municipios';
import ufs from './ufs';
import paises from './paises';
import contas from './contas';

export default combineReducers({
  emitentes,
  produtos,
  destinatarios,
  servicos,
  transportadores,
  nfes,
  municipios,
  ufs,
  paises,
  contas,
});
