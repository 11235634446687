import { createSelector } from 'reselect';

import { State } from '../../types';
import { permissaoParaUsuario } from '../../util/permissoes';
import { WhoAmI } from '../../types/api';

export const LOGIN = 'gerais/whoami/LOGIN';
export const LOGOUT = 'gerais/whoami/LOGOUT';
export const DESLOGADO = 'gerais/whoami/DESLOGADO';
export const LOGADO = 'gerais/whoami/LOGADO';

const INITIAL_STATE: WhoAmI = {
  autenticado: false,
  id: undefined,
  nome: undefined,
  perfil: undefined,
  papeis: {},
  email: undefined,
} as const;

type Actions = ReturnType<typeof logado | typeof deslogado>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case LOGADO:
      return { ...INITIAL_STATE, ...action.dados, autenticado: true };
    case DESLOGADO:
      return INITIAL_STATE;
    default:
      return state;
  }
}

// SELECTORS

export const usuarioAtualSelector = (state: State) => state.gerais.whoami;
export const permissaoSelector = createSelector(usuarioAtualSelector, (u) => permissaoParaUsuario(u));

// ACTION CREATORS

export function logado(dados: WhoAmI) {
  return { type: LOGADO, dados } as const;
}

export function deslogado() {
  return { type: DESLOGADO } as const;
}

export function login() {
  return { type: LOGIN } as const;
}

export function logout() {
  return { type: LOGOUT } as const;
}
