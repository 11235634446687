import { library } from '@fortawesome/fontawesome-svg-core';

import * as fal from './fontawesome-light';
import * as far from './fontawesome-regular';
import * as fas from './fontawesome-solid';
import * as fab from './fontawesome-brand';

// Referência da forma de uso: https://fontawesome.com/how-to-use/on-the-web/using-with/react

// registra os ícones exportados
library.add(...Object.values(fal), ...Object.values(far), ...Object.values(fas), ...Object.values(fab));
