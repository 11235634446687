import moment from 'moment';

import { parseMoment } from '../../../../util/formatacao';

/**
 *  Converte a data recebida do backend para o formato utilizado no formulário.
 *  recebe data: 2017-03-29T20:44:00.000Z ou 2017-03-29T17:44:00-03:00
 *  retorna: 290320171744
 */
export function dataHoraForm(dataHora: Date | string | null | undefined) {
  const d = parseMoment(dataHora);
  return d ? d.format('DDMMYYYYHHmm') : '';
}

/**
 * Converte a data do formulário para o formato utilizado no backend.
 *  recebe data: 290320171744
 *  retorna: 2017-03-29T17:44:00-03:00
 */
export function dataHoraMongoose(dataHora: Date | string | null | undefined): string {
  if (dataHora instanceof Date) return moment(dataHora).format();

  if (!dataHora) return '';

  const dataHoraNumeros = String(dataHora).replace(/\D/g, ''); // remove qualquer caracter que não seja dígito

  if (dataHoraNumeros.length !== 12) return '';

  return moment(dataHoraNumeros, 'DDMMYYYYHmm').format();
}

/**
 *  Converte a data dos campos data recebida do backend para o formato utilizado no formulário.
 *  recebe data: 2017-03-29T20:44:00.000Z ou 2017-03-29T17:44:00-03:00
 *  retorna: 29032017
 */
export function dataForm(dataHora: string | null | undefined) {
  if (!dataHora) return '';

  const dataHoraTela = dataHoraForm(dataHora);
  return dataHoraTela && dataHoraTela.length > 8 ? dataHoraTela.substring(0, 8) : '';
}

/**
 * Converte a data dos campos data do formulário para o formato utilizado no backend.
 *  recebe data: 29032017
 *  retorna: 2017-03-29T00:00:00-03:00
 */
export function dataDeMongoose(data: string) {
  if (!data) return '';

  const dataDeComHora = data.length === 8 ? `${data}0000` : data;
  return dataHoraMongoose(dataDeComHora);
}

/**
 * Converte a data dos campos data do formulário para o formato utilizado no backend.
 *  recebe data: 29032017
 *  retorna: 2017-03-29T23:59:00-03:00
 */
export function dataAteMongoose(data: string) {
  if (!data) return '';

  const dataAteComHora = data.length === 8 ? `${data}2359` : data;
  return dataHoraMongoose(dataAteComHora);
}
