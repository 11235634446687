import { delay, put, take, takeLatest } from 'redux-saga/effects';
import { autofill, formValueSelector } from 'redux-form';
import { ARRAY_PUSH, ARRAY_REMOVE, AUTOFILL, BLUR, CHANGE, UPDATE_SYNC_WARNINGS } from 'redux-form/lib/actionTypes';
import { Action, AnyAction } from 'redux';
import { all, call, putResolve, select } from 'typed-redux-saga';

import { EmitenteNfe, ProdutoServico } from '../../types/apiNfe';
import * as expr from '../../util/expressoes';
import * as produtos from '../../reducers/telas/nfe/emissao/produto';
import * as servicos from '../../reducers/telas/nfe/emissao/servico';
import * as municipios from '../../reducers/transacionais/municipios';
import * as ufs from '../../reducers/transacionais/ufs';
import {
  carregaTela,
  finalizaCarregamentoTela,
  itemAtivoSelector,
  mudaAdicaoAtiva,
  mudaDeclaracaoAtiva,
  mudaItemAtivo,
  mudaLoteAtivo,
} from '../../reducers/telas/nfe/emissao/itens';
import { roundingNumber } from '../expr';
import { valorSeletor } from '../../util/seletor';
import { numProxy } from '../../util/numProxy';

const PREFIXO_ITENS = 'produtoServico';

const nfeSelector = formValueSelector('nfe');

export default function* sagaItensEmissao() {
  // ao abrir a aba, carregamos produtos e serviços
  yield call(carregaProdutosServicos);

  // cada vez que um novo item for adicionado, tornamos ele o item ativo
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_ITENS,
    function* (_ac: Action) {
      const arr: unknown[] = yield* select(nfeSelector, PREFIXO_ITENS);
      if (arr && arr.length > 0) {
        const pos = arr.length - 1;

        yield put(autofill('nfe', `produtoServico[${pos}].itemNumero`, pos + 1));
        yield put(autofill('nfe', `produtoServico[${pos}].tipo`, 'produto'));
        yield put(autofill('nfe', `produtoServico[${pos}].valorCompoeTotalNfe`, '1'));
        yield put(autofill('nfe', `produtoServico[${pos}].gtinPossui`, true));
        yield put(mudaItemAtivo(pos));
      }
    }
  );

  // cada vez que um item for removido, atualiza o itemNumero do item que ocupou sua posição e dos posteriores
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_REMOVE && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_ITENS,
    function* (ac: AnyAction) {
      // Após excluir um item da NF-e, ignora a saga reiniciar campo
      // porque, quando o item anterior ao ativo é de um tipo diferente do item ativo, campos novos são apresentados na tela.
      yield put(autofill('nfe', 'ignorarReiniciarCampo', true));

      const { items } = yield* obtemItemAtivo();

      if (items && items.length > 0) {
        const posItemExcluido = ac.meta.index;

        // eslint-disable-next-line no-plusplus
        for (let posItem = posItemExcluido; posItem < items.length; posItem++) {
          yield put(autofill('nfe', `produtoServico[${posItem}].itemNumero`, posItem + 1));
        }
      }

      yield delay(100); // tempo para permitir que o campos sejam apresentados no formulário
      yield put(autofill('nfe', 'ignorarReiniciarCampo', false));
    }
  );

  // cada vez que um novo lote, declaração ou adição for adicionado, tornamos ativo
  yield* tornaUltimoAtivo('.lotesProduto', mudaLoteAtivo);
  yield* tornaUltimoAtivo('.declaracoesImportacao', mudaDeclaracaoAtiva);
  yield* tornaUltimoAtivo('.adicoesImportacao', mudaAdicaoAtiva);

  // preenche campos default depois de escolher tipo de item
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field.endsWith('.tipo'),
    function* (_ac: AnyAction) {
      // quando o tipo é alterado, campos novos são adicionados na tela
      // preenche os campos default somente depois de registrar e incluir Warnings desses campos
      yield take(UPDATE_SYNC_WARNINGS);

      const { pos, item } = yield* obtemItemAtivo();

      // preenche com nulo os campos preenchidos pelo campo seletor 'Código / Descrição
      yield put(autofill('nfe', `produtoServico[${pos}].codigo`, null));
      yield put(autofill('nfe', `produtoServico[${pos}].descricao`, null));

      if (item && itemComTipoServico(item)) {
        yield put(autofill('nfe', `produtoServico[${pos}].ncm`, '00'));
      }

      yield put(autofill('nfe', `produtoServico[${pos}].valorCompoeTotalNfe`, 1));
    }
  );

  // preenche campo regime de item com regime de emitente da NF-e como valor default
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field.endsWith('.tipoTributo'),
    function* (_ac: AnyAction) {
      const { pos, item } = yield* obtemItemAtivo();

      if (itemComTipoTributoIcms(item)) {
        const emitente: EmitenteNfe = yield* select(nfeSelector, 'emitente');
        const valorRegime = emitente ? emitente.regime : null;

        yield put(autofill('nfe', `produtoServico[${pos}].regime`, valorRegime));
      }
    }
  );

  // cada vez que o campo quantidade comercial for modificado, adiciona o valor ao campo quantidade tributável
  yield* copiaCampoNaAlteracao('qtdTributavel', '.qtdComercial');
  yield* copiaCampoNaAlteracao('unidadeTributavel', '.unidadeComercial', true);
  yield* copiaCampoNaAlteracao('valorUnitarioTributavel', '.valorUnitarioComercial');

  yield* calculaCampoNaAlteracao('valorIcmsInterestadualUfRemetente', expr.VALOR_ICMS_INTERESTADUAL_UF_REMETENTE, ['.valorIcmsInterestadualUfDestino']);
  yield* calculaCampoNaAlteracao('valorIcmsDiferido', expr.VALOR_ICMS_DIFERIDO, ['.valorIcmsOperacao']);
  yield* calculaCampoNaAlteracao('valorIcms', expr.VALOR_ICMS_51, ['.valorIcmsDiferido', '.valorIcmsOperacao']);

  // cada vez que o campo gtinPossui for alterado, preenche 'SEM GTIN' ou nulo em ean e eanTributavel
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field.endsWith('.gtinPossui'),
    function* (ac: AnyAction) {
      const { pos } = yield* obtemItemAtivo();

      if (pos !== null) yield call(alteraGtin, pos, !ac.payload ? 'SEM GTIN' : null);
    }
  );

  // cada vez que o campo situacaoTributaria for alterado para o valor 900, preenche true para icms
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field.endsWith('.situacaoTributaria'),
    function* () {
      const { pos, item } = yield* obtemItemAtivo();

      if (pos !== null && item && item.situacaoTributaria) yield call(alteraIcms, pos, [900, '900'].includes(String(valorSeletor(item.situacaoTributaria))));
    }
  );
}

export function* carregaProdutosServicos() {
  yield put(carregaTela());

  // carregamento da tela é finalizado somente após os dados de município, uf, serviço e produto serem recebidos
  yield* all([
    putResolve(produtos.carregaOpcoesSelect()),
    putResolve(servicos.carregaOpcoesSelect()),
    putResolve(municipios.carregaDados()),
    putResolve(ufs.carregaDados()),
  ]);

  yield put(finalizaCarregamentoTela());
}

export function* alteraCampoComValor(valor: string, campoPreenchido: string, campoAPreencher: string) {
  const prefixoCampo = campoPreenchido.split('.').slice(0, -1).join('.');

  yield put(autofill('nfe', `${prefixoCampo}.${campoAPreencher}`, valor));
}

export function converteDecimal2(valor: number | null | undefined) {
  if (!valor && valor !== 0) return valor;

  // Os cálculos das expressões são usados em campos com 2 casas decimais
  return roundingNumber(valor, 2);
}

export function* alteraGtin(itemAtivo: number, valor: string | null | undefined) {
  yield put(autofill('nfe', `produtoServico[${itemAtivo}].ean`, valor));
  yield put(autofill('nfe', `produtoServico[${itemAtivo}].eanUnidadeTributavel`, valor));
}

export function* alteraIcms(itemAtivo: number, valor: boolean | null | undefined) {
  yield put(autofill('nfe', `produtoServico[${itemAtivo}].icms`, valor));
}

function itemComTipoServico(item: ProdutoServico) {
  return item && valorSeletor(item.tipo) === 'servico';
}

function itemComTipoTributoIcms(item: ProdutoServico | undefined) {
  return item && valorSeletor(item.tipoTributo) === 'ICMS';
}

function* obtemItemAtivo() {
  const pos = yield* select(itemAtivoSelector);
  const items: ProdutoServico[] = yield* select(nfeSelector, PREFIXO_ITENS);
  const item: ProdutoServico | undefined = pos !== null ? items[pos] : undefined;

  return { pos, items, item };
}

function* tornaUltimoAtivo(pattern: string, actionCreator: (index: any) => Action) {
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field.endsWith(pattern),
    function* (ac: AnyAction) {
      const arr: unknown[] = yield* select(nfeSelector, ac.meta.field);
      if (arr && arr.length > 0) {
        const posicaoArray = arr.length - 1;
        yield put(actionCreator(posicaoArray));
      }
    }
  );
}

function* copiaCampoNaAlteracao(campoAPreencher: string, campoAObservar: string, apenasServico = false) {
  yield takeLatest(
    (ac: AnyAction) => ac.type === BLUR && ac.meta.form === 'nfe' && ac.meta.field.endsWith(campoAObservar),
    function* (ac: AnyAction) {
      if (apenasServico) {
        const { item } = yield* obtemItemAtivo();

        if (!item || !itemComTipoServico(item)) return;
      }

      yield* call(alteraCampoComValor, ac.payload, ac.meta.field, campoAPreencher);
    }
  );
}

function* calculaCampoNaAlteracao(campoAPreencher: string, calculo: expr.Expr<ProdutoServico>, camposAObservar: string[], apenasServico = false) {
  yield takeLatest(
    (ac: AnyAction) => ac.type === AUTOFILL && ac.meta.form === 'nfe' && camposAObservar.some((c) => ac.meta.field.endsWith(c)),
    function* () {
      const { pos, item } = yield* obtemItemAtivo();

      if (!item) return;

      if (apenasServico && !itemComTipoServico(item)) return;

      const res = calculo(numProxy(item), numProxy(item));

      yield put(autofill('nfe', `produtoServico[${pos}].${campoAPreencher}`, converteDecimal2(res)));
    }
  );
}
