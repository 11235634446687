import { combineReducers } from 'redux';

import item from './item';
import emitente from './emitente';
import destinatario from './destinatario';
import nota from './nota';
import itens from './itens';
import produto from './produto';
import servico from './servico';
import totais from './totais';
import transporte from './transporte';
import cobranca from './cobranca';
import pagamento from './pagamento';
import informacoesAdicionais from './informacoesAdicionais';
import modalAlerta from './modalAlerta';
import modalImportacao from './modalImportacao';
import modalLotesProduto from './modalLotesProduto';
import upload from './upload';

export default combineReducers({
  item,
  emitente,
  destinatario,
  nota,
  itens,
  produto,
  servico,
  totais,
  transporte,
  cobranca,
  pagamento,
  informacoesAdicionais,
  modalAlerta,
  modalImportacao,
  modalLotesProduto,
  upload,
});

// exporta algumas ações diretamente, para simplificar
export { iniciaNovaNota, carregaNota, salva, validar, downloadXml, downloadXmlProtocolo, imprimirDanfe, emitir, excluir, dadosAtuaisSelector } from './item';
