import { DefaultRootState } from 'react-redux';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Selector } from 'reselect';
import { FieldArrayFieldsProps } from 'redux-form';

export interface PaginatedData<T> {
  readonly page: number;
  readonly pageSize: number;
  readonly total: number;
  readonly items: readonly T[];
}

/**
 * Estado da aplicação.
 */
export type State = DefaultRootState;

/**
 * Função que retorna o estado (usada no "thunk").
 */
export type GetState = () => State;

/**
 * Dispatch que aceita um dispatch de action padrão do redux, ou um "thunk".
 */
export interface Dispatch<A extends Action = Action> {
  <R>(t: ThunkAction<R, DefaultRootState, unknown, A>): R;

  (action: A): A;
}

/**
 * Função utilitária para declarar um thunk. Evita a necessidade de tipar os parâmetros Dispatch e GetState.
 */
export function makeThunk<R>(t: ThunkAction<R, DefaultRootState, unknown, Action>) {
  return t;
}

/**
 * Função utilitária para declarar um seletor raiz. Evita a necessidade de tipar o parâmetro State.
 */
export function createRootSelector<R>(s: Selector<DefaultRootState, R>) {
  return s;
}

/**
 * Campos do redux-form
 */
export type CamposReduxForm<T> = FieldArrayFieldsProps<T>;
