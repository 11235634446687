import { createSelector } from 'reselect';

import { State } from '../../../../types';
import { numberIndex } from '../../../../util/formatacao';

export const CARREGA_DADOS = 'telas/nfe/emissao/cobranca/CARREGA_DADOS';
export const MUDA_PARCELA_ATIVA = 'telas/nfe/emissao/cobranca/MUDA_PARCELA_ATIVA';
export const TELA_CARREGANDO = 'telas/nfe/emissao/cobranca/TELA_CARREGANDO';
export const TELA_CARREGADA = 'telas/nfe/emissao/cobranca/TELA_CARREGADA';

const INITIAL_STATE = {
  parcelaAtiva: null as number | null,
  carregandoTela: false as boolean,
} as const;

type Actions = ReturnType<typeof mudaParcelaAtiva | typeof carregaTela | typeof carregaDados | typeof finalizaCarregamentoTela>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case MUDA_PARCELA_ATIVA:
      return { ...state, parcelaAtiva: action.index };
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.cobranca;

export const parcelaAtivaSelector = createSelector(rootSelector, (s) => s.parcelaAtiva);

export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

// ACTION CREATORS

export function mudaParcelaAtiva(index: number | string | null) {
  return { type: MUDA_PARCELA_ATIVA, index: numberIndex(index) } as const;
}

export function carregaDados() {
  return { type: CARREGA_DADOS } as const;
}

export function carregaTela() {
  return { type: TELA_CARREGANDO } as const;
}

export function finalizaCarregamentoTela() {
  return { type: TELA_CARREGADA } as const;
}
