import { takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { ABRE_NOTA } from '../../reducers/telas/nfe/emissao/item';
import { CARREGA_DADOS as ABRE_ABA_NOTA } from '../../reducers/telas/nfe/emissao/nota';
import { TELA_CARREGANDO as ABRE_ABA_DESTINATARIO } from '../../reducers/telas/nfe/emissao/destinatario';
import { CARREGA_DADOS as ABRE_ABA_ITENS } from '../../reducers/telas/nfe/emissao/itens';
import { CARREGA_DADOS as ABRE_ABA_TRANSPORTE } from '../../reducers/telas/nfe/emissao/transporte';
import { CARREGA_DADOS as ABRE_ABA_TOTAIS } from '../../reducers/telas/nfe/emissao/totais';
import { CARREGA_DADOS as ABRE_ABA_COBRANCA } from '../../reducers/telas/nfe/emissao/cobranca';
import { CARREGA_DADOS as ABRE_ABA_PAGAMENTO } from '../../reducers/telas/nfe/emissao/pagamento';
import { CARREGA_DADOS as ABRE_ABA_INFORMACOES_ADICIONAIS } from '../../reducers/telas/nfe/emissao/informacoesAdicionais';
import { ABRE_TELA_UPLOAD } from '../../reducers/telas/nfe/emissao/upload';

import sagaEmissaoUpload from './upload';
import sagaEmissaoNota from './nota';
import sagaEmissaoDestinatario from './destinatario';
import sagaEmissaoItens from './itens';
import sagaEmissaoTransporte from './transporte';
import sagaEmissaoTotais from './totais';
import sagaEmissaoVoltar from './voltar';
import sagaEmissaoDuplicata from './duplicatas';
import sagaEmissaoFormaPagamento from './formasPagamento';
import sagaEmissaoInformacoesAdicionais from './informacoesAdicionais';

export default function* sagaEmissao() {
  /**
   * A ação ABRE_NOTA é chamada quando o usuário troca a nota ativa para emissão.
   *
   * Quando isso acontece, queremos iniciar algumas sagas para tratar as possíveis ações que o usuário pode realizar.
   */

  // eslint-disable-next-line require-yield
  yield takeLatest(ABRE_NOTA, function* ({ nota }: AnyAction) {
    // eslint-disable-next-line no-console
    console.log('editando nota:', nota);
  });

  yield takeLatest(ABRE_TELA_UPLOAD, sagaEmissaoUpload);
  yield takeLatest(ABRE_ABA_NOTA, sagaEmissaoNota);
  yield takeLatest(ABRE_NOTA, sagaEmissaoVoltar);
  yield takeLatest(ABRE_ABA_DESTINATARIO, sagaEmissaoDestinatario);
  yield takeLatest(ABRE_ABA_ITENS, sagaEmissaoItens);
  yield takeLatest(ABRE_ABA_TRANSPORTE, sagaEmissaoTransporte);
  yield takeLatest(ABRE_ABA_TOTAIS, sagaEmissaoTotais);
  yield takeLatest(ABRE_ABA_COBRANCA, sagaEmissaoDuplicata);
  yield takeLatest(ABRE_ABA_PAGAMENTO, sagaEmissaoFormaPagamento);
  yield takeLatest(ABRE_ABA_INFORMACOES_ADICIONAIS, sagaEmissaoInformacoesAdicionais);
}
