import { autofill, formValueSelector } from 'redux-form';
import { put, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { CHANGE } from 'redux-form/lib/actionTypes';
import { AnyAction } from 'redux';

import { carregaOpcoesSelect, porIdSelector } from '../../reducers/telas/nfe/consulta/opcoesParaFiltroEmitente';

const filtroSelector = formValueSelector('nfeFiltrar');

export default function* sagaFiltrosNfe() {
  // quando preenche dataEmissaoDe e o dataEmissaoAte não está preenchido, preenche dataEmissaoAte com valor de dataEmissaoDe
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfeFiltrar' && ac.meta.field.includes('dataEmissaoDe') && ac.payload.length === 8,
    function* (ac: AnyAction) {
      yield call(atualizaDataEmissaoAte, ac.payload);
    }
  );

  // cada vez que o campo contaId ou possuiEmitente for modificado, atualiza lista de emitentes em emitenteId
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfeFiltrar' && ['contaId', 'possuiEmitente'].includes(ac.meta.field),
    function* (_ac: AnyAction) {
      yield* call(atualizaEmitente);
    }
  );
}

export function* atualizaDataEmissaoAte(dataEmissaoDe: string) {
  const dataEmissaoAte = yield select(filtroSelector, 'dataEmissaoAte');

  if (!dataEmissaoAte) {
    yield put(autofill('nfeFiltrar', 'dataEmissaoAte', dataEmissaoDe));
  }
}

export function* atualizaEmitente() {
  const possuiEmitente = yield select(filtroSelector, 'possuiEmitente');

  // Se possuiEmitente igual a false, lista de emitenteId deve estar desabilitada.
  if (possuiEmitente === false) {
    yield put(autofill('nfeFiltrar', 'emitenteId', null));
    return;
  }

  let opcaoContaId = yield select(filtroSelector, 'contaId');

  // Se a opcaoContaId é string, converte pra object para listar corretamente o Emitente
  if (typeof opcaoContaId === 'string') {
    opcaoContaId = { value: opcaoContaId };
  }

  // Se contaId é nula, atualiza lista de emitente com todos os emitentes.
  if (!opcaoContaId) {
    yield put(carregaOpcoesSelect({}));
    return;
  }

  const opcaoEmitenteId = yield* select(filtroSelector, 'emitenteId');
  const emitentesSelect = yield* select(porIdSelector);
  const emitSelecionado = emitentesSelect && opcaoEmitenteId ? emitentesSelect[opcaoEmitenteId.value] : null;

  // Se o emitente de emitenteId possuir contaId diferente do parâmetro, reinicia emitenteId
  if (!emitSelecionado || opcaoContaId !== emitSelecionado.contaId) {
    yield put(autofill('nfeFiltrar', 'emitenteId', null));
  }

  // Se usuário selecionou uma conta e possuiEmitente diferente de false, atualiza lista de emitente.
  yield put(carregaOpcoesSelect({ contaId: opcaoContaId.value }));
}
