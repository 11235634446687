import { autofill } from 'redux-form';
import { push } from 'react-router-redux';
import { AnyAction } from 'redux';

import { Dispatch, makeThunk, State } from '../../../../types';
import { UploadNfe } from '../../../../types/apiNfe';
import api from '../../../../api';
import { alertaModal } from '../../../../util/dialogos';
import { valorFormData } from '../../../../util/formatacao';
import { formToJS } from '../../../../util/listas';

import * as emitentes from './emitente';
import { abreModalAlerta } from './modalAlerta';

export const ABRE_TELA_UPLOAD = 'telas/nfe/emissao/upload/ABRE_TELA_UPLOAD';
export const ABRE_ALERTA_VALIDAR = 'telas/nfe/emissao/upload/ALERTAS_VALIDAR_SALVAR';

const INITIAL_STATE = {
  emitenteId: '',
  ambiente: '',
  arquivo: '',
} as const;

export default function reducer(state = INITIAL_STATE, action: AnyAction): typeof INITIAL_STATE {
  switch (action.type) {
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.nfe.emissao.upload;

// ACTION CREATORS

export function abreTelaUpload() {
  return { type: ABRE_TELA_UPLOAD } as const;
}

export function abreAlertaValidar(nfeId: string) {
  return { type: ABRE_ALERTA_VALIDAR, nfeId } as const;
}

// THUNK ACTION CREATORS

export function carregaDados() {
  return async function (dispatch: Dispatch) {
    await dispatch(abreTelaUpload());
    const r = await api.nfe.ambiente();
    await dispatch(autofill('nfeEmissaoUpload', 'ambiente', r.ambiente));

    await dispatch(emitentes.carregaOpcoesSelect());
  };
}

export function salva(form: any) {
  const formInJS = formToJS(form);

  const formData = new FormData();
  formData.append('arquivo', valorFormData(formInJS.arquivo));
  formData.append('ambiente', valorFormData(formInJS.ambiente));
  formData.append('emitenteId', valorFormData(formInJS.emitenteId));

  return makeThunk(async function (dispatch) {
    const r: UploadNfe = await api.nfe.emitirUpload(formData);
    const { nfeId } = r;
    const existeAlertas = r.alertas && r.alertas.length !== 0;

    // Alerta da validação ou importação do arquivo da RN aut.01
    if (existeAlertas && r.tipo === 'upload') {
      await alertaModal(r.alertas.join('\n'));
      return;
    }

    if (!nfeId) {
      await alertaModal('Ocorreu um erro na criação da NF-e.');
      return;
    }

    if (existeAlertas) {
      // Alerta da RN salv.01
      if (r.tipo === 'validar') {
        await dispatch(abreModalAlerta(r.alertas));
        dispatch(abreAlertaValidar(nfeId));
        return;
      }

      const alertas = r.alertas.join('\n');

      // Alerta da RN emis.01
      if (r.tipo === 'emissao') {
        await alertaModal(alertas);
        dispatch(push(`/app/nfe/consulta/detalhes/${nfeId}`));
        return;
      }

      await alertaModal(alertas);
      return;
    }

    await alertaModal('NF-e enviada e emitida com sucesso.');
    dispatch(push(`/app/nfe/consulta/detalhes/${nfeId}`));
  });
}
