import { createSelector } from 'reselect';

import { Dispatch, State } from '../../../../types';

const SET_BOTAO = 'telas/nfe/consulta/botaoComCliqueUnico/SET_BOTAO';

const INITIAL_STATE = {
  botaoDesabilitado: false as boolean,
} as const;

type Actions = ReturnType<typeof setBotao>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case SET_BOTAO:
      return { ...state, botaoDesabilitado: action.botaoDesabilitado };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.consulta.botaoComCliqueUnico;
export const botaoDesabilitadoSelector = createSelector(rootSelector, (d) => d.botaoDesabilitado);

// ACTION CREATORS

export function setBotao(botaoDesabilitado: boolean) {
  return { type: SET_BOTAO, botaoDesabilitado } as const;
}

// THUNK ACTION CREATORS

export function desabilitaBotaoComTimeout(segundos: number) {
  return async function (dispatch: Dispatch) {
    setTimeout(() => {
      dispatch(setBotao(false));
    }, segundos);

    dispatch(setBotao(true));
  };
}
