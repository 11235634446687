import { call, put, select, takeEvery } from 'redux-saga/effects';
import { change, formValueSelector, actionTypes } from 'redux-form';
import { AnyAction } from 'redux';

export default function* sagaReiniciarCampo() {
  // atribui nulo ao campo quando ele é desabilitado do formulário.
  yield takeEvery(
    (ac: AnyAction) => ac.type === actionTypes.UNREGISTER_FIELD && ac.meta.form && ac.payload.name,
    function* (ac: { meta: { form: string }; payload: any }) {
      let ignorarReiniciarCampo = false;

      if (ac.meta.form === 'nfe') ignorarReiniciarCampo = yield select(formValueSelector('nfe'), 'ignorarReiniciarCampo');

      if (!ignorarReiniciarCampo) yield call(reiniciaCampo, ac.meta.form, ac.payload.name);
    }
  );
}

export function* reiniciaCampo(form: string, name: string) {
  if (!form || !name) return;

  const campo = yield select(formValueSelector(form), name);

  if (campo || campo === 0) yield put(change(form, name, null));
}
