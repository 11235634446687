import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import Bugsnag from '../config/bugsnag';

import { alertaModal } from './dialogos';

const lastActions: string[] = [];
let lastHandled: Error;

export default function crashMiddleware<S, A extends Action>() {
  return (next: ThunkDispatch<S, never, A>) => async (action: ThunkAction<S, S, never, A> | A) => {
    try {
      lastActions.unshift(typeof action === 'function' ? action.name : action.type);
      while (lastActions.length > 10) lastActions.pop();
      return await next(action as any);
    } catch (err) {
      if (lastHandled !== err) {
        lastHandled = err;
        window.console.error(err);
        await alertaModal(err.message);

        try {
          Bugsnag.notify(err, (report) => report.addMetadata('redux', { lastActions }));
        } catch (e) {
          // ignorar
        }
      }

      throw err;
    }
  };
}
