import { createSelector } from 'reselect';

import api from '../../api';
import { createRootSelector, Dispatch } from '../../types';
import { Emitente } from '../../types/api';
import { mergeIndexedBy } from '../../util/listas';

const DADOS_RECEBIDOS = 'transacionais/emitentes/DADOS_RECEBIDOS';

const INITIAL_STATE = {
  porId: {} as Record<string, Emitente>,
} as const;

type EmitentesActions = ReturnType<typeof dadosRecebidos>;

export default function reducer(state = INITIAL_STATE, action?: EmitentesActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case DADOS_RECEBIDOS:
      return { ...state, porId: mergeIndexedBy(state.porId, action.dados, '_id') };
    default:
      return state;
  }
}

// SELECTORS

const rootSelector = createRootSelector((state) => state.transacionais.emitentes);

export const porIdSelector = createSelector(rootSelector, (root) => root.porId);

// ACTION CREATORS

export function dadosRecebidos(dados: Emitente[]) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaDados() {
  return async function (dispatch: Dispatch) {
    const todos = await api.emitentes.index();
    dispatch(dadosRecebidos(todos));

    return todos;
  };
}
