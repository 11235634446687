import Url from 'url';
import { ParsedUrlQueryInput } from 'querystring';

import { push } from 'react-router-redux';
import { reset } from 'redux-form';

import { Dispatch, State } from '../../../../types';
import { formularioJS } from '../../../../util/listas';

import * as destinatarioListaActions from './lista';
import * as destinatarioOpcoesSeletor from './opcoesSeletor';

const REINICIA_FILTROS = 'telas/cadastro/destinatarios/filtros/REINICIA_FILTROS';

const INITIAL_STATE = {
  contaId: '' as string,
  clienteId: '' as string,
  nomeFantasia: '' as string,
  email: '' as string,
} as const;

type Actions = ReturnType<typeof reiniciaFiltros>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case REINICIA_FILTROS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.cadastro.destinatario.filtros;

// ACTION CREATORS

export function reiniciaFiltros() {
  return { type: REINICIA_FILTROS };
}

// THUNK ACTION CREATORS

export function salva(form: any) {
  const formInJS = formularioJS(form);

  return async function (dispatch: Dispatch) {
    await dispatch(push(prepareUrl(formInJS)));
    await dispatch(destinatarioListaActions.carregaLista(formInJS));
  };
}

export function limpaFiltros() {
  return async function (dispatch: Dispatch) {
    await dispatch(reiniciaFiltros());
    await dispatch(destinatarioOpcoesSeletor.carregaOpcoesSelect());

    await dispatch(reset('destinatarioFiltrar'));

    await dispatch(push(prepareUrl({})));
    await dispatch(destinatarioListaActions.carregaLista({}));
  };
}

function prepareUrl(query: ParsedUrlQueryInput) {
  return Url.format({ pathname: '/app/cadastro/destinatarios', query });
}
