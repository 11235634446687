import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import gerais from './gerais';
import telas from './telas';
import transacionais from './transacionais';
import constantes from './constantes';
import routing from './routing';
import expr from './expr';

export default combineReducers({
  gerais,
  telas,
  transacionais,
  constantes,
  routing,
  form,
  expr,
});
