import { call, delay, put } from 'redux-saga/effects';

import api from '../api';
import { deslogado, logado } from '../reducers/gerais/whoami';

/**
 * O tempo entre as chamadas de whoami.
 * Definido para 2min em produção e 5min em desenvolvimento.
 */
const TEMPO_ENTRE_WHOAMIS = (process.env.NODE_ENV === 'production' ? 2 : 5) * 60 * 1000;

/**
 * Esta saga é responsável por manter sempre atualizadas as informações do usuário conectado.
 *
 * @see module:reducers/whoami
 */
export default function* sagaWhoAmI() {
  while (true) {
    yield call(buscaESalvaInformacoes); // busca e salva informações do usuário logado no backend
    yield delay(TEMPO_ENTRE_WHOAMIS); // aguarda um tempo antes de buscar novamente
  }
}

export function* buscaESalvaInformacoes() {
  try {
    const r = yield call(api.whoami); // chama API do backend para obter informações do usuário logado
    yield put(logado(r)); // salva informações na store
  } catch (e) {
    if (e.name === 'UnauthorizedError')
      // se o usuário não estiver mais logado,
      yield put(deslogado()); // limpa dados do usuário e redireciona ao raiz
  }
}
