import React, { Suspense } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import Header from './Header';
import Footer from './Footer';
import DialogoUsuario from './DialogoUsuario';
import StatusBackend from './StatusBackend';

type BaseAppProps = {
  children?: React.ReactNode;
};

export default function BaseApp({ children }: BaseAppProps) {
  const loadingOverlay = (
    <LoadingOverlay active spinner text="Carregando, aguarde...">
      <div style={{ minHeight: '50vh' }} />
    </LoadingOverlay>
  );

  return (
    <section>
      <Header />
      <DialogoUsuario />
      <section className="conteudo">
        <Suspense fallback={loadingOverlay}>{children}</Suspense>
      </section>
      <Footer />
      <div className="text-center">
        <StatusBackend />
      </div>
    </section>
  );
}
