/* eslint-disable no-restricted-syntax */

import { put, take } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { autofill } from 'redux-form';

import * as item from '../../reducers/telas/nfe/emissao/item';
import { carregaTela, finalizaCarregamentoTela, dadosCalculados } from '../../reducers/telas/nfe/emissao/totais';
import { calculaValoresTotais, ValoresTotais } from '../../util/valorTotal';
import { ABRE_NOTA } from '../../reducers/telas/nfe/emissao/item';

import { calculaValorTroco } from './formasPagamento';
import { converteDecimal2 } from './itens';

const CAMPOS_TOTAIS: Array<keyof ValoresTotais> = [
  'totalBcIcmsNfe',
  'totalIcmsNfe',
  'totalIcmsDesoneradoNfe',
  'totalIcmsFcpUfDestinoNfe',
  'totalIcmsInterestadualUfDestinoNfe',
  'totalIcmsInterestadualUfRemetenteNfe',
  'totalBcIcmsStNfe',
  'totalIcmsStNfe',
  'totalFreteNfe',
  'totalSeguroNfe',
  'totalDescontoNfe',
  'totalIiNfe',
  'totalIpiNfe',
  'totalPisNfe',
  'totalCofinsNfe',
  'totalOutrasDespesas',
  'totalProdutosServicos',
  'totalBcIssqnNfe',
  'totalIssNfe',
  'totalPisServicos',
  'totalCofinsServicos',
  'totalNaoIncidencia',
  'totalDeducaoBcServicos',
  'totalDescontoIncondicionado',
  'totalDescontoCondicionado',
  'totalRetencaoIss',
  'totalIpiDevolvidoNfe',
  'totalTributosValorAproximado',
  'totalFcpNfe',
  'totalFcpRetidoStNfe',
  'totalFcpRetidoAnteriormenteStNfe',
];

export default function* sagaTotaisEmissao() {
  yield* call(carregaDados);
}

/**
 * Atualiza dados do formulário com valoresTotais calculados.
 */
export function* defineTotais() {
  // prepara dados para a aba de totais
  let valoresTotais: ValoresTotais = {};

  const produtoServico = yield* select(item.produtoServicoSelector);
  if (produtoServico) {
    valoresTotais = calculaValoresTotais({ produtoServico });

    yield put(dadosCalculados(valoresTotais));
  }

  // preenche os campos do formulário com o valor calculado (ou zero, se não foi calculado)
  // Desabilita warning por causa de bug do ESLint em variável definida no for
  for (const t of CAMPOS_TOTAIS) {
    yield put(autofill('nfe', t, converteDecimal2(valoresTotais[t]) || 0.0));
  }

  yield put(autofill('nfe', 'valorTotalNfe', converteDecimal2(valoresTotais.valorTotalNfe) || 0.0));

  yield call(calculaValorTroco);
}

function* carregaDados() {
  yield put(carregaTela());

  // o formulário de totais é atualizado após abrir nota
  // aguarda até abrir a nota para calcular totais
  yield take(ABRE_NOTA);

  yield call(defineTotais);

  yield put(finalizaCarregamentoTela());
}
