import { createSelector } from 'reselect';

import { State } from '../../../../types';

export const CARREGA_DADOS = 'telas/nfe/emissao/informacoesAdicionais/CARREGA_DADOS';
export const TELA_CARREGANDO = 'telas/nfe/emissao/informacoesAdicionais/TELA_CARREGANDO';
export const TELA_CARREGADA = 'telas/nfe/emissao/informacoesAdicionais/TELA_CARREGADA';

const INITIAL_STATE = {
  carregandoTela: false as boolean,
} as const;

type Actions = ReturnType<typeof carregaTela | typeof finalizaCarregamentoTela>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.informacoesAdicionais;

export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

// ACTION CREATORS

export function carregaDados() {
  return { type: CARREGA_DADOS } as const;
}

export function carregaTela() {
  return { type: TELA_CARREGANDO } as const;
}

export function finalizaCarregamentoTela() {
  return { type: TELA_CARREGADA } as const;
}
