import Url from 'url';
import { ParsedUrlQueryInput } from 'querystring';

import { push } from 'react-router-redux';
import { autofill, reset } from 'redux-form';

import { Dispatch, GetState, State } from '../../../../../types';
import { usuarioAtualSelector } from '../../../../gerais/whoami';
import { formularioJS } from '../../../../../util/listas';

export type FiltroForm = {
  contaId?: { value: string; label: string };
  contasComNfe?: string;
};

const INITIAL_STATE = {
  contaId: null,
  contasComNfe: false,
} as const;

export default function reducer(state = INITIAL_STATE): typeof INITIAL_STATE {
  return state;
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.ferramentas.configuracoes.contas.filtros;

// THUNK ACTION CREATORS

export function salva(form: any) {
  const formInJS = formularioJS(form);

  return async function (dispatch: Dispatch) {
    await dispatch(push(prepareUrl(formInJS)));
  };
}

export function limpaFiltros() {
  return function (dispatch: Dispatch) {
    dispatch(reset('contaFiltrar'));

    dispatch(push(prepareUrl({})));
  };
}

export function atualizaFiltros(query: FiltroForm) {
  return function (dispatch: Dispatch, getState: GetState) {
    const perfilDoUsuarioAtual = usuarioAtualSelector(getState()).perfil;

    Object.entries(query).forEach(([campo, valor]) => {
      // contasComNfe é filtro de suporte
      if (campo === 'contasComNfe' && perfilDoUsuarioAtual !== 'suporte') {
        dispatch(autofill('contaFiltrar', campo, ''));
      } else {
        dispatch(autofill('contaFiltrar', campo, formataDado(campo, valor)));
      }
    });
  };
}

/**
 * Verifica se o campo é tipo booleano e converte valor para valor válido no formulário.
 */
function formataDado(campo: string, valor: unknown) {
  switch (campo) {
    case 'ativa':
    case 'contasComNfe':
      return valor === 'true';

    default:
      return valor;
  }
}

function prepareUrl(query: ParsedUrlQueryInput) {
  return Url.format({
    pathname: '/app/ferramentas/configuracoes/contas',
    query,
  });
}
