import url from 'url';
import { ParsedUrlQueryInput } from 'querystring';

import { createSelector } from 'reselect';
import Cookies from 'universal-cookie';

import { Dispatch, State } from '../../../../types';
import * as alertas from '../../../gerais/alertas';
import * as contasActions from '../../../transacionais/contas';
import * as destinatariosActions from '../../../transacionais/destinatarios';
import { Destinatario, ItensPaginados } from '../../../../types/api';

import * as destinatarioOpcoesSeletor from './opcoesSeletor';

export const CARREGANDO = 'telas/cadastro/destinatarios/lista/CARREGANDO';
export const CARREGADA = 'telas/cadastro/destinatarios/lista/CARREGADA';
export const ALTERA_LAST_PATH = 'telas/cadastro/destinatarios/lista/ALTERA_LAST_PATH';

const cookie = new Cookies();
const PATH_CADASTRO_DESTINATARIOS = '/app/cadastro/destinatarios';

const INITIAL_STATE = {
  carregando: false as boolean,
  resultado: {
    total: 0 as number,
    page: 0 as number,
    pageSize: 25 as number,
    items: [] as string[],
  },
  lastPathDestinatarios: cookie.get('lastPathDestinatarios') || PATH_CADASTRO_DESTINATARIOS,
} as const;

type Actions = ReturnType<typeof listaCarregando | typeof listaCarregada | typeof alteraLastPath>;

export default function reducer(state = INITIAL_STATE, action?: Actions): typeof INITIAL_STATE {
  switch (action?.type) {
    case CARREGANDO:
      return { ...state, carregando: true };
    case CARREGADA:
      return { ...state, resultado: { ...action.resultado, items: action.resultado.items.map((i) => i._id || '') }, carregando: false };
    case ALTERA_LAST_PATH:
      return { ...state, lastPathDestinatarios: action.path };
    default:
      return state;
  }
}

/* ================= */
/* * * SELECTORS * * */
/* ================= */

export const rootSelector = (state: State) => state.telas.cadastro.destinatario.lista;
export const lastPathSelector = createSelector(rootSelector, (root) => root.lastPathDestinatarios || PATH_CADASTRO_DESTINATARIOS);

// ACTION CREATORS

export function listaCarregando() {
  return { type: CARREGANDO } as const;
}

export function listaCarregada(resultado: ItensPaginados<Destinatario>) {
  return { type: CARREGADA, resultado } as const;
}

export function alteraLastPath(path: string) {
  return { type: ALTERA_LAST_PATH, path } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query?: ParsedUrlQueryInput) {
  return async function (dispatch: Dispatch) {
    try {
      const urlLastPath = url.format({
        pathname: PATH_CADASTRO_DESTINATARIOS,
        query,
      });

      await dispatch(alteraLastPath(urlLastPath));
      cookie.set('lastPathDestinatarios', urlLastPath);

      await dispatch(listaCarregando());
      await dispatch(contasActions.carregaDados());
      await dispatch(destinatarioOpcoesSeletor.carregaOpcoesSelect());

      const r = await dispatch(destinatariosActions.carregaLista(query));

      await dispatch(listaCarregada(r));
    } catch (e) {
      await dispatch(alertas.erro(e));
    }
  };
}
