import { State } from '../../../../types';

export const ABRE_MODAL = 'telas/nfe/emissao/modalAlerta/ABRE_MODAL';
export const FECHA_MODAL = 'telas/nfe/emissao/modalAlerta/FECHA_MODAL';

const INITIAL_STATE = {
  open: false as boolean,
  conteudo: [] as readonly string[],
} as const;

type Actions = ReturnType<typeof abreModalAlerta | typeof fechaModalAlerta>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case ABRE_MODAL:
      return { ...state, open: true, conteudo: action.conteudo };
    case FECHA_MODAL:
      return { ...state, open: false, conteudo: [] };
    default:
      return state;
  }
}

// SELECTORS

export const openModalSelector = (state: State) => state.telas.nfe.emissao.modalAlerta.open || false;
export const conteudoModalSelector = (state: State) => state.telas.nfe.emissao.modalAlerta.conteudo || [];

// ACTION CREATORS

export function abreModalAlerta(conteudo: string[]) {
  return { type: ABRE_MODAL, conteudo } as const;
}

export function fechaModalAlerta() {
  return { type: FECHA_MODAL } as const;
}
