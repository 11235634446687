import { SubmissionError } from 'redux-form';

import Bugsnag from '../config/bugsnag';

import { HttpError, ServerValidationError, UnauthorizedError } from './errors';

type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE';

export function json<T>(method: HttpMethod, body?: T) {
  return {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body || {}),
  };
}

export function formData<T>(method: HttpMethod, body: T) {
  return { method, body };
}

function backendPrefix(env: { NODE_ENV?: string }) {
  switch (env.NODE_ENV) {
    case 'development':
      return 'http://localhost:3001';
    case 'test':
      return 'http://blackhole';
    default:
      return '';
  }
}

export function backendUri(uri: string, env: { NODE_ENV?: string; BACKEND_PREFIX?: string } = process.env) {
  const prefix = env.BACKEND_PREFIX || backendPrefix(env);

  return `${prefix}${uri}`;
}

export async function fetchJSON(uri: string, opts?: RequestInit) {
  const r = await global.fetch(backendUri(uri), {
    credentials: 'include',
    ...(opts || {}),
  });
  return await handleCrudResponse(r);
}

async function handleCrudResponse<T>(r: Response): Promise<T | null> {
  // se a API não permite a operação, lança um erro
  if (r.status === 401) throw new UnauthorizedError('Usuário não autenticado ou sem permissão');

  // se ocorreram erros de validação, lança um erro
  if (r.status === 422) {
    const rJson = await r.json();
    // se temos dados sobre a validação, repassa para o redux-form interpretar.
    // caso contrário, apenas lança um erro genérico
    if (rJson && rJson.validationErrors) throw new SubmissionError(rJson.validationErrors);
    else if (rJson && rJson.message) throw new ServerValidationError(`Ocorreram erros de validação: ${rJson.message}`);
    else throw new ServerValidationError('Ocorreram erros de validação');
  }

  // se o retorno é 204: no content, retorna nulo
  if (r.status === 204) return null;

  // se ocorreu algum outro erro, lança um erro genérico e envia ao Bugsnag para análise
  if (r.status !== 200 && r.status !== 201) throw await makeAndReportError(r);

  // caso contrário, retorna o JSON recebido pela API
  return r.json();
}

async function makeAndReportError(r: Response) {
  const body = await r.text().catch((e: Error) => `Erro ao ler corpo: ${e}`);

  const e = new HttpError(`Erro ao comunicar com o servidor: ${r.status}`, r);

  Bugsnag.notify(e, (report) => {
    report.addMetadata('fetch', {
      status: r.status,
      url: r.url,
      body,
      headers: r.headers,
    });
  });

  return e;
}
