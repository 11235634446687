import { WhoAmI } from '../types/api';

export type Permissao = 'deslogado' | 'suporte' | 'com_acesso' | 'sem_acesso';

export function permissaoParaUsuario(usuarioAtual: WhoAmI): Permissao {
  if (!usuarioAtual || !usuarioAtual.autenticado) return 'deslogado';

  if (usuarioAtual.perfil === 'suporte') return 'suporte';

  if (usuarioAtual.acesso) return 'com_acesso';

  return 'sem_acesso';
}
