import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

type UsuarioLogadoProps = {
  usuarioAtual: {
    name?: string;
  };
};

export default function UsuarioLogado({ usuarioAtual }: UsuarioLogadoProps) {
  return (
    <div>
      <span className="dialogoUsers">{usuarioAtual.name}</span>{' '}
      <LinkContainer to="/app/sair">
        <Button className="btnReal">
          <FontAwesomeIcon icon={['fas', 'power-off']} size="1x" /> SAIR
        </Button>
      </LinkContainer>
    </div>
  );
}
