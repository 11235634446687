import { push } from 'react-router-redux';
import _ from 'lodash';

import { makeThunk, State } from '../../../../types';
import * as transportadoresActions from '../../../transacionais/transportadores';
import * as municipiosActions from '../../../transacionais/municipios';
import * as ufsActions from '../../../transacionais/ufs';
import * as contasActions from '../../../transacionais/contas';
import api from '../../../../api';
import { obterTituloEdicao } from '../util';
import { alertaModal, confirmaModal } from '../../../../util/dialogos';
import { formToJS } from '../../../../util/listas';
import { Transportador } from '../../../../types/api';
import { alertaObrigatorio, obterMsgAlerta, salvaRegistro } from '../salvaRegistro';

const DEFINE_TITULO = 'telas/cadastro/transportadores/detalhes/DEFINE_TITULO';
const NOVO = 'telas/cadastro/transportadores/detalhes/NOVO';
const DEFINE_ITEM_ATUAL = 'telas/cadastro/transportadores/detalhes/DEFINE_ITEM_ATUAL';

const TRANSPORTADOR_VAZIO = {
  nomeRazaoSocial: '',
  tipoDocumento: '',
  cpf: '',
  cnpj: '',
  inscricaoEstadual: '',
  telefone: '',
  email: '',
  contaId: '',
  endereco: {
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    uf: '',
    pais: '',
  },
  ativo: true,
} as Readonly<Transportador>;

const INITIAL_STATE = {
  titulo: '' as string,
  item: TRANSPORTADOR_VAZIO,
} as const;

type Actions = ReturnType<typeof defineTitulo | typeof defineItemAtual | typeof novoTransportador>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_TITULO:
      return { ...state, titulo: action.titulo };
    case DEFINE_ITEM_ATUAL:
      return { ...state, item: { ...TRANSPORTADOR_VAZIO, ...action.transportador } };
    case NOVO:
      return { ...state, item: TRANSPORTADOR_VAZIO };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.cadastro.transportador.detalhes.item;

export const tituloSelector = (state: State): string => state.telas.cadastro.transportador.detalhes.titulo;

// ACTION CREATORS

export function defineTitulo(titulo: string) {
  return { type: DEFINE_TITULO, titulo } as const;
}

export function novoTransportador() {
  return { type: NOVO } as const;
}

export function defineItemAtual(transportador: Transportador) {
  return { type: DEFINE_ITEM_ATUAL, transportador } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega o transportador com o ID selecionado na store transacional, e copia os dados para a tela.
 */
export function carregaRegistro(id: string) {
  return makeThunk(async (dispatch) => {
    const transportador = await dispatch(transportadoresActions.carregaTransportador(id));
    await dispatch(defineItemAtual(transportador));
    await dispatch(defineTitulo(obterTituloEdicao(transportador.nomeRazaoSocial)));

    await dispatch(contasActions.carregaDados());
    await dispatch(municipiosActions.carregaDados());
    await dispatch(ufsActions.carregaDados());

    return transportador;
  });
}

/**
 + * Carrega novo transportador e contas na store.
 + */
export function carregaNovoTransportador() {
  return makeThunk(async (dispatch) => {
    await dispatch(novoTransportador());
    await dispatch(defineTitulo('Novo Transportador'));

    await dispatch(contasActions.carregaDados());
    await dispatch(municipiosActions.carregaDados());
    await dispatch(ufsActions.carregaDados());
  });
}

export function salva(form: any) {
  const formInJS: Partial<Transportador> = formToJS(form);

  const formSemDataCriacao = _.omit(formInJS, 'criadoEm');

  return makeThunk(async (dispatch) => {
    const alertasValidacao = await api.transportadores.validar(formSemDataCriacao);

    if (alertaObrigatorio(alertasValidacao)) {
      throw new Error(obterMsgAlerta(alertasValidacao, 'transportador'));
    }

    if (await salvaRegistro(alertasValidacao, 'transportador')) {
      const r = await dispatch(transportadoresActions[formInJS._id ? 'atualiza' : 'cria'](formSemDataCriacao));

      dispatch(push(`/app/cadastro/transportadores/${r._id}`)); // navega para a tela de detalhes

      return r;
    }
    throw new Error('Envio do formulário cancelado.');
  });
}

export function excluir() {
  return makeThunk(async (dispatch, getState) => {
    // busca o transportador na store, ou um mapa vazio se não encontrar
    const transportador = dadosAtuaisSelector(getState());

    if (!transportador._id) return;

    if (await confirmaModal('Confirma a exclusão deste transportador?')) {
      let registroExcluido = true;
      try {
        await api.transportadores.excluir(transportador._id);
      } catch (e) {
        registroExcluido = false;
        await alertaModal(e.message);
      }

      if (registroExcluido) {
        await alertaModal('Transportador excluído.');
        dispatch(push(`/app/cadastro/transportadores`)); // navega para a página de transportadores
      }
    } else {
      await alertaModal('Operação cancelada.');
    }
  });
}
