import React from 'react';

import './Footer.css';

export default function Footer() {
  return (
    <footer id="footer" className="Footer">
      <div className="container">
        <div className="col-md-12">
          <div className="pull-left">
            <p>Emissor NF-e by Consyst-e - Todos os direitos reservados &copy; 2016-{new Date().getFullYear()}</p>
            <p>Suporte Técnico: suporte@consyste.com.br</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
