import { call, put, takeLatest } from 'redux-saga/effects';
import { actionTypes, autofill, formValueSelector } from 'redux-form';
import { CHANGE } from 'redux-form/lib/actionTypes';
import { AnyAction } from 'redux';
import { select } from 'typed-redux-saga';

const COD_PAIS = 'codPais';
const MUNICIPIO_EXTERIOR = 9999999;
const UF_EXTERIOR = 'EX';

const destinatarioSelector = formValueSelector('destinatario');

export default function* sagaDestinatario() {
  // cada vez que o campo pais for modificado, altera valor de municipio
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'destinatario' && ac.meta.field.includes(COD_PAIS),
    function* (ac: AnyAction) {
      yield call(alteraMunicipio, ac.payload);
    }
  );

  // cada vez que o campo Nome/Razão social for preenchido e o Nome Fantasia for vazio,
  // preenche campo Nome Fantasia com o valor do Nome/Razão social
  yield takeLatest(
    (ac: AnyAction) => ac.type === actionTypes.BLUR && ac.meta.form === 'destinatario' && ac.meta.field === 'nomeRazaoSocial',
    function* (ac: AnyAction) {
      yield call(alteraNomeFantasia, ac.payload);
    }
  );
}

export function* alteraMunicipio(opcaoCodPais: { value: number; label: string }) {
  if (opcaoCodPais && opcaoCodPais.value !== 1058) {
    yield put(autofill('destinatario', 'endereco.municipioCodIbge', MUNICIPIO_EXTERIOR));
    yield put(autofill('destinatario', 'endereco.uf', UF_EXTERIOR));
  }
}

export function* alteraNomeFantasia(value: string) {
  const nomeFantasia = yield select(destinatarioSelector, 'nomeFantasia');

  if (nomeFantasia === '') yield put(autofill('destinatario', 'nomeFantasia', value));
}
