import Bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugsnagClient = Bugsnag.start({
  apiKey: '48212dd4bb0902f64975bc7f7ff90210',
  enabledReleaseStages: ['staging', 'production'],
  releaseStage: detectReleaseStage(),
  plugins: [new BugsnagPluginReact()],
  logger: null,
});

function detectReleaseStage() {
  const { host } = window.location || {};

  switch (host) {
    case 'emissor.consyste.com.br':
      return 'production';
    case 'emissor-hml.consyste.com.br':
      return 'staging';
    default:
      return 'development';
  }
}

export default bugsnagClient;
