import { ParsedUrlQueryInput } from 'querystring';

import { Dispatch, State } from '../../../../../types';
import { PaginatedContaWithNfe } from '../../../../../types/api';
import * as alertas from '../../../../gerais/alertas';
import * as contas from '../../../../transacionais/contas';
import api from '../../../../../api';

import * as filtros from './filtros';

export const CARREGANDO = 'telas/ferramentas/configuracoes/contas/lista/CARREGANDO';
export const CARREGADA = 'telas/ferramentas/configuracoes/contas/lista/CARREGADA';

const INITIAL_STATE = {
  carregando: false as boolean,
  resultado: {
    total: 0,
    page: 0,
    pageSize: 25,
    items: [],
    nfesProducao: {},
    nfesHomologacao: {},
  } as PaginatedContaWithNfe,
} as const;

type Actions = ReturnType<typeof listaCarregando | typeof listaCarregada>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case CARREGANDO:
      return { ...state, carregando: true };
    case CARREGADA:
      return { ...state, carregando: false, resultado: action.resultado };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.ferramentas.configuracoes.contas.lista;

// ACTION CREATORS

export function listaCarregando() {
  return { type: CARREGANDO } as const;
}

export function listaCarregada(resultado: PaginatedContaWithNfe) {
  return { type: CARREGADA, resultado } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query: ParsedUrlQueryInput) {
  return async function (dispatch: Dispatch) {
    try {
      await Promise.all([dispatch(contas.carregaContas()), dispatch(listaCarregando())]);

      await dispatch(filtros.atualizaFiltros(query));

      const r = await api.contas.contasComQuantidadeNfe(query);

      await dispatch(listaCarregada(r));

      return r;
    } catch (e) {
      await dispatch(alertas.erro(e));
      throw e;
    }
  };
}
