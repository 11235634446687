import { createSelector } from 'reselect';

import { State } from '../../../../types';
import { ProximaNumeracao } from '../../../../types/apiNfe';
import { numberIndex } from '../../../../util/formatacao';

export const CARREGA_DADOS = 'telas/nfe/emissao/nota/CARREGA_DADOS';
export const MUDA_DOCUMENTO_ATIVO = 'telas/nfe/emissao/nota/MUDA_DOCUMENTO_ATIVO';
export const ALTERA_PROXIMA_NUMERACAO = 'telas/nfe/emissao/nota/ALTERA_PROXIMA_NUMERACAO';
export const TELA_CARREGANDO = 'telas/nfe/emissao/nota/TELA_CARREGANDO';
export const TELA_CARREGADA = 'telas/nfe/emissao/nota/TELA_CARREGADA';

const INITIAL_STATE = {
  documentoAtivo: null as number | null,
  proximaNumeracao: {} as ProximaNumeracao,
  carregandoTela: false as boolean,
} as const;

type Actions = ReturnType<typeof mudaDocumentoAtivo | typeof alteraProximaNumeracao | typeof carregaTela | typeof finalizaCarregamentoTela>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case MUDA_DOCUMENTO_ATIVO:
      return { ...state, documentoAtivo: action.index };
    case ALTERA_PROXIMA_NUMERACAO:
      return { ...state, proximaNumeracao: action.dados };
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.nota;
export const documentoAtivoSelector = createSelector(rootSelector, (s) => s.documentoAtivo);
export const proximaNumeracaoSelector = createSelector(rootSelector, (s) => s.proximaNumeracao);
export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

// ACTION CREATORS

export function mudaDocumentoAtivo(index: number | string | null) {
  return { type: MUDA_DOCUMENTO_ATIVO, index: numberIndex(index) } as const;
}

export function alteraProximaNumeracao(dados: ProximaNumeracao) {
  return { type: ALTERA_PROXIMA_NUMERACAO, dados } as const;
}

export function carregaDados() {
  return { type: CARREGA_DADOS } as const;
}

export function carregaTela() {
  return { type: TELA_CARREGANDO } as const;
}

export function finalizaCarregamentoTela() {
  return { type: TELA_CARREGADA } as const;
}
