import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function PaginaNaoEncontrada() {
  return (
    <section>
      <div className="container">
        <ConteudoPaginaNaoEncontrada />
      </div>
    </section>
  );
}

export function PaginaNaoEncontradaAjuda() {
  return (
    <section>
      <ConteudoPaginaNaoEncontrada />
    </section>
  );
}

function ConteudoPaginaNaoEncontrada() {
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h4 className="erro">
        <p>
          <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} size="3x" />
        </p>
        Página não encontrada
      </h4>
      <div className="dadosEmitente">
        <p>O conteúdo que você tentou acessar não está disponível no endereço que solicitou. Experimente utilizar as opções de navegação acima.</p>
        <p>Caso acredite que encontrou um erro, contate o suporte, através dos canas abaixo.</p>
      </div>
    </div>
  );
}
