import { combineReducers, Reducer } from 'redux';
import { createSelector } from 'reselect';

import { indexBy } from '../../util/listas';
import aliquotasInterestaduais from '../../constantes/aliquotasInterestaduais.json';
import ambientes from '../../constantes/ambientes.json';
import bandeirasOperadoras from '../../constantes/bandeirasOperadoras.json';
import cfops from '../../constantes/cfops/cfops.json';
import cfopsA from '../../constantes/cfops/cfopsA.json';
import cfopsB from '../../constantes/cfops/cfopsB.json';
import cfopsC from '../../constantes/cfops/cfopsC.json';
import cfopsD from '../../constantes/cfops/cfopsD.json';
import cfopsE from '../../constantes/cfops/cfopsE.json';
import cfopsF from '../../constantes/cfops/cfopsF.json';
import cfopsG from '../../constantes/cfops/cfopsG.json';
import cfopsH from '../../constantes/cfops/cfopsH.json';
import cfopsI from '../../constantes/cfops/cfopsI.json';
import cfopsJ from '../../constantes/cfops/cfopsJ.json';
import cfopsK from '../../constantes/cfops/cfopsK.json';
import cfopsL from '../../constantes/cfops/cfopsL.json';
import cfopsM from '../../constantes/cfops/cfopsM.json';
import cfopsN from '../../constantes/cfops/cfopsN.json';
import cfopsO from '../../constantes/cfops/cfopsO.json';
import cfopsP from '../../constantes/cfops/cfopsP.json';
import cfopsQ from '../../constantes/cfops/cfopsQ.json';
import cfopsR from '../../constantes/cfops/cfopsR.json';
import cfopsS from '../../constantes/cfops/cfopsS.json';
import cfopsT from '../../constantes/cfops/cfopsT.json';
import codigoEnquadramento from '../../constantes/codigoEnquadramento.json';
import codigoEnquadramentoA from '../../constantes/codigoEnquadramentoA.json';
import codigoEnquadramentoB from '../../constantes/codigoEnquadramentoB.json';
import codigoEnquadramentoC from '../../constantes/codigoEnquadramentoC.json';
import codigoEnquadramentoD from '../../constantes/codigoEnquadramentoD.json';
import codigoSeloControle from '../../constantes/codigoSeloControle.json';
import consumidorFinal from '../../constantes/consumidorFinal.json';
import csts from '../../constantes/csts.json';
import destinoOperacao from '../../constantes/destinoOperacao.json';
import exigibilidadeIss from '../../constantes/exigibilidadeIss.json';
import finalidadeEmissao from '../../constantes/finalidadeEmissao.json';
import formasImportacao from '../../constantes/formasImportacao.json';
import formasPagamento from '../../constantes/formasPagamento.json';
import formatoImpressaoDanfe from '../../constantes/formatoImpressaoDanfe.json';
import indicadorEscalaRelevante from '../../constantes/indicadorEscalaRelevante.json';
import meiosPagamento from '../../constantes/meiosPagamento.json';
import modalidadeDeterminacaoIcms from '../../constantes/modalidadeDeterminacaoIcms.json';
import modalidadeDeterminacaoIcmsSt from '../../constantes/modalidadeDeterminacaoIcmsSt.json';
import modalidadesFrete from '../../constantes/modalidadesFrete.json';
import modelosCupomFiscal from '../../constantes/modelosCupomFiscal.json';
import modelosNotaFiscal from '../../constantes/modelosNotaFiscal.json';
import modelosNotaFiscalProdutor from '../../constantes/modelosNotaFiscalProdutor.json';
import motivoDesoneracaoIcms from '../../constantes/motivoDesoneracaoIcms.json';
import motivoDesoneracaoIcmsA from '../../constantes/motivoDesoneracaoIcmsA.json';
import motivoDesoneracaoIcmsB from '../../constantes/motivoDesoneracaoIcmsB.json';
import opcoesIncentivoFiscal from '../../constantes/opcoesIncentivoFiscal.json';
import opcoesValorCompoeValorTotal from '../../constantes/opcoesValorCompoeValorTotal.json';
import origens from '../../constantes/origens.json';
import percentuaisProvisorios from '../../constantes/percentuaisProvisorios.json';
import presencaComprador from '../../constantes/presencaComprador.json';
import intermediadorMarketplace from '../../constantes/intermediadorMarketplace.json';
import regimes from '../../constantes/regimes.json';
import regimesEmitentes from '../../constantes/regimesEmitentes.json';
import regimesEspeciaisDeTributacao from '../../constantes/regimesEspeciaisDeTributacao.json';
import servicos from '../../constantes/servicos.json';
import situacoesTributariasIpi from '../../constantes/situacoesTributariasIpi.json';
import situacoesTributariasPisCofins from '../../constantes/situacoesTributariasPisCofins.json';
import situacoesTributariasSimplesNacional from '../../constantes/situacoesTributariasSimplesNacional.json';
import situacoesTributariasTributacaoNormal from '../../constantes/situacoesTributariasTributacaoNormal.json';
import tipoContribuinte from '../../constantes/tipoContribuinte.json';
import tipoDocumento from '../../constantes/tipoDocumento.json';
import tipoDocumentoComIdEstrangeiro from '../../constantes/tipoDocumentoComIdEstrangeiro.json';
import tipoEmissao from '../../constantes/tipoEmissao.json';
import tipoOperacao from '../../constantes/tipoOperacao.json';
import tiposDocumentoFiscalReferenciado from '../../constantes/tiposDocumentoFiscalReferenciado.json';
import tiposIntegracaoPagamento from '../../constantes/tiposIntegracaoPagamento.json';
import tiposProdutoEspecifico from '../../constantes/tiposProdutoEspecifico.json';
import viasTransporteInternacional from '../../constantes/viasTransporteInternacional.json';
import tipoCalculo from '../../constantes/tipoCalculo.json';
import { createRootSelector } from '../../types';

const reducers = {
  aliquotasInterestaduais: constReducer(aliquotasInterestaduais),
  ambientes: constReducer(ambientes),
  bandeirasOperadoras: constReducer(bandeirasOperadoras),
  cfops: constReducer(cfops),
  cfopsA: constReducer(cfopsA),
  cfopsB: constReducer(cfopsB),
  cfopsC: constReducer(cfopsC),
  cfopsD: constReducer(cfopsD),
  cfopsE: constReducer(cfopsE),
  cfopsF: constReducer(cfopsF),
  cfopsG: constReducer(cfopsG),
  cfopsH: constReducer(cfopsH),
  cfopsI: constReducer(cfopsI),
  cfopsJ: constReducer(cfopsJ),
  cfopsK: constReducer(cfopsK),
  cfopsL: constReducer(cfopsL),
  cfopsM: constReducer(cfopsM),
  cfopsN: constReducer(cfopsN),
  cfopsO: constReducer(cfopsO),
  cfopsP: constReducer(cfopsP),
  cfopsQ: constReducer(cfopsQ),
  cfopsR: constReducer(cfopsR),
  cfopsS: constReducer(cfopsS),
  cfopsT: constReducer(cfopsT),
  codigoEnquadramento: constReducer(codigoEnquadramento),
  codigoEnquadramentoA: constReducer(codigoEnquadramentoA),
  codigoEnquadramentoB: constReducer(codigoEnquadramentoB),
  codigoEnquadramentoC: constReducer(codigoEnquadramentoC),
  codigoEnquadramentoD: constReducer(codigoEnquadramentoD),
  codigoSeloControle: constReducer(codigoSeloControle),
  consumidorFinal: constReducer(consumidorFinal),
  csts: constReducer(csts),
  destinoOperacao: constReducer(destinoOperacao),
  exigibilidadeIss: constReducer(exigibilidadeIss),
  finalidadeEmissao: constReducer(finalidadeEmissao),
  formasImportacao: constReducer(formasImportacao),
  formasPagamento: constReducer(formasPagamento),
  formatoImpressaoDanfe: constReducer(formatoImpressaoDanfe),
  indicadorEscalaRelevante: constReducer(indicadorEscalaRelevante),
  meiosPagamento: constReducer(meiosPagamento),
  modalidadeDeterminacaoIcms: constReducer(modalidadeDeterminacaoIcms),
  modalidadeDeterminacaoIcmsSt: constReducer(modalidadeDeterminacaoIcmsSt),
  modalidadesFrete: constReducer(modalidadesFrete),
  modelosCupomFiscal: constReducer(modelosCupomFiscal),
  modelosNotaFiscal: constReducer(modelosNotaFiscal),
  modelosNotaFiscalProdutor: constReducer(modelosNotaFiscalProdutor),
  motivoDesoneracaoIcms: constReducer(motivoDesoneracaoIcms),
  motivoDesoneracaoIcmsA: constReducer(motivoDesoneracaoIcmsA),
  motivoDesoneracaoIcmsB: constReducer(motivoDesoneracaoIcmsB),
  opcoesIncentivoFiscal: constReducer(opcoesIncentivoFiscal),
  opcoesValorCompoeValorTotal: constReducer(opcoesValorCompoeValorTotal),
  origens: constReducer(origens),
  percentuaisProvisorios: constReducer(percentuaisProvisorios),
  presencaComprador: constReducer(presencaComprador),
  intermediadorMarketplace: constReducer(intermediadorMarketplace),
  regimes: constReducer(regimes),
  regimesEmitentes: constReducer(regimesEmitentes),
  regimesEspeciaisDeTributacao: constReducer(regimesEspeciaisDeTributacao),
  servicos: constReducer(servicos),
  situacoesTributariasIpi: constReducer(situacoesTributariasIpi),
  situacoesTributariasPisCofins: constReducer(situacoesTributariasPisCofins),
  situacoesTributariasSimplesNacional: constReducer(situacoesTributariasSimplesNacional),
  situacoesTributariasTributacaoNormal: constReducer(situacoesTributariasTributacaoNormal),
  tipoContribuinte: constReducer(tipoContribuinte),
  tipoDocumento: constReducer(tipoDocumento),
  tipoDocumentoComIdEstrangeiro: constReducer(tipoDocumentoComIdEstrangeiro),
  tipoEmissao: constReducer(tipoEmissao),
  tipoOperacao: constReducer(tipoOperacao),
  tiposDocumentoFiscalReferenciado: constReducer(tiposDocumentoFiscalReferenciado),
  tiposIntegracaoPagamento: constReducer(tiposIntegracaoPagamento),
  tiposProdutoEspecifico: constReducer(tiposProdutoEspecifico),
  viasTransporteInternacional: constReducer(viasTransporteInternacional),
  tipoCalulo: constReducer(tipoCalculo),
} as const;

export default combineReducers(reducers);

export type ConstanteSistema = keyof typeof reducers;

export const rootSelector = createRootSelector((state) => state.constantes);

export const createConstanteSelector = (nomeConstante: ConstanteSistema) => createSelector(rootSelector, (root) => root[nomeConstante]);

/**
 * Cria um reducer padrão para uma lista de valores constantes.
 */
function constReducer<T extends { codigo: string | number }>(values: T[]): Reducer<Record<string, T>> {
  const initialState = indexBy<T, string>(values, 'codigo');
  return () => initialState;
}
