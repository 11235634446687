import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'react-router-redux';

import crashMiddleware from './util/crash';
import rootReducer from './reducers';
import sagas from './sagas';

export const history = createBrowserHistory();

export function newStore() {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(crashMiddleware, thunk, sagaMiddleware, routerMiddleware(history))));

  sagaMiddleware.run(sagas);

  return store;
}

export type RootState = ReturnType<typeof rootReducer>;

const store = newStore();

export default store;
