import { call, put, takeLatest } from 'redux-saga/effects';
import { all, putResolve, select } from 'typed-redux-saga';
import { autofill, formValueSelector } from 'redux-form';
import { ARRAY_PUSH, CHANGE } from 'redux-form/lib/actionTypes';
import { Action, AnyAction } from 'redux';

import * as ufs from '../../reducers/transacionais/ufs';
import * as municipios from '../../reducers/transacionais/municipios';
import { carregaTela, finalizaCarregamentoTela, mudaDocumentoAtivo, proximaNumeracaoSelector } from '../../reducers/telas/nfe/emissao/nota';
import { DocumentoReferenciado } from '../../components/nfe/emissao/2-nota/tipos';

const PREFIXO_DOC_REFERENCIADOS = 'documentosReferenciados';
const AMBIENTE = 'ambiente';

const AMBIENTE_DESCR = { '1': 'producao', '2': 'homologacao' };

const nfeSelector = formValueSelector('nfe');

export default function* sagaNotaEmissao() {
  yield call(carregaDados);

  // cada vez que um novo doc. referenciado for adicionado, tornamos ele o documento ativo
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_DOC_REFERENCIADOS,
    function* (_ac: Action) {
      const arr: DocumentoReferenciado[] = yield* select(nfeSelector, PREFIXO_DOC_REFERENCIADOS);
      const posicaoArray = arr.length - 1;

      yield put(mudaDocumentoAtivo(posicaoArray));
      yield put(autofill('nfe', `documentosReferenciados[${posicaoArray}].tipoDocumentoReferenciado`, 1));
    }
  );

  // cada vez que o campo ambiente for modificado, altera valor de número e série
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field.includes(AMBIENTE),
    function* (ac: AnyAction) {
      yield call(atualizaSerieENumero, ac.payload);
    }
  );
}

function* atualizaSerieENumero(ambiente: 1 | 2) {
  const proximaNumeracao = yield select(proximaNumeracaoSelector);
  const ambienteAtual = AMBIENTE_DESCR[ambiente];
  const proxNumAtual = proximaNumeracao[ambienteAtual];

  if (proxNumAtual) {
    yield put(autofill('nfe', 'serie', proxNumAtual.serie));
    yield put(autofill('nfe', 'numero', proxNumAtual.numero));
  }
}

function* carregaDados() {
  yield put(carregaTela());

  // ao abrir a aba, carregamos os municípios e UFs (o putResolve irá fazer um "await")
  yield* all([putResolve(municipios.carregaDados()), putResolve(ufs.carregaDados())]);

  yield put(finalizaCarregamentoTela());
}
