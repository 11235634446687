import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router';

/**
 * Este componente foi criado devido a um bug no react-router, que não consegue identificar a URL
 * como ativa quando o caminho possui parâmetros.
 */
export default function LinkContainerForPathParams({ to, ...props }: { to: string }) {
  const location = useLocation();

  const isActive = location.pathname !== '/' && location.pathname.startsWith(to);
  const config = to === '/' ? { ...props, to } : { ...props, to, active: isActive };

  return <LinkContainer {...config} />;
}
