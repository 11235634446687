import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import { createRootSelector, makeThunk } from '../../../../../../types';
import { Emitente } from '../../../../../../types/api';
import * as fmt from '../../../../../../util/formatacao';
import api from '../../../../../../api';
import { alertaModal, confirmaModal } from '../../../../../../util/dialogos';
import { formToJS } from '../../../../../../util/listas';

const DEFINE_ITEM_ATUAL = 'telas/ferramentas/configuracoes/emitentes/inutilizacoes/nova/DEFINE_ITEM_ATUAL';
const PERGUNTA_INUTILIZAR = 'Deseja realmente solicitar a inutilização dos números contidos no intervalo informado? Essa operação é irreversível.';

const OPCAO_VAZIA = { value: '', label: '' };

const INITIAL_STATE = {
  emitenteId: null as string | null,
  modelo: 55,
  serie: null,
  inicialNumero: null,
  finalNumero: null,
  justificativa: '',
  opcaoEmitente: OPCAO_VAZIA as Readonly<typeof OPCAO_VAZIA>,
} as const;

type Actions = ReturnType<typeof defineItemAtual>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_ITEM_ATUAL:
      return { ...state, emitenteId: action.emitenteId, opcaoEmitente: action.opcaoEmitente };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = createRootSelector((state) => state.telas.ferramentas.configuracoes.emitentes.inutilizacoes.nova);

export const opcaoEmitenteSelector = createSelector(dadosAtuaisSelector, (root) => root.opcaoEmitente);

// ACTION CREATORS

export function defineItemAtual(emitenteId: string, opcaoEmitente: { label: string; value: string }) {
  return { type: DEFINE_ITEM_ATUAL, emitenteId, opcaoEmitente } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega a ID da nfe na store transacional.
 */
export function carregaRegistro(emitenteId: string) {
  return makeThunk(async (dispatch) => {
    const emitente: Emitente = await api.emitentes.show(emitenteId);
    const opcaoEmitente = {
      value: emitenteId,
      label: `${fmt.cnpj(emitente.cnpj)} - ${emitente.nomeRazaoSocial || ''}`,
    };

    await dispatch(defineItemAtual(emitenteId, opcaoEmitente));
  });
}

export function salva(form: any) {
  const formInJS = formToJS(form);

  return makeThunk(async (dispatch) => {
    if (await confirmaModal(PERGUNTA_INUTILIZAR)) {
      const r = await api.inutilizacoes.create(formInJS);

      if (r.alertas && r.alertas.length !== 0) {
        await alertaModal(r.alertas.join('\n'));
      } else {
        await alertaModal('Inutilização solicitada.');
        await dispatch(push(`/app/ferramentas/configuracoes/emitentes/${formInJS.emitenteId}/inutilizacoes`));
      }
    } else {
      await alertaModal('Operação cancelada.');
    }
  });
}
