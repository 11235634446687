import { ValidacaoCadastro } from '../../../types/api';
import { confirmaModal } from '../../../util/dialogos';

const FRASE_ALERTAS = 'Não é possível salvar porque: \n';
const FRASE_CAMPOS_OBRIG = 'Verifique o preenchimento de todos os campos obrigatórios.\n Campos:\n';
const FRASE_CAMPOS_TAMANHO = 'Os tamanhos mínimos e máximos dos campos devem ser respeitados:\n Campos:\n';
const FRASE_CAMPOS_OBRIG_REC_PRODUTO =
  'Campos não preenchidos que podem ser exigidos pela SEFAZ na emissão da NF-e, dependendo da atividade econômica exercida pela empresa:\n';
const FRASE_CAMPOS_OBRIG_REC = 'Os seguintes campos são obrigatórios para a emissão de NF-e e não foram preenchidos:\n';
const FRASE_ALERTAS_CAMPOS_REL = 'Foram encontrados problemas no preenchimento dos campos abaixo:\n';

type Tipo = 'produto' | 'destinatario' | 'servico' | 'transportador';

export async function salvaRegistro(alertasValidacao: ValidacaoCadastro<any>, tipo: Tipo) {
  const alertasOpcionais = alertasValidacao.alertasCamposRelativos.length > 0 || alertasValidacao.camposObrigRecomendados.length > 0;

  let salva = !alertasOpcionais;
  if (alertasOpcionais) salva = await confirmaModal(`${obterMsgAlerta(alertasValidacao, tipo)} Deseja prosseguir mesmo assim?`);

  return salva;
}

export function alertaObrigatorio(alertasValidacao: ValidacaoCadastro<any>) {
  if (!alertasValidacao) return false;

  return alertasValidacao.alertas.length > 0 || alertasValidacao.camposObrigatorios.length > 0 || alertasValidacao.camposTamanhos.length > 0;
}

export function obterMsgAlerta(alertasValidacao: ValidacaoCadastro<any>, tipo: Tipo) {
  const msgAlertasObrig = alertaObrigatorio(alertasValidacao) ? FRASE_ALERTAS : '';

  return (
    msgAlertasObrig +
    msg(alertasValidacao.alertas, '\n', '', '') +
    msg(alertasValidacao.camposObrigatorios, ', ', FRASE_CAMPOS_OBRIG, '.') +
    msg(alertasValidacao.camposObrigRecomendados, ', ', tipo === 'produto' ? FRASE_CAMPOS_OBRIG_REC_PRODUTO : FRASE_CAMPOS_OBRIG_REC, '.') +
    msg(alertasValidacao.alertasCamposRelativos, '', FRASE_ALERTAS_CAMPOS_REL, '') +
    msg(alertasValidacao.camposTamanhos, ', ', FRASE_CAMPOS_TAMANHO, '.')
  );
}

function msg(alertas: readonly string[], separador: string, frase: string, pontuacao: string) {
  if (alertas.length > 0) {
    const msgs = alertas.join(separador);
    return `${frase} ${msgs}${pontuacao} \n\n`;
  }

  return '';
}
