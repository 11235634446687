import { push } from 'react-router-redux';
import { AnyAction } from 'redux';

import { createRootSelector, Dispatch, State } from '../../../../types';
import * as contasActions from '../../../transacionais/contas';
import api from '../../../../api';
import { alertaModal, confirmaModal } from '../../../../util/dialogos';
import { formToJS } from '../../../../util/listas';

const PERGUNTA_IMPORTAR = 'Confirma importação de todos os dados constantes no arquivo?';

const INITIAL_STATE = {
  contaId: null,
  arquivo: '',
};

export default function reducer(state = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = createRootSelector((state: State) => state.telas.ferramentas.importacoes.produto);

// THUNK ACTION CREATORS

export function carregaDados() {
  return async function (dispatch: Dispatch) {
    await dispatch(contasActions.carregaDados());
  };
}

export function salva(form: any) {
  const formInJS = formToJS(form);

  const formData = new FormData();
  formData.append('arquivo', formInJS.arquivo);
  formData.append('contaId', formInJS.contaId);

  return async function (dispatch: Dispatch) {
    if (await confirmaModal(PERGUNTA_IMPORTAR)) {
      const r = await api.produtos.importar(formData);

      if (r.alertas.length > 0) await alertaModal(r.alertas.join('\n'));
      else {
        await alertaModal(r.msgSucesso);
        dispatch(push(`/app/cadastro/produtos`)); // navega para a lista de produto
      }
    }
  };
}
