import { call, put, takeLatest } from 'redux-saga/effects';
import { autofill } from 'redux-form';
import { CHANGE } from 'redux-form/lib/actionTypes';
import { AnyAction } from 'redux';

export default function* sagaProduto() {
  // cada vez que o campo gtinPossui for alterado, preenche 'SEM GTIN' ou nulo em ean e eanTributavel
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'produto' && ac.meta.field === 'gtinPossui',
    function* (ac: AnyAction) {
      yield call(alteraGtin, !ac.payload ? 'SEM GTIN' : null);
    }
  );
}

export function* alteraGtin(valor: string | null | undefined) {
  yield put(autofill('produto', 'ean', valor));
  yield put(autofill('produto', 'eanUnidadeTributavel', valor));
}
