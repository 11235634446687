/**
 * @file Este arquivo contém funções utilitárias específicas relacionadas aos cadastros.
 */
import _ from 'lodash';

import { toNumberUsa } from './formatacao';

/**
 * Lista de campos de valor unitário que precisam ser modificados ao serem enviados ao backend.
 *
 * Os campos valor unitário são salvos como string no front end.
 * A string que o front end recebe do back end está no formato 'xx.xx'.
 *
 * Então, se o front end enviar uma string 'xx.xx' para o back end, ele retira o ponto e perde a precisão do número.
 * E, se enviar uma string'xx.xx' para o back end, ele substitui por vírgula.
 * Por isso, para não perder a precisão, o front deve enviar um número com vírgula nesse caso.
 */
const CAMPOS_VALOR_UNITARIO = { valorUnitarioComercial: 1, valorUnitarioTributavel: 1 };

type Translator<T> = { [k in keyof T]: MaybeTranslate<T, k> };
type MaybeTranslate<T, K extends keyof T> = K extends keyof typeof CAMPOS_VALOR_UNITARIO ? T[K] | string : T[K];

/**
 * Função para adicionar/editar campos do formulário de cadastro
 * que será envidado para o backend.
 */
export function ajustaCadastroParaBackend<T extends Record<string, any>>(cadastro: T) {
  return _.mapValues(cadastro, (v, k) => {
    // formatação de valores unitários para enviar para o backend
    if (typeof v === 'string' && k in CAMPOS_VALOR_UNITARIO) return toNumberUsa(v).replace('.', ',');
    return v;
  }) as Translator<T>;
}
