import _ from 'lodash';
import { autofill } from 'redux-form';

import { createRootSelector, makeThunk } from '../../../../types';
import * as nfeActions from '../../../transacionais/nfes';
import * as alertas from '../../../gerais/alertas';
import * as conta from '../../../transacionais/contas';
import * as emitente from '../../../transacionais/emitentes';
import api from '../../../../api';
import { ItensPaginados } from '../../../../types/api';
import { Nfe } from '../../../../types/apiNfe';

import * as opcoesParaFiltroEmitente from './opcoesParaFiltroEmitente';
import * as filtros from './filtros';
import { FiltroForm } from './filtros';
import { carregaOpcoesSelect } from './opcoesParaFiltroEmitente';

export const CARREGANDO = 'telas/nfe/consulta/lista/CARREGANDO';
export const CARREGADA = 'telas/nfe/consulta/lista/CARREGADA';

const INITIAL_STATE = {
  carregando: false as boolean,
  resultado: {
    total: 0 as number,
    page: 0 as number,
    pageSize: 25 as number,
    items: [] as readonly string[],
  },
} as const;

type Actions = ReturnType<typeof listaCarregando | typeof listaCarregada>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case CARREGANDO:
      return { ...state, carregando: true };
    case CARREGADA:
      return { ...state, carregando: false, resultado: { ...action.resultado, items: action.resultado.items.map((i) => i._id) } };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = createRootSelector((state) => state.telas.nfe.consulta.lista);

// ACTION CREATORS

export function listaCarregando() {
  return { type: CARREGANDO } as const;
}

export function listaCarregada(resultado: ItensPaginados<Nfe>) {
  return { type: CARREGADA, resultado } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query: FiltroForm) {
  return makeThunk(async (dispatch) => {
    const filtrosQuery = _.omit(query, 'page');

    // se filtros é vazio, preenche valor default de campo ambiente no formulário
    if (_.isEmpty(filtrosQuery)) {
      const r = await api.nfe.ambiente();
      if (r.ambiente) {
        dispatch(autofill('nfeFiltrar', 'ambiente', String(r.ambiente)));
      }
    }

    try {
      await Promise.all([
        dispatch(listaCarregando()),
        dispatch(conta.carregaContas()),
        dispatch(emitente.carregaDados()),
        dispatch(opcoesParaFiltroEmitente.carregaOpcoesSelect({})),
      ]);

      await dispatch(filtros.atualizaFiltros(query));
      await dispatch(carregaOpcoesSelect({ contaId: query.contaId }));

      const r = await dispatch(nfeActions.carregaLista(query));

      await dispatch(listaCarregada(r));

      return r;
    } catch (e) {
      await dispatch(alertas.erro(e));
      throw e;
    }
  });
}
