import { put, takeLatest } from 'redux-saga/effects';
import { autofill, formValueSelector } from 'redux-form';
import { CHANGE, ARRAY_PUSH } from 'redux-form/lib/actionTypes';
import { Action, AnyAction } from 'redux';
import { select } from 'typed-redux-saga';

import { PessoaAutorizada } from '../../components/nfe/emissao/3-destinatario/AutorizarObterXml/tipos';
import { mudaPessoaAtiva } from '../../reducers/telas/nfe/emissao/destinatario';

const PREFIXO_PESSOA_AUTORIZADA = 'pessoasAutorizadas';

const nfeSelector = formValueSelector('nfe');

export default function* sagaDestinatarioEmissao() {
  // cada vez que o campo retiradaLocalDiferente for marcado com falso, altera valor de retiradaLocal para nulo
  // isso porque campos do formulário sem edição na tela como 'uf' e 'nomeMunicipio' não são alterados para nulo
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field === 'retiradaLocalDiferente' && ac.payload === false,
    function* (_ac: AnyAction) {
      yield put(autofill('nfe', 'retiradaLocal', null));
    }
  );

  // cada vez que o campo entregaLocalDiferente for marcado com falso, altera valor de entregaLocal para nulo
  // isso porque campos do formulário sem edição na tela como 'uf' e 'nomeMunicipio' não são alterados para nulo
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field === 'entregaLocalDiferente' && ac.payload === false,
    function* (_ac: AnyAction) {
      yield put(autofill('nfe', 'entregaLocal', null));
    }
  );

  // cada vez que uma nova pessoa autorizada for adicionada, tornamos ela a pessoa ativa
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_PESSOA_AUTORIZADA,
    function* (_ac: Action) {
      const arr: PessoaAutorizada[] = yield* select(nfeSelector, PREFIXO_PESSOA_AUTORIZADA);
      const posicaoArray = arr.length - 1;

      yield put(mudaPessoaAtiva(posicaoArray));
      yield put(autofill('nfe', `pessoasAutorizadas[${posicaoArray}].tipoDocumento`, 1));
    }
  );
}
