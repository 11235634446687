import url from 'url';
import { ParsedUrlQueryInput } from 'querystring';

import { createSelector } from 'reselect';
import Cookies from 'universal-cookie';

import { Dispatch, State } from '../../../../types';
import * as alertas from '../../../gerais/alertas';
import * as contasActions from '../../../transacionais/contas';
import * as produtosActions from '../../../transacionais/produtos';
import { ItensPaginados, Produto } from '../../../../types/api';

const CARREGANDO = 'telas/cadastro/produtos/lista/CARREGANDO';
const CARREGADA = 'telas/cadastro/produtos/lista/CARREGADA';
const ALTERA_LAST_PATH = 'telas/cadastro/produtos/lista/ALTERA_LAST_PATH';

const cookie = new Cookies();
const PATH_CADASTRO_PRODUTOS = '/app/cadastro/produtos';

const INITIAL_STATE = {
  carregando: false as boolean,
  resultado: {
    total: 0 as number,
    page: 0 as number,
    pageSize: 25 as number,
    items: [] as readonly string[],
  },
  lastPathProdutos: String(cookie.get('lastPathProdutos') || PATH_CADASTRO_PRODUTOS),
} as const;

type Actions = ReturnType<typeof listaCarregando | typeof listaCarregada | typeof alteraLastPath>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case CARREGANDO:
      return { ...state, carregando: true };
    case CARREGADA:
      return { ...state, carregando: false, resultado: { ...action.resultado, items: action.resultado.items.map((r) => r._id || '') } };
    case ALTERA_LAST_PATH:
      return { ...state, lastPathProdutos: action.path };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.cadastro.produto.lista;
export const lastPathSelector = createSelector(rootSelector, (root) => root.lastPathProdutos || PATH_CADASTRO_PRODUTOS);

// ACTION CREATORS

export function listaCarregando() {
  return { type: CARREGANDO } as const;
}

export function listaCarregada(resultado: ItensPaginados<Produto>) {
  return { type: CARREGADA, resultado } as const;
}

function alteraLastPath(urlLastPath: string) {
  return { type: ALTERA_LAST_PATH, path: urlLastPath } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query?: ParsedUrlQueryInput) {
  return async function (dispatch: Dispatch) {
    try {
      const urlLastPath = url.format({
        pathname: PATH_CADASTRO_PRODUTOS,
        query,
      });

      await dispatch(alteraLastPath(urlLastPath));
      cookie.set('lastPathProdutos', urlLastPath);

      await dispatch(listaCarregando());
      await dispatch(contasActions.carregaDados());

      const r = await dispatch(produtosActions.carregaLista(query));

      await dispatch(listaCarregada(r));

      return r;
    } catch (e) {
      await dispatch(alertas.erro(e));
      throw e;
    }
  };
}
