import { autofill } from 'redux-form';
import { createSelector } from 'reselect';

import { Dispatch, State } from '../../../../types';
import { Destinatario, Itens } from '../../../../types/api';
import * as municipios from '../../../transacionais/municipios';
import * as ufs from '../../../transacionais/ufs';
import * as paises from '../../../transacionais/paises';
import api from '../../../../api';
import { indexBy } from '../../../../util/listas';
import { numberIndex } from '../../../../util/formatacao';

export const DADOS_SELECT_RECEBIDOS = 'telas/nfe/emissao/destinatario/DADOS_SELECT_RECEBIDOS';
export const TELA_CARREGANDO = 'telas/nfe/emissao/destinatario/TELA_CARREGANDO';
export const TELA_CARREGADA = 'telas/nfe/emissao/destinatario/TELA_CARREGADA';
export const MUDA_PESSOA_ATIVA = 'telas/nfe/emissao/destinatario/MUDA_PESSOA_ATIVA';

const INITIAL_STATE = {
  porId: {} as Readonly<Record<string, Destinatario>>,
  carregandoTela: false as boolean,
  pessoaAtiva: null as number | null,
} as const;

type Actions = ReturnType<typeof dadosSelectRecebidos | typeof mudaPessoaAtiva> | { type: typeof TELA_CARREGANDO } | { type: typeof TELA_CARREGADA };

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DADOS_SELECT_RECEBIDOS:
      // recebemos os dados como um array, e fazemos a indexação por ID
      return { ...state, porId: { ...state.porId, ...indexBy(action.dados.items, '_id') } };
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    case MUDA_PESSOA_ATIVA:
      return { ...state, pessoaAtiva: action.index };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.destinatario;

export const todosSelector = createSelector(rootSelector, (root) => Object.values(root.porId));

export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

export const pessoaAtivaSelector = createSelector(rootSelector, (s) => s.pessoaAtiva);

// ACTION CREATORS

export function dadosSelectRecebidos(dados: Itens<Destinatario>) {
  return { type: DADOS_SELECT_RECEBIDOS, dados } as const;
}

export function mudaPessoaAtiva(index: number | string | null) {
  return { type: MUDA_PESSOA_ATIVA, index: numberIndex(index) } as const;
}

// THUNK ACTION CREATORS

export function carregaDados() {
  return async function (dispatch: Dispatch) {
    await dispatch({ type: TELA_CARREGANDO });

    await Promise.all([dispatch(carregaOpcoesSelect()), dispatch(paises.carregaDados()), dispatch(ufs.carregaDados()), dispatch(municipios.carregaDados())]);

    await dispatch({ type: TELA_CARREGADA });
  };
}

export function carregaOpcoesSelect() {
  return async function (dispatch: Dispatch) {
    const destinatarios = await api.nfe.destinatarios();
    dispatch(dadosSelectRecebidos(destinatarios));

    return destinatarios;
  };
}

export function trocaDestinatario(opcao: { value: string; label: string } | null | undefined) {
  return async function (dispatch: Dispatch) {
    // para executar 'get' somente quando destinatarioId é definido
    if (!opcao || !opcao.value) return dispatch(autofill('nfe', 'destinatario', null));

    // obtém estrutura no formato de destinatário da NF-e do backend
    const destinatario = await api.nfe.dadosDestinatario(opcao.value);
    return dispatch(autofill('nfe', 'destinatario', destinatario));
  };
}
