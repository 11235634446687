import React from 'react';

import UsuarioLogado from './UsuarioLogado';

type BarraUsuarioProps = {
  usuarioAtual?: { autenticado: boolean; name?: string };
};

export default function BarraUsuario({ usuarioAtual }: BarraUsuarioProps) {
  if (!usuarioAtual?.autenticado) {
    return null;
  }

  return <UsuarioLogado usuarioAtual={usuarioAtual} />;
}
