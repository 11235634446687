import { autofill, formValueSelector } from 'redux-form';

import { Dispatch, GetState, State } from '../../../../types';
import { DadosLotesProdutoForm } from '../../../../types/forms';

import * as itens from './itens';

const ABRE_MODAL_LOTES_PRODUTO = 'telas/nfe/emissao/modalLotesProduto/ABRE_MODAL_LOTES_PRODUTO';
const FECHA_MODAL_LOTES_PRODUTO = 'telas/nfe/emissao/modalLotesProduto/FECHA_MODAL_LOTES_PRODUTO';

const INITIAL_STATE = {
  open: false as boolean,
} as const;

type Actions = ReturnType<typeof abreModal | typeof fechaModal>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case ABRE_MODAL_LOTES_PRODUTO:
      return { ...state, open: true };
    case FECHA_MODAL_LOTES_PRODUTO:
      return { ...state, open: false };
    default:
      return state;
  }
}

// SELECTORS

export const openModalSelector = (state: State): boolean => state.telas.nfe.emissao.modalLotesProduto.open || false;

// ACTION CREATORS

export function abreModal() {
  return { type: ABRE_MODAL_LOTES_PRODUTO } as const;
}

export function fechaModal() {
  return { type: FECHA_MODAL_LOTES_PRODUTO } as const;
}

const nfeSelector = formValueSelector('nfe');

export function abreModalLotesProduto() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const itemAtivo = itens.itemAtivoSelector(getState());

    if (typeof itemAtivo === 'number') {
      const lotesProduto = nfeSelector(getState(), `produtoServico[${itemAtivo}].lotesProduto`);
      if (lotesProduto) dispatch(autofill('nfe', `produtoServico[${itemAtivo}].dadosLotesProduto.lotesProduto`, lotesProduto));
    }

    dispatch(abreModal());
  };
}

export function fechaModalESalva() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const itemAtivo = itens.itemAtivoSelector(getState());

    if (typeof itemAtivo === 'number') {
      const dadosLotesProduto: DadosLotesProdutoForm = nfeSelector(getState(), `produtoServico[${itemAtivo}].dadosLotesProduto`);

      // Fecha o modal e depois atualiza o formulário com o valor de lotesProduto
      await dispatch(fechaModal());

      if (dadosLotesProduto) {
        dispatch(autofill('nfe', `produtoServico[${itemAtivo}].lotesProduto`, dadosLotesProduto.lotesProduto || null));
      }
    } else dispatch(fechaModal());
  };
}

export function fechaModalENaoSalva() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const itemAtivo = itens.itemAtivoSelector(getState());

    if (typeof itemAtivo === 'number') {
      const lotesProduto: DadosLotesProdutoForm = nfeSelector(getState(), `produtoServico[${itemAtivo}].lotesProduto`);

      // Fecha o modal e depois atualiza o formulário com o valor de lotesProduto
      // Porque, após fechar o modal, o valor de lotesProduto é atualizado com nulo pela ação CHANGE do reduxform
      await dispatch(fechaModal());

      dispatch(autofill('nfe', `produtoServico[${itemAtivo}].lotesProduto`, lotesProduto));
    } else dispatch(fechaModal());
  };
}
