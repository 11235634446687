import { combineReducers } from 'redux';

import lista from './lista';
import detalhes from './detalhes';
import cancelar from './cancelar';
import filtros from './filtros';
import cartaCorrecao from './cartaCorrecao';
import opcoesParaFiltroEmitente from './opcoesParaFiltroEmitente';
import modalComunicacao from './modalComunicacao';
import botaoComCliqueUnico from './botaoComCliqueUnico';

export default combineReducers({
  lista,
  detalhes,
  cancelar,
  filtros,
  cartaCorrecao,
  opcoesParaFiltroEmitente,
  modalComunicacao,
  botaoComCliqueUnico,
});
