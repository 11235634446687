import _ from 'lodash';
import { createSelector } from 'reselect';

import api from '../../api';
import { createRootSelector, makeThunk } from '../../types';
import { indexBy } from '../../util/listas';
import { Uf } from '../../types/api';

export const DADOS_RECEBIDOS = 'transacionais/ufs/DADOS_RECEBIDOS';

const INITIAL_STATE = {
  todas: [] as readonly Uf[],
  porId: {} as { readonly [k: string]: Uf },
  porCodigo: {} as { readonly [k: string]: Uf },
} as const;

type UfActions = ReturnType<typeof dadosRecebidos>;

export default function reducer(state = INITIAL_STATE, action?: UfActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case DADOS_RECEBIDOS:
      return {
        todas: action.dados.ufs,
        porId: indexBy(action.dados.ufs, '_id'),
        porCodigo: indexBy(action.dados.ufs, 'codigo'),
      };
    default:
      return state;
  }
}

// SELECTORS

export const ufsSelector = createRootSelector((state) => state.transacionais.ufs);

export const todasUfsSelector = createSelector(ufsSelector, (ufs) => ufs.todas);

export const ufPorCodigoSelector = createSelector(ufsSelector, (ufs) => ufs.porCodigo);

export const ufPorIdSelector = createSelector(ufsSelector, (ufs) => ufs.porId);

export const ufsParaSelect = createSelector(todasUfsSelector, (ufs) =>
  _(ufs)
    .sortBy('sigla')
    .map((c) => ({ value: c.sigla, label: `${c.sigla}` }))
    .value()
);

export const ufsCodParaSelect = createSelector(todasUfsSelector, (ufs) =>
  _(ufs)
    .sortBy('sigla')
    .map(({ codigo, sigla }) => ({ value: Number(codigo), label: `${sigla}` }))
    .value()
);

// ACTION CREATORS

export function dadosRecebidos(dados: { ufs: readonly Uf[] }) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaDados() {
  return makeThunk(async function (dispatch) {
    const r = await api.ufs.index();
    dispatch(dadosRecebidos(r));
  });
}
