import { createSelector } from 'reselect';

import { State } from '../../../../types';
import { ValoresTotais } from '../../../../util/valorTotal';

export const DADOS_CALCULADOS = 'telas/nfe/emissao/totais/DADOS_CALCULADOS';
export const CARREGA_DADOS = 'telas/nfe/emissao/totais/CARREGA_DADOS';
export const TELA_CARREGANDO = 'telas/nfe/emissao/totais/TELA_CARREGANDO';
export const TELA_CARREGADA = 'telas/nfe/emissao/totais/TELA_CARREGADA';

const INITIAL_STATE: ValoresTotais & { carregandoTela: boolean } = {
  carregandoTela: false,
} as const;

type Actions = ReturnType<typeof dadosCalculados | typeof carregaTela | typeof finalizaCarregamentoTela>;

export default function reducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case DADOS_CALCULADOS:
      return { ...state, ...action.dados };
    case TELA_CARREGANDO:
      return { ...state, carregandoTela: true };
    case TELA_CARREGADA:
      return { ...state, carregandoTela: false };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.totais;

export const carregandoTelaSelector = createSelector(rootSelector, (root) => root.carregandoTela);

// ACTION CREATORS

export function dadosCalculados(dados: ValoresTotais) {
  return { type: DADOS_CALCULADOS, dados } as const;
}

export function carregaDados() {
  return { type: CARREGA_DADOS } as const;
}

export function carregaTela() {
  return { type: TELA_CARREGANDO } as const;
}

export function finalizaCarregamentoTela() {
  return { type: TELA_CARREGADA } as const;
}
