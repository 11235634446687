import React from 'react';

import BarraUsuario from './BarraUsuario';
import Menu from './Menu';

export default function DialogoUsuario() {
  return (
    <section className="bgDialogoInterno nav-topo">
      <div className="container">
        <div className="pull-left">
          <Menu />
        </div>

        <div className="pull-right">
          <BarraUsuario />
        </div>
      </div>
    </section>
  );
}
