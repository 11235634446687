import { LOCATION_CHANGE, LocationChangeAction } from 'react-router-redux';
import { Location } from 'history';

import { createRootSelector } from '../types';

const INITIAL_STATE = {
  locationBeforeTransitions: null as Location | null,
} as const;

type Actions = LocationChangeAction;

export default (state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE => {
  if (action.type === LOCATION_CHANGE) {
    return { ...state, locationBeforeTransitions: action.payload };
  }

  return state;
};

// SELECTORS

export const dadosAtuaisSelector = createRootSelector((state) => state.routing.locationBeforeTransitions);
