import { put, take, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { AnyAction } from 'redux';

import { ABRE_ALERTA_VALIDAR } from '../../reducers/telas/nfe/emissao/upload';
import { FECHA_MODAL } from '../../reducers/telas/nfe/emissao/modalAlerta';

export default function* sagaUploadEmissao() {
  // quando existe alerta de 'validar e salvar', aguarda o modal de alertas ser fechado
  // para encaminhar o usuário para outra tela
  yield takeLatest(
    (ac: AnyAction) => ac.type === ABRE_ALERTA_VALIDAR,
    function* (ac: { type: string; nfeId: string }) {
      // somente após o modal ser fechado, encaminha o usuário para tela de informações adicionais
      yield take(FECHA_MODAL);
      yield put(push(`${ac.nfeId}/finalizar`));
    }
  );
}
