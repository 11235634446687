/* eslint-disable no-plusplus */

import { put, take, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import { autofill, formValueSelector } from 'redux-form';
import { ARRAY_PUSH, ARRAY_REMOVE, CHANGE } from 'redux-form/lib/actionTypes';
import { Action, AnyAction } from 'redux';

import { carregaTela, finalizaCarregamentoTela, mudaParcelaAtiva } from '../../reducers/telas/nfe/emissao/cobranca';
import { ABRE_NOTA } from '../../reducers/telas/nfe/emissao/item';
import { Duplicata } from '../../types/apiNfe';

import { converteDecimal2 } from './itens';

const PREFIXO_DUPLICATAS = 'duplicatas';
const TAMANHO_NUMERO_DUPLICATA = 3;

const nfeSelector = formValueSelector('nfe');

export default function* sagaDuplicatasEmissao() {
  yield* call(carregaDados);

  // quando o campo cobrança for alterado, altera valor original
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field === 'cobranca',
    function* (ac: AnyAction) {
      yield* call(alteraValorOriginal, ac.payload);
    }
  );

  // cada vez que um novo volume for adicionado, tornamos ele o volume ativo
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_DUPLICATAS,
    function* (_ac: Action) {
      const arr = yield* obtemDuplicatas();
      yield put(mudaParcelaAtiva(arr.length - 1));
    }
  );

  // cada vez que uma nova duplicata for adicionada, carrega o número da duplicata adicionada
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_DUPLICATAS,
    function* (_ac: Action) {
      yield* call(carregaNumeroUltimaDuplicata);
    }
  );

  // cada vez que uma duplicata é removida, se existe elemento na posição anterior,
  // atualiza o número da duplicata que ocupou sua posição e das posteriores
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_REMOVE && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_DUPLICATAS,
    function* (ac: AnyAction) {
      yield* call(atualizaNumeroDuplicatasAposRemocao, ac.meta.index);
    }
  );
}

function* carregaDados() {
  yield put(carregaTela());

  // o formulário de cobrança é atualizado após abrir nota
  // aguarda até abrir a nota para finalizar carregamento da janela
  yield take(ABRE_NOTA);

  yield put(finalizaCarregamentoTela());
}

export function* alteraValorOriginal(cobrancaAtiva: boolean | null | undefined) {
  if (!cobrancaAtiva) {
    yield put(autofill('nfe', 'faturaValorOriginal', null));
    return;
  }

  const faturaValorOriginalAtual = yield* select(nfeSelector, 'faturaValorOriginal');

  if (!faturaValorOriginalAtual) {
    const valorTotalNfe = yield* select(nfeSelector, 'valorTotalNfe');
    yield put(autofill('nfe', 'faturaValorOriginal', converteDecimal2(valorTotalNfe) || 0));
  }
}

export function* carregaNumeroUltimaDuplicata() {
  const duplicatas = yield* obtemDuplicatas();

  if (!duplicatas || duplicatas.length === 0) return;

  const posicaoUltimaDuplicata = duplicatas.length - 1;

  // se for a primeira duplicata, carrega o numero 001 default
  if (duplicatas.length === 1) {
    yield put(autofill('nfe', `duplicatas[${posicaoUltimaDuplicata}].numero`, '001'));
    return;
  }

  const numeroDuplicataAnterior = yield* select(nfeSelector, `duplicatas[${posicaoUltimaDuplicata - 1}].numero`);

  // formata o número da duplicata anterior
  const numeroDuplicataAnteriorFormatado = Number(formataNumeroDuplicata(numeroDuplicataAnterior));

  // verifica se não é número
  if (!Number.isInteger(numeroDuplicataAnteriorFormatado)) return;

  // soma em um o número da duplicata anterior formatado
  yield put(autofill('nfe', `duplicatas[${posicaoUltimaDuplicata}].numero`, somaNumeroDuplicata(numeroDuplicataAnteriorFormatado)));
}

export function* atualizaNumeroDuplicatasAposRemocao(posicaoDuplicataRemovida: number) {
  const duplicatas = yield* obtemDuplicatas();

  if (!duplicatas || duplicatas.length === 0 || posicaoDuplicataRemovida === 0) return;

  const numeroDuplicataAnterior = yield* select(nfeSelector, `duplicatas[${posicaoDuplicataRemovida - 1}].numero`);

  let numeroDuplicataAnteriorFormatado = Number(formataNumeroDuplicata(numeroDuplicataAnterior));

  if (!Number.isInteger(numeroDuplicataAnteriorFormatado)) return;

  for (let posicaoDuplicata = posicaoDuplicataRemovida; posicaoDuplicata < duplicatas.length; posicaoDuplicata++) {
    yield put(autofill('nfe', `duplicatas[${posicaoDuplicata}].numero`, somaNumeroDuplicata(numeroDuplicataAnteriorFormatado)));

    numeroDuplicataAnteriorFormatado++;
  }
}

export function somaNumeroDuplicata(numeroDuplicata: number) {
  if (!Number.isInteger(numeroDuplicata)) return undefined;

  return incluiZerosAEsquerdaDoNumero(String(numeroDuplicata + 1));
}

function incluiZerosAEsquerdaDoNumero(numero: string) {
  const zerosAEsquerdaDoNumero = '0'.repeat(TAMANHO_NUMERO_DUPLICATA - numero.length);

  return `${zerosAEsquerdaDoNumero}${numero}`;
}

export function formataNumeroDuplicata(numeroDuplicata: string) {
  if (!numeroDuplicata) {
    return undefined;
  }

  // troca caracteres diferentes de número por string vazia
  const numeroDuplicataFormatado = numeroDuplicata.replace(/\D/g, '');

  if (numeroDuplicataFormatado.length === 0 || numeroDuplicataFormatado.length > 3) {
    return undefined;
  }

  return numeroDuplicataFormatado;
}

function* obtemDuplicatas() {
  const duplicatas: Duplicata[] = yield* select(nfeSelector, PREFIXO_DUPLICATAS);

  return duplicatas;
}
