import { State } from '../../../../types';

const ABRE_MODAL_COMUNICACAO = 'telas/nfe/consulta/modalComunicacao/ABRE_MODAL_COMUNICACAO';
const FECHA_MODAL_COMUNICACAO = 'telas/nfe/consulta/modalComunicacao/FECHA_MODAL_COMUNICACAO';

const DEFAULT_STATE = {
  open: false as boolean,
} as const;

type Actions = ReturnType<typeof abreModal | typeof fechaModal>;

export default function reducer(state = DEFAULT_STATE, action: Actions): typeof DEFAULT_STATE {
  switch (action.type) {
    case ABRE_MODAL_COMUNICACAO:
      return { ...state, open: true };
    case FECHA_MODAL_COMUNICACAO:
      return { ...state, open: false };
    default:
      return state;
  }
}

// SELECTORS

export const openModalSelector = (state: State): boolean => state.telas.nfe.consulta.modalComunicacao.open;

// ACTION CREATORS

export function abreModal() {
  return { type: ABRE_MODAL_COMUNICACAO } as const;
}

export function fechaModal() {
  return { type: FECHA_MODAL_COMUNICACAO } as const;
}
