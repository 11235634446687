import React from 'react';
import { useLocation } from 'react-router';

type ScrollToTopProps = {
  children: React.ReactNode;
};

export default function ScrollToTop({ children }: ScrollToTopProps) {
  const { pathname } = useLocation();

  React.useEffect(() => {
    const paginaEmissaoManual = pathname && pathname.includes('emissao') && !pathname.endsWith('/emissao') && !pathname.endsWith('/emissao/upload');

    const paginaAbaDetalhesEmitente = pathname && pathname.includes('ferramentas/configuracoes/emitentes/') && !pathname.endsWith('/inutilizacoes/nova');

    if (paginaEmissaoManual) scrollIntoView('navegacao');

    if (!paginaEmissaoManual && !paginaAbaDetalhesEmitente) window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
}

// https://stackoverflow.com/questions/4801655/how-to-go-to-a-specific-element-on-page
export function scrollIntoView(elementId: string) {
  const e = document.getElementById(elementId);
  if (e) e.scrollIntoView();
}
