import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WithTooltip from '../common/WithTooltip';

export default function StatusBackend() {
  const { resultado, erro } = useSelector((s) => s.gerais.ping);

  let icone;
  let tooltip;

  if (erro) [icone, tooltip] = ['text-danger', erro.mensagem];
  else if (resultado) [icone, tooltip] = ['text-success', 'Backend OK'];
  else [icone, tooltip] = ['text-warning', 'Aguardando…'];

  return (
    <WithTooltip tooltip={tooltip} placement="right">
      <FontAwesomeIcon icon={['fas', 'circle']} size="sm" className={icone} />
    </WithTooltip>
  );
}
