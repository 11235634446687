/* eslint-disable no-plusplus */

import React, { ReactNode, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type WithTooltipProps = {
  tooltip: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children?: ReactNode;
};

let n = 0;
export default function WithTooltip({ tooltip, placement, children }: WithTooltipProps) {
  const id = useMemo(() => `tooltip-${n++}`, []);
  const tt = <Tooltip id={id}>{tooltip}</Tooltip>;

  return (
    <OverlayTrigger overlay={tt} placement={placement}>
      {children}
    </OverlayTrigger>
  );
}
