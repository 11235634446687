import { ParsedUrlQueryInput } from 'querystring';

import * as transportadoresActions from '../../../transacionais/transportadores';
import * as contasActions from '../../../transacionais/contas';
import * as alertas from '../../../gerais/alertas';
import { makeThunk, State } from '../../../../types';
import { ItensPaginados, Transportador } from '../../../../types/api';

const CARREGANDO = 'telas/cadastro/transportadores/lista/CARREGANDO';
const CARREGADA = 'telas/cadastro/transportadores/lista/CARREGADA';

const INITIAL_STATE = {
  carregando: false as boolean,
  resultado: {
    total: 0 as number,
    page: 0 as number,
    pageSize: 25 as number,
    items: [] as readonly string[],
  },
} as const;

type Actions = ReturnType<typeof listaCarregando | typeof listaCarregada>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case CARREGANDO:
      return { ...state, carregando: true };
    case CARREGADA:
      return { ...state, carregando: false, resultado: { ...action.resultado, items: action.resultado.items.map((r) => r._id || '') } };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.cadastro.transportador.lista;

// ACTION CREATORS

export function listaCarregando() {
  return { type: CARREGANDO } as const;
}

export function listaCarregada(resultado: ItensPaginados<Transportador>) {
  return { type: CARREGADA, resultado } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query?: ParsedUrlQueryInput) {
  return makeThunk(async (dispatch) => {
    try {
      await dispatch(listaCarregando());
      await dispatch(contasActions.carregaDados());

      const r = await dispatch(transportadoresActions.carregaLista(query));

      await dispatch(listaCarregada(r));

      return r;
    } catch (e) {
      await dispatch(alertas.erro(e));
      throw e;
    }
  });
}
