import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';

import { match, useLocation, useRouteMatch } from 'react-router';
import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';
import * as qs from 'qs';
import { useDispatch } from 'react-redux';

type OnEnter = (args: { match: match<Record<string, string>>; query: ParsedUrlQueryInput }) => void;

/**
 * Engloba o componente em um HoC representando uma "Tela" do sistema.
 *
 * O HoC de "Tela" oferece os seguintes recursos:
 * - Faz o dispatch de uma action que seja retornada pelo "onEnter";
 * - Oferece uma prop "query", com a querystring interpretada;
 */
export function tela<T>(Component: ComponentType<T>, onEnter?: OnEnter) {
  // TODO: descobrir como tipar este HoC
  function Tela<P extends T>(props: P) {
    const routeMatch = useRouteMatch<Record<string, string>>();
    const location = useLocation();
    const dispatch = useDispatch();

    const query = useMemo(() => (!location?.search ? {} : qs.parse(location.search.substr(1))) as ParsedUrlQuery, [location]);

    const handleEnter = useCallback(() => {
      if (onEnter) dispatch(onEnter({ match: routeMatch, query }));
    }, [dispatch, routeMatch, query]);

    useEffect(() => {
      handleEnter();
    }, [handleEnter]);

    return <Component {...props} />;
  }

  return Tela;
}
