import _ from 'lodash';

import { Nfe, ProdutoServico, Transporte } from '../types/apiNfe';

import { valorSeletor } from './seletor';
import { NumberProxy } from './numProxy';

// ver: https://stackoverflow.com/a/52668133/292586
export type Expr<T = any> = { bivarianceHack(section: NumberProxy<T>, form: NumberProxy<T>): number | null }['bivarianceHack'];

function makeExpr<T>(expr: Expr<T>) {
  return expr;
}

export const TOTAL_COMERCIAL = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(Number(pro.valorUnitarioComercial), Number(pro.qtdComercial))) return null;

  return Number(pro.valorUnitarioComercial) * Number(pro.qtdComercial);
});

// RN item.23a
export const VALOR_COFINS_ST_REAIS = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.aliquotaReaisCofinsSt, pro.qtdVendidaCofinsSt) || valorSeletor(pro.o.tipoCalculoCofinsSt) !== 'R') return null;

  return pro.aliquotaReaisCofinsSt * pro.qtdVendidaCofinsSt;
});

// RN item.23
export const VALOR_COFINS_ST_PCT = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcCofinsSt, pro.aliquotaCofinsSt) || valorSeletor(pro.o.tipoCalculoCofinsSt) !== 'P') return null;

  return pro.valorBcCofinsSt * (pro.aliquotaCofinsSt / 100);
});

// RN item.22a
export const VALOR_COFINS_REAIS = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.aliquotaReaisCofins, pro.qtdVendidaCofins)) return null;

  return pro.aliquotaReaisCofins * pro.qtdVendidaCofins;
});

// RN item.22
export const VALOR_COFINS_PCT = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcCofins, pro.aliquotaPercentualCofins)) return null;

  return pro.valorBcCofins * (pro.aliquotaPercentualCofins / 100);
});

// RN item.11
export const VALOR_ICMS = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcIcms, pro.aliquotaIcms)) return null;

  return pro.valorBcIcms * (pro.aliquotaIcms / 100);
});

// RN item.11a
export const VALOR_ICMS_51 = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorIcmsOperacao, pro.valorIcmsDiferido)) return null;

  return pro.valorIcmsOperacao - pro.valorIcmsDiferido;
});

// RN item.12
export const VALOR_FCP_00 = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcIcms, pro.percentualFcp)) return null;

  return pro.valorBcIcms * (pro.percentualFcp / 100);
});

// RN item.12a
export const VALOR_FCP = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcFcp, pro.percentualFcp)) return null;

  return pro.valorBcFcp * (pro.percentualFcp / 100);
});

// RN item.14
export const VALOR_FCP_RETIDO_ST = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (algumNumeroValido(pro.valorBcFcpRetidoSt, pro.percentualFcpRetidoSt, pro.valorFcp))
    // Campos não informados devem ser considerados como '0'
    return numeroValido(pro.valorBcFcpRetidoSt) * (numeroValido(pro.percentualFcpRetidoSt) / 100) - numeroValido(pro.valorFcp);

  return null;
});

// Retorna o número ou zero caso esse não seja número válido
function numeroValido(n: any) {
  return Number.isFinite(n) ? n : 0;
}

// RN item.16
export const VALOR_FCP_RETIDO_ANTER_ST = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcFcpRetidoAnteriormenteSt, pro.percentualFcpRetidoAnteriormenteSt)) return null;

  return pro.valorBcFcpRetidoAnteriormenteSt * (pro.percentualFcpRetidoAnteriormenteSt / 100);
});

// RN item.15
export const VALOR_ICMS_ST_RETIDO_ANTER = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcIcmsStRetidoAnteriormente, pro.aliquotaSuportadaConsumidorFinal)) return null;

  return pro.valorBcIcmsStRetidoAnteriormente * (pro.aliquotaSuportadaConsumidorFinal / 100);
});

// RN item.27
export const VALOR_BC_EFETIVA = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!pro.valorTotalBruto || !numerosValidos(pro.valorTotalBruto, pro.icmsEfetivaPercentualReducaoBc)) return null;

  return pro.valorTotalBruto * (1 - pro.icmsEfetivaPercentualReducaoBc / 100);
});

// RN item.26
export const VALOR_ICMS_EFETIVO = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.icmsEfetivaValorBc, pro.icmsEfetivaAliquota)) return null;

  return pro.icmsEfetivaValorBc * (pro.icmsEfetivaAliquota / 100);
});

// RN item.13a
export const VALOR_ICMS_ST = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcIcmsSt, pro.aliquotaIcmsSt)) return null;

  return pro.valorBcIcmsSt * (pro.aliquotaIcmsSt / 100);
});

// RN item.13
export const VALOR_ICMS_ST_COM_SUBTRACAO = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcIcmsSt, pro.aliquotaIcmsSt, pro.valorIcms)) return null;

  return pro.valorBcIcmsSt * (pro.aliquotaIcmsSt / 100) - pro.valorIcms;
});

// RN item.17
export const VALOR_ICMS_OPERACAO = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcIcms, pro.aliquotaIcms)) return null;

  return pro.valorBcIcms * (pro.aliquotaIcms / 100);
});

// RN item.18
export const VALOR_ICMS_DIFERIDO = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorIcmsOperacao, pro.percentualDiferimento)) return null;

  return pro.valorIcmsOperacao * (pro.percentualDiferimento / 100);
});

export const VALOR_IPI_PCT = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcIpi, pro.aliquotaIpi)) return null;

  return pro.valorBcIpi * (pro.aliquotaIpi / 100);
});
export const VALOR_IPI_UN = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.qtdTotalUnidadePadraoIpi, pro.valorUnidadeIpi)) return null;

  return pro.qtdTotalUnidadePadraoIpi * pro.valorUnidadeIpi;
});

// RN item.20
export const VALOR_PIS_PCT = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcPis, pro.aliquotaPisPercentual)) return null;

  return pro.valorBcPis * (pro.aliquotaPisPercentual / 100);
});

// RN item.20a
export const VALOR_PIS_REAIS = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.aliquotaPisReais, pro.qtdVendidaPis)) return null;

  return pro.aliquotaPisReais * pro.qtdVendidaPis;
});

// RN item.21
export const VALOR_PIS_ST_PCT = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcPisSt, pro.aliquotaPisStPercentual) || valorSeletor(pro.o.tipoCalculoPisSt) !== 'P') return null;

  return pro.valorBcPisSt * (pro.aliquotaPisStPercentual / 100);
});

// RN item.21a
export const VALOR_PIS_ST_REAIS = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.aliquotaPisStReais, pro.qtdVendidaPisSt) || valorSeletor(pro.o.tipoCalculoPisSt) !== 'R') return null;

  return pro.aliquotaPisStReais * pro.qtdVendidaPisSt;
});

// RN item.08b
export const VALOR_ICMS_FCP_UF_DESTINO = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcFcpUfDestino, pro.percentualIcmsFcpUfDestino)) return null;

  return pro.valorBcFcpUfDestino * (pro.percentualIcmsFcpUfDestino / 100);
});

// RN item.08
export const VALOR_ICMS_INTERESTADUAL_UF_DESTINO = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  const valorAliquotaInterestadualUfsEnvolvidas = valorSeletor(pro.aliquotaInterestadualUfsEnvolvidas);
  const valorPercentualProvisorioPartilhaIcms = valorSeletor(pro.percentualProvisorioPartilhaIcms);

  if (
    !numerosValidos(pro.valorBcIcmsUfDestino, pro.aliquotaInternaUfDestino, valorAliquotaInterestadualUfsEnvolvidas, valorPercentualProvisorioPartilhaIcms) ||
    typeof valorAliquotaInterestadualUfsEnvolvidas !== 'number' ||
    typeof valorPercentualProvisorioPartilhaIcms !== 'number'
  )
    return null;

  return (
    pro.valorBcIcmsUfDestino * ((pro.aliquotaInternaUfDestino - valorAliquotaInterestadualUfsEnvolvidas) / 100) * (valorPercentualProvisorioPartilhaIcms / 100)
  );
});

// RN item.08a
export const VALOR_ICMS_INTERESTADUAL_UF_REMETENTE = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  const valorAliquotaInterestadualUfsEnvolvidas = valorSeletor(pro.aliquotaInterestadualUfsEnvolvidas);

  if (
    !numerosValidos(pro.valorBcIcmsUfDestino, pro.aliquotaInternaUfDestino, valorAliquotaInterestadualUfsEnvolvidas, pro.valorIcmsInterestadualUfDestino) ||
    typeof valorAliquotaInterestadualUfsEnvolvidas !== 'number'
  )
    return null;

  return pro.valorBcIcmsUfDestino * ((pro.aliquotaInternaUfDestino - valorAliquotaInterestadualUfsEnvolvidas) / 100) - pro.valorIcmsInterestadualUfDestino;
});

export const VALOR_ICMS_RETIDO = makeExpr((trans: NumberProxy<Transporte>) => {
  if (!numerosValidos(trans.valorBcIcms, trans.aliquota)) return null;

  return trans.valorBcIcms * (trans.aliquota / 100);
});

// RN item.24
export const VALOR_ISSQN = makeExpr((pro: NumberProxy<ProdutoServico>) => {
  if (!numerosValidos(pro.valorBcIssqn, pro.aliquotaIssqn)) return null;

  return pro.valorBcIssqn * (pro.aliquotaIssqn / 100);
});

export const VALOR_LIQUIDO = makeExpr((f: NumberProxy<Nfe>) => {
  if (numerosValidos(f.faturaValorDesconto)) {
    const valorOriginal = numerosValidos(f.faturaValorOriginal) ? f.faturaValorOriginal : 0;
    return valorOriginal - f.faturaValorDesconto;
  }

  return f.faturaValorOriginal;
});

// Quando usuário apaga valor do campo após preenchimento, considera valor zero.
// Adiciona função para não considerar campo sem valor como zero
function numerosValidos(...num: any[]) {
  return num.every((n) => Number.isFinite(n));
}

export function algumNumeroValido(...num: any[]) {
  return num.some((n) => Number.isFinite(n));
}

/**
 * Função para obter os props utilizados na função expressão
 */
export function calculaPropsParaExpr<T>(expr: Expr<T>) {
  const props = new Set<string | number | symbol>();
  const proxy = new Proxy(
    {},
    {
      get(t, prop) {
        if (prop === 'o') return 0;

        props.add(prop);
        return 1;
      },
    }
  ) as NumberProxy<T>;
  _.attempt(() => expr(proxy, proxy));
  return [...props];
}
