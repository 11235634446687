import validate from 'validate.js';
import moment from 'moment';
import _ from 'lodash';

import * as formatacao from '../../../util/formatacao';

import { CampoProps, TipoCampo } from './CampoProps';

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
const INT_REGEX = /^(0|[1-9]\d*)$/;
const DECIMAL_REGEX = /^(0|[1-9]\d*)(,\d+)?$/;

interface Constraints {
  presence?: boolean;
  datetime?: boolean | { dateOnly: true };
  length?: { minimum?: number; maximum?: number };
  decimal?: { antes?: number; depois?: number };
  opcoes?: { tamanhosOpcoes?: number[] };
  telefone?: { tamanhoMinimo?: number; tamanhoMaximo?: number };
  format?: RegExp;
}

export class RegrasValidacao {
  constraints: Constraints;

  formatFunc: (arg0: any) => string;

  constructor(constraints: Constraints, formatFunc: (val: any) => any) {
    this.constraints = constraints;
    this.formatFunc = formatFunc;
  }

  // validate.js espera receber null quando o campo não foi informado.
  valida = (valor: any) => validate.single(this.formatFunc(valor) || null, this.constraints);
}

validate.extend(validate.validators.datetime, {
  parse(value: any) {
    return +moment.utc(value, ['DD/MM/YYYY', 'DD/MM/YYYY hh:mm']);
  },
  format(value: any, options: { dateOnly: boolean }) {
    const format = options.dateOnly ? 'DD/MM/YYYY' : 'DD/MM/YYYY hh:mm';
    return moment.utc(value).format(format);
  },
});

export function isNumeric(numero: any) {
  return Number.isFinite(parseFloat(numero));
}

export function decimalValidator(value: unknown, { antes, depois }: { antes: number; depois: number }) {
  if (value === null || value === undefined) return undefined;
  if (!_.isString(value)) return 'Valor não é string';

  if (!value.includes(',')) {
    if (!isNumeric(value)) {
      return 'Valor possui caractere diferente de número, "." e ","';
    }

    if (value.length > antes) {
      return `Até ${antes} posições antes da vírgula`;
    }
  } else {
    const res = value.split(',');
    let antesVirgula = res[0];
    antesVirgula = antesVirgula.split('.').join('');
    const depoisVirgula = res[1];

    if (!isNumeric(antesVirgula) || !isNumeric(depoisVirgula)) {
      return 'Valor possui caractere diferente de número, "." e ","';
    }
    if (antesVirgula.length > antes) {
      return `Até ${antes} posições antes da vírgula`;
    }
    if (depoisVirgula.length > depois) {
      return `Até ${depois} posições depois da vírgula`;
    }
  }

  return undefined;
}

validate.validators.decimal = decimalValidator;

export function telefoneValidator(value: any, { tamanhoMinimo, tamanhoMaximo }: { tamanhoMinimo: number; tamanhoMaximo: number }) {
  if (value === null || value === undefined) return undefined;

  const digitos = String(value).replace(/\D/g, ''); // remove qualquer caracter que não seja dígito

  if (digitos.length < tamanhoMinimo || digitos.length > tamanhoMaximo) return `Entre ${tamanhoMinimo} e ${tamanhoMaximo} dígitos`;

  return undefined;
}

validate.validators.telefone = telefoneValidator;

export function opcoesValidator(value: any, { tamanhosOpcoes }: { tamanhosOpcoes: number[] }) {
  if (value === null || value === undefined) return undefined;

  if (tamanhosOpcoes && tamanhosOpcoes.length > 0 && !tamanhosOpcoes.includes(String(value).length)) return `${tamanhosOpcoes.join(' ou ')} posições`;

  return undefined;
}

validate.validators.opcoes = opcoesValidator;

const cacheRegras = new Map<string, RegrasValidacao>();

/**
 * Obtém regras de validação a partir de um cache de regras.
 *
 * A intenção é evitar criar novas funções de validação para campos com validação idêntica.
 *
 * @param props as propriedades do campo
 */
export function obtemRegrasValidacao(props: CampoProps): RegrasValidacao {
  const chave = [props.tamanhoMaximo, props.tamanhoMinimo, props.tamanhosOpcoes, props.antes, props.depois, props.obrigatorio, props.tipo].join('-');

  if (cacheRegras.has(chave)) {
    return cacheRegras.get(chave)!;
  }

  const regras = montaRegrasValidacao(props);
  cacheRegras.set(chave, regras);
  return regras;
}

/**
 * Monta as regras de validação do validate.js e função de formatação, de acordo com as props do campo.
 *
 * Documentação: https://validatejs.org/
 */
export function montaRegrasValidacao({ tamanhoMaximo, tamanhoMinimo, tamanhosOpcoes, antes, depois, obrigatorio = false, tipo }: CampoProps): RegrasValidacao {
  const constraints: Constraints = {};
  let formatFunc = formatacao.noop;

  if (obrigatorio && tipo !== 'booleano')
    // valor falso gera erro de obrigatoriedade. Campos booleanos obrigatórios precisam de valor default.
    constraints.presence = true;

  if (tipo !== 'selecao' && tipo !== 'telefone' && tipo !== 'moedaValorUnitario' && (tamanhoMinimo || tamanhoMaximo)) {
    constraints.length = {};
    if (tamanhoMinimo) constraints.length.minimum = tamanhoMinimo;
    if (tamanhoMaximo) constraints.length.maximum = tamanhoMaximo;
  }

  if (antes || depois) {
    constraints.decimal = {};
    if (antes) constraints.decimal.antes = antes;
    if (depois) constraints.decimal.depois = depois;
  }

  if (tamanhosOpcoes) constraints.opcoes = { tamanhosOpcoes };

  if (tipo === 'telefone' && tamanhoMinimo && tamanhoMaximo) constraints.telefone = { tamanhoMinimo, tamanhoMaximo };

  if (tipo === 'fci') formatFunc = formatacao.fci;

  if (tipo === 'inteiro') {
    constraints.format = INT_REGEX;
    formatFunc = formatacao.inteiro;
  }

  if (tipo === 'decimal' || tipo === 'percentual') constraints.format = DECIMAL_REGEX;

  if (tipo === 'email') constraints.format = EMAIL_REGEX;

  if (tipo === 'decimal' || tipo === 'percentual') formatFunc = formatacao.decimal;

  if (tipo === 'moeda') formatFunc = formatacao.moeda;

  if (tipo === 'moedaValorUnitario') formatFunc = formatacao.moedaValorUnitario;

  if (tipo === 'aliquota') formatFunc = formatacao.aliquota;

  if (tipo === 'aliquotaDecimal') formatFunc = formatacao.aliquotaDecimal;

  if (tipo === 'peso') formatFunc = formatacao.peso;

  if (tipo === 'livre') formatFunc = formatacao.livre;

  if (tipo === 'dataHora') constraints.datetime = true;

  if (tipo === 'data') constraints.datetime = { dateOnly: true };

  return new RegrasValidacao(constraints, formatFunc);
}

export function mascaraPadrao(tipo: TipoCampo | null | undefined): string | null | undefined {
  switch (tipo) {
    case 'cnpj':
      return '99.999.999/9999-99';
    case 'cpf':
      return '999.999.999-99';
    case 'cep':
      return '99999-999';
    case 'data':
      return '99/99/9999';
    case 'dataHora':
      return '99/99/9999 99:99';
    case 'mesAno':
      return '99 / 99';
    case 'fci':
      return '********-****-****-****-************';
    case 'chaveAcesso':
      return '99 9999 99999999999999 99 999 999999999 9 99999999 9';
    default:
      return undefined;
  }
}

export function limpaMascara(v: any): string {
  return String(v).replace(/\D+/g, '');
}
