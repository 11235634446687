import { ParsedUrlQueryInput } from 'querystring';

import { createRootSelector, makeThunk } from '../../../../../../types';
import { Inutilizacao, ItensPaginados } from '../../../../../../types/api';
import * as alertas from '../../../../../gerais/alertas';
import * as aba from '../aba';
import api from '../../../../../../api';

export const ATUALIZA_EMITENTE_ID = 'telas/ferramentas/configuracoes/emitentes/inutilizacoes/lista/ATUALIZA_EMITENTE_ID';
export const CARREGANDO = 'telas/ferramentas/configuracoes/emitentes/inutilizacoes/lista/CARREGANDO';
export const CARREGADA = 'telas/ferramentas/configuracoes/emitentes/inutilizacoes/lista/CARREGADA';

const INITIAL_STATE = {
  emitenteId: null as string | null,
  carregando: false as boolean,
  resultado: {
    total: 0 as number,
    page: 0 as number,
    pageSize: 25 as number,
    items: [] as ReadonlyArray<Inutilizacao>,
  },
} as const;

type Actions = ReturnType<typeof listaCarregando | typeof listaCarregada | typeof atualizaEmitenteId>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case ATUALIZA_EMITENTE_ID:
      return { ...state, emitenteId: action.emitenteId };
    case CARREGANDO:
      return { ...state, carregando: true };
    case CARREGADA:
      return { ...state, carregando: false, resultado: action.resultado };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = createRootSelector((s) => s.telas.ferramentas.configuracoes.emitentes.inutilizacoes.lista);

// ACTION CREATORS

export function listaCarregando() {
  return { type: CARREGANDO } as const;
}

export function listaCarregada(resultado: ItensPaginados<Inutilizacao>) {
  return { type: CARREGADA, resultado } as const;
}

function atualizaEmitenteId(emitenteId: string) {
  return { type: ATUALIZA_EMITENTE_ID, emitenteId } as const;
}

// THUNK ACTION CREATORS

export function carregaLista(query: ParsedUrlQueryInput, emitenteId: string) {
  return makeThunk(async (dispatch) => {
    try {
      dispatch(aba.defineAbaAtual({ emitenteId, abaAtiva: 'inutilizacoes' }));
      dispatch(listaCarregando());
      dispatch(atualizaEmitenteId(emitenteId));

      const r = await api.inutilizacoes.index({ ...query, emitenteId });

      await dispatch(listaCarregada(r));

      return r;
    } catch (e) {
      return dispatch(alertas.erro(e));
    }
  });
}
