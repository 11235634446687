import { push } from 'react-router-redux';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { createRootSelector, makeThunk } from '../../../../types';
import { Destinatario } from '../../../../types/api';
import * as destinatariosActions from '../../../transacionais/destinatarios';
import * as municipiosActions from '../../../transacionais/municipios';
import * as ufsActions from '../../../transacionais/ufs';
import * as paisesActions from '../../../transacionais/paises';
import * as contasActions from '../../../transacionais/contas';
import api from '../../../../api';
import { alertaObrigatorio, obterMsgAlerta, salvaRegistro } from '../salvaRegistro';
import { obterTituloEdicao } from '../util';
import { alertaModal, confirmaModal } from '../../../../util/dialogos';
import { formToJS } from '../../../../util/listas';

export const DEFINE_TITULO = 'telas/cadastro/destinatarios/detalhes/DEFINE_TITULO';
export const NOVO = 'telas/cadastro/destinatarios/detalhes/NOVO';
export const DEFINE_ITEM_ATUAL = 'telas/cadastro/destinatarios/detalhes/DEFINE_ITEM_ATUAL';

export const DESTINATARIO_VAZIO: Destinatario = {
  nomeRazaoSocial: '',
  nomeFantasia: '',
  tipoDocumento: '',
  cnpj: '',
  cpf: '',
  identificacaoDestinatarioEstrangeiro: '',
  tipoContribuinte: undefined,
  inscricaoEstadual: '',
  inscricaoSuframa: '',
  telefone: '',
  email: '',
  contaId: '',
  endereco: {
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    uf: '',
    municipioId: undefined,
    codPais: 1058,
    nomePais: 'BRASIL',
  },
  ativo: true,
} as const;

const INITIAL_STATE = {
  titulo: '' as string,
  item: DESTINATARIO_VAZIO,
} as const;

type Actions = ReturnType<typeof defineTitulo | typeof defineItemAtual | typeof novoDestinatario>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_TITULO:
      return { ...state, titulo: action.titulo };
    case DEFINE_ITEM_ATUAL:
      return { ...state, item: { ...DESTINATARIO_VAZIO, ...action.destinatario } };
    case NOVO:
      return { ...state, item: DESTINATARIO_VAZIO };
    default:
      return state;
  }
}

// SELECTORS

const rootSelector = createRootSelector((s) => s.telas.cadastro.destinatario.detalhes);

export const dadosAtuaisSelector = createSelector(rootSelector, (d) => d.item);
export const tituloSelector = createSelector(rootSelector, (d) => d.titulo);

// ACTION CREATORS

export function defineTitulo(titulo: string) {
  return { type: DEFINE_TITULO, titulo } as const;
}

export function novoDestinatario() {
  return { type: NOVO } as const;
}

export function defineItemAtual(destinatario?: Destinatario) {
  return { type: DEFINE_ITEM_ATUAL, destinatario } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega o destinatário com o ID selecionado na store transacional, e copia os dados para a tela.
 */
export function carregaRegistro(id: string) {
  return makeThunk(async (dispatch) => {
    const destinatario: Destinatario = await dispatch(destinatariosActions.carregaDestinatario(id));

    await Promise.all([
      dispatch(defineItemAtual(destinatario)),
      dispatch(defineTitulo(obterTituloEdicao(destinatario.nomeRazaoSocial))),

      dispatch(contasActions.carregaDados()),
      dispatch(municipiosActions.carregaDados()),
      dispatch(ufsActions.carregaDados()),
      dispatch(paisesActions.carregaDados()),
    ]);

    return destinatario;
  });
}

/**
 + * Carrega novo cliente, contas e municipios na store.
 + */
export function carregaNovoDestinatario() {
  return makeThunk(async (dispatch) => {
    await dispatch(novoDestinatario());
    await dispatch(defineTitulo('Novo Destinatário'));

    await dispatch(contasActions.carregaDados());
    await dispatch(municipiosActions.carregaDados());
    await dispatch(ufsActions.carregaDados());
    await dispatch(paisesActions.carregaDados());
  });
}

export function salva(form: any) {
  const formInJS = formToJS(form);

  const formSemDataCriacao = _.omit(formInJS, 'criadoEm');

  return makeThunk(async (dispatch) => {
    const alertasValidacao = await api.destinatarios.validar(formSemDataCriacao);

    if (alertaObrigatorio(alertasValidacao)) {
      throw new Error(obterMsgAlerta(alertasValidacao, 'destinatario'));
    }

    if (await salvaRegistro(alertasValidacao, 'destinatario')) {
      const r = await dispatch(destinatariosActions[formInJS._id ? 'atualiza' : 'cria'](formSemDataCriacao));

      dispatch(push(`/app/cadastro/destinatarios/${r._id}`)); // navega para a tela de detalhes

      return r;
    }
    throw new Error('Envio do formulário cancelado.');
  });
}

export function excluir() {
  return makeThunk(async (dispatch, getState) => {
    // busca o destinatário na store, ou um mapa vazio se não encontrar
    const destinatario = dadosAtuaisSelector(getState());

    if (!destinatario._id) return;

    if (await confirmaModal('Confirma a exclusão deste cliente?')) {
      let registroExcluido = true;
      try {
        await api.destinatarios.excluir(destinatario._id);
      } catch (e) {
        registroExcluido = false;
        await alertaModal(e.message);
      }

      if (registroExcluido) {
        await alertaModal('Cliente excluído.');
        dispatch(push(`/app/cadastro/destinatarios`)); // navega para a página de destinatários
      }
    } else {
      await alertaModal('Operação cancelada.');
    }
  });
}
