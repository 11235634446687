import { push } from 'react-router-redux';
import { createSelector } from 'reselect';

import Bugsnag from '../../../../config/bugsnag';
import { Dispatch, GetState, makeThunk, State } from '../../../../types';
import * as nfes from '../../../transacionais/nfes';
import api from '../../../../api/index';
import { alertaModal, confirmaModal } from '../../../../util/dialogos';
import { ajustaNfeParaBackend, ajustaNfeParaEdicao } from '../../../../util/nfe';
import { formToJS } from '../../../../util/listas';
import { Nfe } from '../../../../types/apiNfe';

import { abreModalAlerta } from './modalAlerta';
import * as nota from './nota';

export const ABRE_NOTA = 'telas/nfe/emissao/item/ABRE_NOTA';
export const VOLTAR = 'telas/nfe/emissao/item/VOLTAR';

const INITIAL_STATE = {} as Readonly<Nfe>;

const PERGUNTA_EXCLUIR = 'A NF-e será excluída do sistema, não sendo possível a sua recuperação posterior. Deseja excluir a NF-e?';
const ALERTA_SEM_EDICAO = 'Não é possível a alteração da NF-e, pois sua emissão já foi solicitada.';
const ALERTA_ERRO = 'Ocorreu um erro na validação da NF-e. Entre em contato com o suporte.';

type Actions = ReturnType<typeof abreNota>;

export default function reducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case ABRE_NOTA:
      return ajustaNfeParaEdicao(action.nota);

    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.nfe.emissao.item;
export const produtoServicoSelector = createSelector(dadosAtuaisSelector, (root) => root.produtoServico);
export const validacoesNfeSelector = createSelector(dadosAtuaisSelector, (root) => root.validacoesNfe);
export const situacaoNfeSelector = createSelector(dadosAtuaisSelector, (root) => root.situacaoNfe);

// ACTION CREATORS

export function abreNota(obj: Nfe) {
  return { type: ABRE_NOTA, nota: obj } as const;
}

export function voltar() {
  return { type: VOLTAR };
}

// THUNK ACTION CREATORS

export function iniciaNovaNota() {
  return async function (dispatch: Dispatch) {
    // monta o rascunho da nova nota
    const novaNota = await dispatch(nfes.iniciaNovaNota());

    await dispatch(abreNota(novaNota));

    if (novaNota && novaNota._id) await dispatch(push(`/app/nfe/emissao/${novaNota._id}/emitente`));

    return novaNota;
  };
}

export function carregaNota(id: string) {
  return async function (dispatch: Dispatch) {
    const nfe = await dispatch(nfes.abreNota(id));

    await dispatch(abreNota(nfe));

    return nfe;
  };
}

export function salva(pagina: string, form: Record<string, any>) {
  // eslint-disable-next-line no-console
  console.log('salvando', pagina, form);

  return async function (dispatch: Dispatch) {
    const r = await api.nfe.update(ajustaNfeParaBackend(formToJS(form) as Nfe)); // salva no backend

    if (r.alertas && r.alertas.length !== 0) {
      const alertas = r.alertas.join('\n');

      await alertaModal(alertas);

      if (alertas.includes(ALERTA_SEM_EDICAO)) {
        return dispatch(push(`/app/nfe/consulta/detalhes/${r.nfe._id}`)); // navega para a tela de detalhes da NF-e
      }

      return r.nfe;
    }

    const abaAtual = ABAS_EMISSAO[ABAS_EMISSAO.indexOf(pagina)];
    if (r.proximaNumeracao && ['emitente', 'destinatario'].includes(abaAtual)) await dispatch(nota.alteraProximaNumeracao(r.proximaNumeracao));

    if (form.botaoVoltar) return await paginaAnterior(pagina);
    // retorna uma action para a página anterior
    return await proximaPagina(pagina); // retorna uma action para a próxima página
  };
}

export function emitir() {
  return async function (dispatch: Dispatch, getState: GetState) {
    try {
      // busca a nota na store, ou um mapa vazio se não encontrar
      const nfe = dadosAtuaisSelector(getState());

      const r = await api.nfe.emitir(nfe);

      await dispatch(abreNota(r.nfe)); // substituir a nota do store pela que veio do backend

      if (r.alertas.length !== 0) await alertaModal(r.alertas.join('\n'));
      else if (r.nfe.validacaoSchema && r.nfe.validacaoSchema.length === 0) {
        await alertaModal('Emissão solicitada.');
        return dispatch(push(`/app/nfe/consulta/detalhes/${r.nfe._id}`)); // navega para a tela de detalhes da NF-e
      } else {
        await alertaModal('Ocorreu um erro de schema. Favor contatar o suporte técnico.');

        Bugsnag.notify(new Error('Erro de schema na emissão'), (report) =>
          report.addMetadata('emissor', {
            nfe: r.nfe._id,
            erro_schema: r.nfe.validacaoSchema,
          })
        );
      }

      return r.nfe;
    } catch (e) {
      await alertaModal(`Erro desconhecido: ${e}\n Favor contatar o suporte técnico.`);
      Bugsnag.notify(e);
      throw e;
    }
  };
}

export function excluir() {
  return makeThunk(async (dispatch, getState) => {
    // busca a nota na store, ou um mapa vazio se não encontrar
    const nfe = dadosAtuaisSelector(getState());

    if (await confirmaModal(PERGUNTA_EXCLUIR)) {
      let nfeExcluida = true;
      try {
        await api.nfe.excluir(nfe._id);
      } catch (e) {
        nfeExcluida = false;
        await alertaModal(e.message);
      }

      if (nfeExcluida) dispatch(push(`/app/nfe/consulta/pesquisa`)); // navega para a página de consulta
    } else {
      await alertaModal('Operação cancelada.');
    }
  });
}

export function validar(pagina: string, form: Record<string, any>) {
  // eslint-disable-next-line no-console
  console.log('salvando e validando', pagina, form);

  return makeThunk(async (dispatch) => {
    const r = await api.nfe.validar(ajustaNfeParaBackend(formToJS(form) as Nfe)); // salva e valida nota

    await dispatch(abreNota(r.nfe)); // substituir a nota do store pela que veio do backend

    // Se a situação indica alertas e não existe alertas, apresenta mensagem de erro.
    if (r.situacao && r.situacao !== 'sem alertas e validado' && r.alertas && r.alertas.length === 0) {
      Bugsnag.notify(new Error('Erro no salvamento e validação da NF-e.'), (report) =>
        report.addMetadata('emissor', {
          metadata: {
            nfe: r.nfe._id,
            alertas: r.alertas,
            situacao: r.situacao,
          },
        })
      );

      return await alertaModal(ALERTA_ERRO);
    }

    switch (r.situacao) {
      case 'sem alertas e validado':
        return await alertaModal('NF-e validada e salva com sucesso.');
      case 'documento com emissão solicitada':
        await alertaModal(r.alertas.join('\n')); // navega para a tela de detalhes da NF-e

        return dispatch(push(`/app/nfe/consulta/detalhes/${r.nfe._id}`));
      case 'alertas de salvar':
        return await alertaModal(r.alertas.join('\n'));
      case 'alertas de validar':
        return await dispatch(abreModalAlerta(r.alertas));
      default:
        Bugsnag.notify(new Error('Erro no salvamento e validação da NF-e.'), (report) =>
          report.addMetadata('emissor', {
            metadata: {
              nfe: r.nfe._id,
              alertas: r.alertas,
              situacao: r.situacao,
            },
          })
        );
        return await alertaModal(ALERTA_ERRO);
    }
  });
}

export function downloadXml() {
  return makeThunk(async (dispatch, getState) => {
    // busca a nota na store, ou um mapa vazio se não encontrar
    const nfe = dadosAtuaisSelector(getState());
    const situacaoNfe = nfe.situacaoNfe || '';

    if (situacaoNfe === 'Autorizada' || situacaoNfe === 'Cancelada') {
      await api.nfe.downloadXml(nfe._id);
    } else {
      await alertaModal('NF-e não emitida.');
    }
  });
}

export function downloadXmlProtocolo(protocoloId: string) {
  return makeThunk(async (dispatch, getState) => {
    // busca a nota na store, ou um mapa vazio se não encontrar
    const nfe = dadosAtuaisSelector(getState());
    const situacaoNfe = nfe.situacaoNfe || '';

    if (['Autorizada', 'Cancelada', 'Denegada', 'Rejeitada'].includes(situacaoNfe)) {
      await api.protocolo.downloadXml(protocoloId);
    } else {
      await alertaModal('NF-e não emitida.');
    }
  });
}

export function imprimirDanfe() {
  return makeThunk(async (dispatch, getState) => {
    // busca a nota na store, ou um mapa vazio se não encontrar
    const nfe = dadosAtuaisSelector(getState());

    const alerta = await api.nfe.validarImpressaoDanfe(nfe._id);

    if (alerta) await alertaModal(alerta);
    else await api.nfe.imprimirDanfe(nfe._id);
  });
}

const ABAS_EMISSAO = ['emitente', 'nota', 'destinatario', 'itens', 'totais', 'transporte', 'cobranca', 'pagamento', 'finalizar'];

export function proximaPagina(pagina: string) {
  return makeThunk(async (dispatch, getState) => {
    const id = dadosAtuaisSelector(getState())._id; // obtém o ID da NFe atual

    if (!id) {
      return await alertaModal('Ocorreu um erro na consulta da NF-e.');
    }

    const proximaAba = ABAS_EMISSAO[ABAS_EMISSAO.indexOf(pagina) + 1]; // calcula a próxima página

    return dispatch(push(`/app/nfe/emissao/${id}/${proximaAba}`)); // navega para a próxima página
  });
}

export function paginaAnterior(pagina: string) {
  return makeThunk(async (dispatch, getState) => {
    const id = dadosAtuaisSelector(getState())._id; // obtém o ID da NFe atual

    if (!id) {
      return await alertaModal('Ocorreu um erro na consulta da NF-e.');
    }

    const abaAnterior = ABAS_EMISSAO[ABAS_EMISSAO.indexOf(pagina) - 1]; // calcula a página anterior

    return dispatch(push(`/app/nfe/emissao/${id}/${abaAnterior}`)); // navega para a página anterior
  });
}
