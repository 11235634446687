import { delay, fork, put } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import api from '../api';
import * as pingActions from '../reducers/gerais/ping';

/**
 * O tempo entre as chamadas de ping.
 * Definido para 10s em produção e 1h em desenvolvimento.
 */
const TEMPO_ENTRE_PINGS = (process.env.NODE_ENV === 'production' ? 10 : 60 * 60) * 1000;

/**
 * Esta saga é responsável por verificar se o backend está online ou offline.
 *
 * @see module:reducers/ping
 */
export default function* sagaPing() {
  yield fork(ping);
}

function* ping() {
  while (true) {
    try {
      const r = yield* call(api.ping);
      yield put(pingActions.dadosRecebidos(r));
    } catch (e) {
      yield put(pingActions.erro(e));
    }

    yield delay(TEMPO_ENTRE_PINGS);
  }
}
