import { push } from 'react-router-redux';
import _ from 'lodash';

import { makeThunk, State } from '../../../../types';
import { Produto } from '../../../../types/api';
import * as produtosActions from '../../../transacionais/produtos';
import * as contasActions from '../../../transacionais/contas';
import api from '../../../../api';
import { ajustaCadastroParaBackend } from '../../../../util/cadastro';
import { alertaObrigatorio, obterMsgAlerta, salvaRegistro } from '../salvaRegistro';
import { obterTituloEdicao } from '../util';
import { alertaModal, confirmaModal } from '../../../../util/dialogos';
import { formToJS } from '../../../../util/listas';

export const DEFINE_TITULO = 'telas/cadastro/produtos/detalhes/DEFINE_TITULO';
export const NOVO = 'telas/cadastro/produtos/detalhes/NOVO';
export const DEFINE_ITEM_ATUAL = 'telas/cadastro/produtos/detalhes/DEFINE_ITEM_ATUAL';

const PRODUTO_VAZIO: Produto = {
  codigo: '',
  descricao: '',
  contaId: '',
  ativo: true,
  gtinPossui: true,
} as const;

const INITIAL_STATE = {
  titulo: '' as string,
  item: PRODUTO_VAZIO,
} as const;

type Actions = ReturnType<typeof defineTitulo | typeof defineItemAtual | typeof novoProduto>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_TITULO:
      return { ...state, titulo: action.titulo };
    case DEFINE_ITEM_ATUAL:
      return { ...state, item: { ...PRODUTO_VAZIO, ...action.produto } };
    case NOVO:
      return { ...state, item: PRODUTO_VAZIO };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.cadastro.produto.detalhes.item;

export const tituloSelector = (state: State) => state.telas.cadastro.produto.detalhes.titulo;

// ACTION CREATORS

export function defineTitulo(titulo: string) {
  return { type: DEFINE_TITULO, titulo } as const;
}

export function novoProduto() {
  return { type: NOVO } as const;
}

export function defineItemAtual(produto: Produto) {
  return { type: DEFINE_ITEM_ATUAL, produto } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega o cliente com o ID selecionado na store transacional, e copia os dados para a tela.
 */
export function carregaRegistro(id: string) {
  return makeThunk(async (dispatch) => {
    const produto: Produto = await dispatch(produtosActions.carregaProduto(id));
    await dispatch(defineItemAtual(produto));
    await dispatch(defineTitulo(obterTituloEdicao(produto.descricao)));

    await dispatch(contasActions.carregaDados());

    return produto;
  });
}

/**
 * Carrega novo produto e contas na store.
 */
export function carregaNovoProduto() {
  return makeThunk(async (dispatch) => {
    await dispatch(novoProduto());
    await dispatch(defineTitulo('Novo Produto'));

    await dispatch(contasActions.carregaDados());
  });
}

export function salva(form: Partial<Produto>) {
  let formInJS = formToJS(form);
  formInJS = ajustaCadastroParaBackend(formInJS);

  const formSemDataCriacao = _.omit(formInJS, 'criadoEm');

  return makeThunk(async (dispatch) => {
    const alertasValidacao = await api.produtos.validar(formSemDataCriacao);

    if (alertaObrigatorio(alertasValidacao)) {
      throw new Error(obterMsgAlerta(alertasValidacao, 'produto'));
    }

    if (await salvaRegistro(alertasValidacao, 'produto')) {
      const r = await dispatch(produtosActions[formInJS._id ? 'atualiza' : 'cria'](formSemDataCriacao));

      dispatch(push(`/app/cadastro/produtos/${r._id}`)); // navega para a tela de detalhes

      return r;
    }
    throw new Error('Envio do formulário cancelado.');
  });
}

export function excluir() {
  return makeThunk(async function (dispatch, getState) {
    // busca o produto na store, ou um mapa vazio se não encontrar
    const produto = dadosAtuaisSelector(getState());
    if (!produto._id) return;

    if (await confirmaModal('Confirma a exclusão deste produto?')) {
      let registroExcluido = true;
      try {
        await api.produtos.excluir(produto._id);
      } catch (e) {
        registroExcluido = false;
        await alertaModal(e.message);
      }

      if (registroExcluido) {
        await alertaModal('Produto excluído.');
        await dispatch(push(`/app/cadastro/produtos`)); // navega para a página de produtos
      }
    } else {
      await alertaModal('Operação cancelada.');
    }
  });
}
