import { createSelector } from 'reselect';
import { autofill, formValueSelector } from 'redux-form';

import { Dispatch, GetState, State } from '../../../../types';
import { ProdutoServico } from '../../../../types/apiNfe';
import api from '../../../../api';
import { indexBy } from '../../../../util/listas';
import { Itens } from '../../../../types/api';

import * as itens from './itens';

export const DADOS_SELECT_RECEBIDOS = 'telas/nfe/emissao/produto/DADOS_SELECT_RECEBIDOS';

const INITIAL_STATE = {
  porId: {} as Readonly<Record<string, ProdutoServico>>,
} as const;

type Actions = ReturnType<typeof dadosSelectRecebidos>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DADOS_SELECT_RECEBIDOS:
      // recebemos os dados como um array, e fazemos a indexação por ID
      return { ...state, porId: { ...state.porId, ...indexBy(action.dados.items, '_id') } };
    default:
      return state;
  }
}

// SELECTORS

export const rootSelector = (state: State) => state.telas.nfe.emissao.produto;

export const todosSelector = createSelector(rootSelector, (root) => Object.values(root.porId));

// ACTION CREATORS

export function dadosSelectRecebidos(dados: Itens<ProdutoServico>) {
  return { type: DADOS_SELECT_RECEBIDOS, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaOpcoesSelect() {
  return async function (dispatch: Dispatch) {
    // carrega os dados dos produtos
    const produtos = await api.nfe.produtos();
    dispatch(dadosSelectRecebidos(produtos));

    return produtos;
  };
}

const nfeSelector = formValueSelector('nfe');

export function trocaProduto(opcao: { value: string; label: string } | null | undefined) {
  return async function (dispatch: Dispatch, getState: GetState) {
    const itemAtivo = itens.itemAtivoSelector(getState());

    if (typeof itemAtivo !== 'number') {
      return null;
    }

    const produtoComCamposDefault = {
      tipo: 'produto',
      itemNumero: itemAtivo + 1,
      valorCompoeTotalNfe: 1,
      gtinPossui: true,
    };

    if (!opcao || !opcao.value) {
      return dispatch(autofill('nfe', `produtoServico[${itemAtivo}]`, produtoComCamposDefault));
    }

    // busca os dados do produto
    const produtoNfeSelecionado = await api.nfe.dadosProduto(opcao.value);

    const valorUnitarioComercial = produtoNfeSelecionado.valorUnitarioComercial ? +produtoNfeSelecionado.valorUnitarioComercial : undefined;

    // preenche os campos do formulário mantendo aqueles que não estão em produto
    const produtoItem = nfeSelector(getState(), `produtoServico[${itemAtivo}]`);
    const regimeEmitente = nfeSelector(getState(), 'emitente.regime');
    const produtoAtual = {
      ...produtoItem,
      ...produtoNfeSelecionado,
      tipoTributo: 'ICMS',
      regime: regimeEmitente,
      valorUnitarioComercial,
      valorUnitarioTributavel: valorUnitarioComercial,
    };

    return dispatch(autofill('nfe', `produtoServico[${itemAtivo}]`, produtoAtual));
  };
}
