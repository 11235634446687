import { push } from 'react-router-redux';

import { Dispatch, State } from '../../../../types';
import api from '../../../../api/index';
import { alertaModal, confirmaModal } from '../../../../util/dialogos';
import { formToJS } from '../../../../util/listas';
import { RequisicaoCancelamento } from '../../../../types/api';

import { desabilitaBotaoComTimeout } from './botaoComCliqueUnico';

const DEFINE_ITEM_ATUAL = 'telas/nfe/consulta/cancelar/DEFINE_ITEM_ATUAL';
const PERGUNTA_CANCELAR = 'Deseja realmente solicitar o cancelamento da NF-e?';

const INITIAL_STATE = {
  nfeId: '' as string,
  justificativaCancelamento: '',
  envioXmlDestinatario: true,
  envioXmlTransportador: true,
} as const;

type Actions = ReturnType<typeof defineItemAtual>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_ITEM_ATUAL:
      return { ...state, nfeId: action.dados };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.nfe.consulta.cancelar;

// ACTION CREATORS

export function defineItemAtual(nfeId: string) {
  return { type: DEFINE_ITEM_ATUAL, dados: nfeId } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega a ID da nfe na store transacional.
 */
export function carregaRegistro(id: string) {
  return async function (dispatch: Dispatch) {
    await dispatch(defineItemAtual(id));

    return id;
  };
}

export function salva(form: RequisicaoCancelamento) {
  return async function (dispatch: Dispatch) {
    if (await confirmaModal(PERGUNTA_CANCELAR)) {
      await dispatch(desabilitaBotaoComTimeout(5000));

      const r = await api.nfe.cancelar(formToJS(form));

      if (r.alertas.length !== 0) {
        const alertas = r.alertas.join('\n');

        await alertaModal(alertas);
      } else if (r.comunicacao.envioValidacaoSchema && r.comunicacao.envioValidacaoSchema.length !== 0)
        await alertaModal('Ocorreu um erro de schema. Favor contatar o suporte técnico.');
      else {
        await alertaModal('Cancelamento solicitado. Verifique a resposta da SEFAZ através do menu Consulta.');
        dispatch(push(`/app/nfe/consulta/detalhes/${form.nfeId}`)); // navega para os detalhes da NFe
      }
    } else {
      await alertaModal('Operação cancelada.');
    }
  };
}
