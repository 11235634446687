import Url from 'url';
import { ParsedUrlQueryInput } from 'querystring';

import { push } from 'react-router-redux';
import { autofill, reset } from 'redux-form';
import _ from 'lodash';

import { Dispatch, State } from '../../../../types';
import { dataAteMongoose, dataDeMongoose, dataForm } from '../../../../components/nfe/emissao/util/formatacaoDataHora';
import { formularioJS } from '../../../../util/listas';

import * as opcoesParaFiltroEmitente from './opcoesParaFiltroEmitente';

export type FiltroForm = {
  contaId?: string;
  possuiEmitente?: boolean;
  emitenteId?: string;
  dataEmissaoDe?: string;
  dataEmissaoAte?: string;
  serie?: string;
  numero?: string;
  situacaoNfe?: string;
  ambiente?: string;
  chaveAcessoNfe?: string;
  page?: string;
};

const INITIAL_STATE = {
  contaId: '',
  possuiEmitente: true,
  emitenteId: '',
  dataEmissaoDe: '',
  dataEmissaoAte: '',
  serie: '',
  numero: '',
  situacaoNfe: '',
  ambiente: '',
  chaveAcessoNfe: '',
} as const;

export default function reducer(state = INITIAL_STATE): typeof INITIAL_STATE {
  return state;
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.nfe.consulta.filtros;

// THUNK ACTION CREATORS

export function salva(form: any) {
  const formInJS = formularioJS(form);

  return async function (dispatch: Dispatch) {
    await dispatch(push(prepareUrl(formataDatas(formInJS))));
  };
}

export function limpaFiltros() {
  return function (dispatch: Dispatch) {
    dispatch(reset('nfeFiltrar'));
    dispatch(opcoesParaFiltroEmitente.dadosSelectRecebidos([]));

    dispatch(push(prepareUrl({ possuiEmitente: true })));
  };
}

export function atualizaFiltros(query: FiltroForm) {
  return function (dispatch: Dispatch) {
    return Object.entries(query).forEach(([campo, valor]) => dispatch(autofill('nfeFiltrar', campo, formataDado(campo, valor))));
  };
}

/**
 * Converte a data dos campos data do formulário para o formato utilizado no backend.
 *  recebe data: 29032017
 *  retorna: 2017-03-29T00:00:00-03:00
 */
function formataDatas(form: FiltroForm) {
  return _.mapValues(form, (v, k) => {
    if (k.startsWith('dataEmissao') && v && typeof v === 'string') {
      return (k.endsWith('Ate') ? dataAteMongoose : dataDeMongoose)(v);
    }

    return v;
  });
}

/**
 * Verifica se o campo é tipo data ou booleano e converte valor para valor válido no formulário.
 */
function formataDado(campo: string, valor: any) {
  switch (campo) {
    case 'possuiEmitente':
      return valor === 'true';

    case 'dataEmissaoDe':
    case 'dataEmissaoAte':
      return dataForm(valor);

    default:
      return valor;
  }
}

function prepareUrl(query: ParsedUrlQueryInput) {
  return Url.format({ pathname: '/app/nfe/consulta/pesquisa', query });
}
