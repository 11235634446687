import { createSelector } from 'reselect';
import { push } from 'react-router-redux';

import { Dispatch, GetState, State } from '../../../../../types';
import api from '../../../../../api';

const DEFINE_ABA_ATUAL = 'telas/ferramentas/configuracoes/emitentes/aba/DEFINE_ABA_ATUAL';

const INITIAL_STATE = {
  emitenteId: '' as string,
  abaAtiva: '' as string,
} as const;

type Actions = ReturnType<typeof defineAbaAtual>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_ABA_ATUAL:
      return { ...state, abaAtiva: action.dados.abaAtiva, emitenteId: action.dados.emitenteId };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.ferramentas.configuracoes.emitentes.aba;
export const abaAtivaSelector = createSelector(dadosAtuaisSelector, (root) => root.abaAtiva);

// ACTION CREATORS

export function defineAbaAtual(configuracaoAba: { emitenteId: string; abaAtiva: string }) {
  return { type: DEFINE_ABA_ATUAL, dados: configuracaoAba } as const;
}

// THUNK ACTION CREATORS

export function alteraAbaEmitente(aba: string) {
  return async function (dispatch: Dispatch, getState: GetState) {
    const { emitenteId } = dadosAtuaisSelector(getState());

    if (emitenteId && aba) await dispatch(push(`/app/ferramentas/configuracoes/emitentes/${emitenteId}/${aba}`));
  };
}

export function retornaParaPaginaListaContas() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const { emitenteId } = dadosAtuaisSelector(getState());

    if (emitenteId) {
      const r = await api.emitentes.configuracoes(emitenteId);
      const detalhesConta = r && r.contaId ? `/configuracoes/contas/${r.contaId}` : '';

      await dispatch(push(`/app/ferramentas${detalhesConta}`));
    }
  };
}

export function avancaParaPaginaNovaInutilizacao() {
  return async function (dispatch: Dispatch, getState: GetState) {
    const { emitenteId } = dadosAtuaisSelector(getState());

    if (emitenteId) await dispatch(push(`/app/ferramentas/configuracoes/emitentes/${emitenteId}/inutilizacoes/nova`));
  };
}
