/* eslint-disable no-plusplus */

const ERRO = 'gerais/alertas/ERRO';
const AVISO = 'gerais/alertas/AVISO';
const SUCESSO = 'gerais/alertas/SUCESSO';
const LIMPA = 'gerais/alertas/LIMPA';
const DESTROY_FORM = '@@redux-form/DESTROY';

export interface Alerta {
  tipo: 'danger' | 'warning' | 'success';
  mensagem: string;
  seq: number;
}

const INITIAL_STATE = [] as Alerta[];

type AlertActions =
  | ReturnType<typeof erro>
  | ReturnType<typeof aviso>
  | ReturnType<typeof sucesso>
  | ReturnType<typeof limpaAlertas>
  | { type: typeof DESTROY_FORM };

export default function reducer(state = INITIAL_STATE, action?: AlertActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case ERRO:
      return [...state, { tipo: 'danger', ...action.payload }];
    case AVISO:
      return [...state, { tipo: 'warning', ...action.payload }];
    case SUCESSO:
      return [...state, { tipo: 'success', ...action.payload }];

    case DESTROY_FORM:
    case LIMPA:
      return INITIAL_STATE;

    default:
      return state;
  }
}

// ACTION CREATORS

let seq = 0;

export function erro(mensagem: string) {
  // eslint-disable-next-line no-console
  console.error(mensagem);
  return { type: ERRO, payload: { mensagem, seq: ++seq } } as const;
}

export function aviso(mensagem: string) {
  return { type: AVISO, payload: { mensagem, seq: ++seq } } as const;
}

export function sucesso(mensagem: string) {
  return { type: SUCESSO, payload: { mensagem, seq: ++seq } } as const;
}

export function limpaAlertas() {
  return { type: LIMPA } as const;
}
