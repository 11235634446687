import _ from 'lodash';

import { ProdutoServico } from '../types/apiNfe';

import { toNumber } from './formatacao';

type CampoValorTotal = {
  campo: keyof ValoresTotais;
  calculo: keyof ProdutoServico;
  tipoTributo?: string;
  compoeTotal?: string;
};

type CampoItem = {
  [key: string]: any;
};

export type ValoresTotais = {
  totalFreteNfe?: number;
  totalSeguroNfe?: number;
  totalDescontoNfe?: number;
  totalIiNfe?: number;
  totalIpiNfe?: number;
  totalIpiDevolvidoNfe?: number;
  totalBcIcmsNfe?: number;
  totalIcmsNfe?: number;
  totalIcmsDesoneradoNfe?: number;
  totalIcmsFcpUfDestinoNfe?: number;
  totalIcmsInterestadualUfDestinoNfe?: number;
  totalIcmsInterestadualUfRemetenteNfe?: number;
  totalFcpNfe?: number;
  totalBcIcmsStNfe?: number;
  totalIcmsStNfe?: number;
  totalFcpRetidoStNfe?: number;
  totalFcpRetidoAnteriormenteStNfe?: number;
  totalProdutosServicos?: number;
  totalPisNfe?: number;
  totalCofinsNfe?: number;
  totalOutrasDespesas?: number;
  totalBcIssqnNfe?: number;
  totalIssNfe?: number;
  totalPisServicos?: number;
  totalCofinsServicos?: number;
  totalNaoIncidencia?: number;
  totalDeducaoBcServicos?: number;
  totalDescontoIncondicionado?: number;
  totalDescontoCondicionado?: number;
  totalRetencaoIss?: number;
  valorTotalNfe?: number;
  totalTributosValorAproximado?: number;
};

/**
 * Lista de campos usados no cálculo de valor total.
 */
const CAMPOS_VALOR_TOTAL_TIPO_TRIBUTO: { [k in keyof ValoresTotais]: { calculo: keyof ProdutoServico; tipoTributo?: string; compoeTotal?: '1' } } = {
  // tipoTributo ICMS ou tipoTributo ISSQN
  totalFreteNfe: { calculo: 'valorFrete' },
  totalSeguroNfe: { calculo: 'valorSeguro' },
  totalDescontoNfe: { calculo: 'valorDesconto' },
  totalIpiNfe: { calculo: 'valorTotalIpi' },
  totalIpiDevolvidoNfe: { calculo: 'valorIpiDevolvido' },
  totalTributosValorAproximado: { calculo: 'tributosValorAproximado' },

  // tipoTributo ICMS
  totalBcIcmsNfe: { calculo: 'valorBcIcms', tipoTributo: 'ICMS' },
  totalIcmsNfe: { calculo: 'valorIcms', tipoTributo: 'ICMS' },
  totalIcmsDesoneradoNfe: {
    calculo: 'valorIcmsDesonerado',
    tipoTributo: 'ICMS',
  },
  totalIcmsFcpUfDestinoNfe: {
    calculo: 'valorIcmsFcpUfDestino',
    tipoTributo: 'ICMS',
  },
  totalIcmsInterestadualUfDestinoNfe: {
    calculo: 'valorIcmsInterestadualUfDestino',
    tipoTributo: 'ICMS',
  },
  totalIcmsInterestadualUfRemetenteNfe: {
    calculo: 'valorIcmsInterestadualUfRemetente',
    tipoTributo: 'ICMS',
  },
  totalFcpNfe: { calculo: 'valorFcp', tipoTributo: 'ICMS' },
  totalBcIcmsStNfe: { calculo: 'valorBcIcmsSt', tipoTributo: 'ICMS' },
  totalIcmsStNfe: { calculo: 'valorIcmsSt', tipoTributo: 'ICMS' },
  totalFcpRetidoStNfe: { calculo: 'valorFcpRetidoSt', tipoTributo: 'ICMS' },
  totalFcpRetidoAnteriormenteStNfe: {
    calculo: 'valorFcpRetidoAnteriormenteSt',
    tipoTributo: 'ICMS',
  },
  totalProdutosServicos: {
    calculo: 'valorTotalBruto',
    compoeTotal: '1',
    tipoTributo: 'ICMS',
  },
  totalPisNfe: { calculo: 'valorPis', tipoTributo: 'ICMS' },
  totalCofinsNfe: { calculo: 'valorCofins', tipoTributo: 'ICMS' },
  totalOutrasDespesas: { calculo: 'valorOutrasDespesas', tipoTributo: 'ICMS' },
  totalIiNfe: { calculo: 'iiValor', tipoTributo: 'ICMS' },

  // tipoTributo ISSQN
  totalBcIssqnNfe: { calculo: 'valorBcIssqn', tipoTributo: 'ISSQN' },
  totalIssNfe: { calculo: 'valorIssqn', tipoTributo: 'ISSQN' },
  totalPisServicos: { calculo: 'valorPis', tipoTributo: 'ISSQN' },
  totalCofinsServicos: { calculo: 'valorCofins', tipoTributo: 'ISSQN' },
  totalNaoIncidencia: {
    calculo: 'valorTotalBruto',
    compoeTotal: '1',
    tipoTributo: 'ISSQN',
  },
  totalDeducaoBcServicos: {
    calculo: 'valorDeducaoBcIssqn',
    tipoTributo: 'ISSQN',
  },
  totalDescontoIncondicionado: {
    calculo: 'valorDescontoIncondicionado',
    tipoTributo: 'ISSQN',
  },
  totalDescontoCondicionado: {
    calculo: 'valorDescontoCondicionado',
    tipoTributo: 'ISSQN',
  },
  totalRetencaoIss: { calculo: 'valorRetencaoIss', tipoTributo: 'ISSQN' },
};

/**
 * Função para calcular os valores totais com base nos items do formulário e os campos definidos nas listas.
 */
export function calculaValoresTotais(form: { produtoServico: readonly CampoItem[] }): ValoresTotais {
  const valoresTotais: ValoresTotais = {};

  if (!form.produtoServico) return valoresTotais;

  let items = form.produtoServico;

  if (items) {
    if (items.length === 0) items = [{}]; // adicionar elemento vazio para inicializar os valores totais com zero no forEach

    Object.entries(CAMPOS_VALOR_TOTAL_TIPO_TRIBUTO).forEach(([nomeCampo, dadosCampo]) => {
      const campoTotal = { campo: nomeCampo as keyof typeof CAMPOS_VALOR_TOTAL_TIPO_TRIBUTO, ...dadosCampo! };

      items.forEach((item) => {
        valoresTotais[nomeCampo as keyof ValoresTotais] = calculaValorTotalCampo(campoTotal, item, valoresTotais);
      });
    });
  }

  // Cálculo campo valorTotalNfe
  const totaisASomar =
    (valoresTotais.totalProdutosServicos || 0) +
    (valoresTotais.totalIcmsStNfe || 0) +
    (valoresTotais.totalFreteNfe || 0) +
    (valoresTotais.totalSeguroNfe || 0) +
    (valoresTotais.totalOutrasDespesas || 0) +
    (valoresTotais.totalIpiNfe || 0) +
    (valoresTotais.totalNaoIncidencia || 0) +
    (valoresTotais.totalIiNfe || 0) +
    (valoresTotais.totalFcpRetidoStNfe || 0) +
    (valoresTotais.totalIpiDevolvidoNfe || 0);
  const totaisASubtrair = (valoresTotais.totalDescontoNfe || 0) + (valoresTotais.totalIcmsDesoneradoNfe || 0);

  valoresTotais.valorTotalNfe = totaisASomar - totaisASubtrair;

  return valoresTotais;
}

/**
 * Função para verificar se item está de acordo com total e calcular valor atual do "campo" de total.
 */
export function calculaValorTotalCampo(campoTotal: CampoValorTotal, item: CampoItem, valorTotal: ValoresTotais): number {
  if (campoTotal && item) {
    const totalPossuiTipoTributo = !!campoTotal.tipoTributo;
    const itemPossuiTipoTributo = !!item.tipoTributo;

    if (totalPossuiTipoTributo) {
      if (!itemPossuiTipoTributo || item.tipoTributo !== campoTotal.tipoTributo) return calculaValorTotalAtual(campoTotal, {}, valorTotal);
    }

    const totalPossuiCompoeTotal = !!campoTotal.compoeTotal;
    const itemPossuiValorCompoeTotalNfe = !!item.valorCompoeTotalNfe;

    if (totalPossuiCompoeTotal) {
      if (!itemPossuiValorCompoeTotalNfe || item.valorCompoeTotalNfe !== campoTotal.compoeTotal) return calculaValorTotalAtual(campoTotal, {}, valorTotal);
    }
  }

  return calculaValorTotalAtual(campoTotal, item, valorTotal);
}

/**
 * Função para calcular o valor atual de propriedade de valorTotal.
 *
 * Para o cálculo, utiliza o "campo" de total para selecionar a propriedade em valorTotal, e
 * o "calculo" de total para selecionar a propriedade em item.
 * O resultado é a soma desses dois valores selecionados ou um deles ou zero.
 */
export function calculaValorTotalAtual(campoTotal: CampoValorTotal, item: CampoItem, valorTotal: ValoresTotais): number {
  let valorAtual = 0;
  let valorItem = 0;

  if (campoTotal && campoTotal.campo && campoTotal.calculo) {
    valorAtual = toNumber((valorTotal && valorTotal[campoTotal.campo]) || '');
    valorItem = toNumber(item && item[campoTotal.calculo]);
  }

  return _.round(valorAtual + valorItem, 2);
}
