import _ from 'lodash';
import { createSelector } from 'reselect';

import api from '../../api';
import { createRootSelector, makeThunk } from '../../types';
import { Municipio, Municipios } from '../../types/api';
import { mergeIndexedBy } from '../../util/listas';
import { FilterableOptions } from '../../components/common/Campo/CampoProps';

import * as ufs from './ufs';

export const DADOS_RECEBIDOS = 'transacionais/municipios/DADOS_RECEBIDOS';

const INITIAL_STATE = {
  todas: [] as readonly Municipio[],
  porId: {} as { readonly [k: string]: Municipio },
  porCodigo: {} as { readonly [k: string]: Municipio },
} as const;

type MunicipioActions = ReturnType<typeof dadosRecebidos>;

export default function reducer(state = INITIAL_STATE, action?: MunicipioActions): typeof INITIAL_STATE {
  switch (action?.type) {
    case DADOS_RECEBIDOS:
      return {
        todas: action.dados.municipios,
        porId: mergeIndexedBy(state.porId, action.dados.municipios, '_id'),
        porCodigo: mergeIndexedBy(state.porCodigo, action.dados.municipios, 'codIbge'),
      };
    default:
      return state;
  }
}

// SELECTORS

export const municipiosSelector = createRootSelector((state) => state.transacionais.municipios);

export const todasMunicipiosSelector = createSelector(municipiosSelector, (municipios) => municipios.todas);

export const municipioPorCodigoSelector = createSelector(municipiosSelector, (municipios) => municipios.porCodigo);

export const municipiosParaSelect = createSelector(todasMunicipiosSelector, ufs.ufPorCodigoSelector, (todosMun, todasUfs) => {
  const options = _(todosMun)
    .sortBy('nome')
    .map(({ nome, codIbge }) => {
      const codUF = codIbge.substring(0, 2);
      const siglaUF = todasUfs[codUF]?.sigla;
      return { value: Number(codIbge), label: `${nome}${siglaUF && ` / ${siglaUF}`}` };
    })
    .value();

  return new FilterableOptions(options);
});

// ACTION CREATORS

export function dadosRecebidos(dados: Municipios) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaDados() {
  return makeThunk(async (dispatch, getState) => {
    if (todasMunicipiosSelector(getState()).length > 0) return;

    const r = await api.municipios.index();
    dispatch(dadosRecebidos(r));
  });
}
