import { call, put, takeLatest } from 'redux-saga/effects';
import { all, putResolve, select } from 'typed-redux-saga';
import { autofill, formValueSelector } from 'redux-form';
import { ARRAY_PUSH, CHANGE } from 'redux-form/lib/actionTypes';
import { Action, AnyAction } from 'redux';

import * as ufs from '../../reducers/transacionais/ufs';
import * as municipios from '../../reducers/transacionais/municipios';
import * as transportadores from '../../reducers/transacionais/transportadores';
import * as contas from '../../reducers/transacionais/contas';
import { carregaTela, finalizaCarregamentoTela, mudaVolumeAtivo } from '../../reducers/telas/nfe/emissao/transporte';

const PREFIXO_VOLUMES = 'transporte.volumes';
const TRANSPORTADOR = 'transporte.transportador';
const RETENCAO_ICMS = 'transporte.icmsRetencao';
const FRETE = 'transporte.modalidadeFrete';

const nfeSelector = formValueSelector('nfe');

export default function* sagaTransporteEmissao() {
  yield call(carregaDados);

  // cada vez que um novo volume for adicionado, tornamos ele o volume ativo
  yield takeLatest(
    (ac: AnyAction) => ac.type === ARRAY_PUSH && ac.meta.form === 'nfe' && ac.meta.field === PREFIXO_VOLUMES,
    function* (_ac: Action) {
      const arr: any[] = yield* select(nfeSelector, PREFIXO_VOLUMES);
      yield put(mudaVolumeAtivo(arr.length - 1));
    }
  );

  // cada vez que o campo transportador é prenchido com false, atualiza campos sem edição na tela
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field === TRANSPORTADOR && ac.payload === false,
    function* (_ac: Action) {
      yield call(reiniciaCamposDadosTransportador);
    }
  );

  // cada vez que o campo icmsRetencao é prenchido com false, atualiza campos sem edição na tela
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field === RETENCAO_ICMS && ac.payload === false,
    function* (_ac: Action) {
      yield call(reiniciaCamposRetencaoIcms);
    }
  );

  // cada vez que o campo modalidadeFrete é prenchido com 9 ou nulo, atualiza campos sem edição na tela
  yield takeLatest(
    (ac: AnyAction) => ac.type === CHANGE && ac.meta.form === 'nfe' && ac.meta.field === FRETE,
    function* (ac: AnyAction) {
      if (!ac.payload || ac.payload.value === '9') {
        yield call(reiniciaCamposDadosTransportador);
        yield call(reiniciaCamposRetencaoIcms);
      }
    }
  );
}

function* carregaDados() {
  yield put(carregaTela());

  // carregamento da tela é finalizado somente após os dados de município, uf, transportador e conta serem recebidos
  yield* all([
    putResolve(municipios.carregaDados()),
    putResolve(ufs.carregaDados()),
    putResolve(transportadores.carregaOpcoesSelect()),
    putResolve(contas.carregaDados()),
  ]);

  yield put(finalizaCarregamentoTela());
}

function* reiniciaCamposDadosTransportador() {
  yield put(autofill('nfe', 'transporte.nomeRazaoSocial', null));
  yield put(autofill('nfe', 'transporte.tipoDocumento', null));
  yield put(autofill('nfe', 'transporte.cnpj', null));
  yield put(autofill('nfe', 'transporte.cpf', null));

  yield put(autofill('nfe', 'transporte.nomeMunicipio', null));
  yield put(autofill('nfe', 'transporte.uf', null));
  yield put(autofill('nfe', 'transporte.codPais', null));
  yield put(autofill('nfe', 'transporte.nomePais', null));
}

function* reiniciaCamposRetencaoIcms() {
  yield put(autofill('nfe', 'transporte.nomeMunicipioOcorrencia', null));
  yield put(autofill('nfe', 'transporte.ufOcorrencia', null));
}
