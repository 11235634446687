import { call, put, take } from 'redux-saga/effects';

import { carregaTela, finalizaCarregamentoTela } from '../../reducers/telas/nfe/emissao/informacoesAdicionais';
import { ABRE_NOTA } from '../../reducers/telas/nfe/emissao/item';

export default function* sagaFormasPagamentoEmissao() {
  yield call(carregaDados);
}

function* carregaDados() {
  yield put(carregaTela());

  // o formulário de informações adicionais é atualizado após abrir nota
  // aguarda até abrir a nota para finalizar carregamento da janela
  yield take(ABRE_NOTA);

  yield put(finalizaCarregamentoTela());
}
