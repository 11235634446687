import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { permissaoSelector } from '../../reducers/gerais/whoami';
import { Permissao } from '../../util/permissoes';

import LinkContainerForPathParams from './LinkContainerForPathParams';

export default function Menu() {
  const permissao = useSelector(permissaoSelector);

  return <MenuItems permissao={permissao} />;
}

export function MenuItems({ permissao }: { permissao: Permissao }) {
  if (!permissao || permissao === 'deslogado') {
    return null;
  }

  let items = [
    <Item key="inicio" to="/" exact>
      {' '}
      <FontAwesomeIcon icon={['fas', 'home']} size="1x" /> INÍCIO{' '}
    </Item>,
  ];

  if (permissao !== 'sem_acesso') {
    items = [
      ...items,
      <Item key="emissao" to="/app/nfe/emissao">
        {' '}
        <FontAwesomeIcon icon={['far', 'file-alt']} size="1x" /> Emitir NF-e{' '}
      </Item>,
      <Item key="consulta" to="/app/nfe/consulta">
        {' '}
        <FontAwesomeIcon icon={['fas', 'search']} size="1x" /> Consultar NF-e{' '}
      </Item>,
      <Item key="cadastro" to="/app/cadastro">
        {' '}
        <FontAwesomeIcon icon={['fas', 'newspaper']} size="1x" /> Cadastros{' '}
      </Item>,
      <Item key="ferramentas" to="/app/ferramentas">
        {' '}
        <FontAwesomeIcon icon={['fas', 'wrench']} size="1x" /> Ferramentas{' '}
      </Item>,
    ];
  }

  items = [
    ...items,
    <Item key="ajuda" to="/app/ajuda">
      {' '}
      <FontAwesomeIcon icon={['fas', 'question']} size="1x" /> Ajuda{' '}
    </Item>,
  ];

  return <div>{items}</div>;
}

function Item({ children, ...linkProps }: { to: string; children?: ReactNode; exact?: boolean }) {
  return (
    <LinkContainerForPathParams {...linkProps}>
      <Button className="menu-button" bsStyle="link">
        {' '}
        {children}
      </Button>
    </LinkContainerForPathParams>
  );
}
