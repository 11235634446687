import './config/bugsnag';
import Bugsnag from '@bugsnag/browser';
import React from 'react';
import * as ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';

import App from './App';

import 'react-virtualized/styles.css';
import 'react-toggle/style.css';

import './css/index.css';

import './config/setup';

const reactRoot = document.getElementById('root');
const bugsnagReactPlugin = Bugsnag.getPlugin('react');

if (!reactRoot || !bugsnagReactPlugin) {
  // eslint-disable-next-line no-alert
  alert('Erro ao inicializar o app');
} else {
  const ErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React);

  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    reactRoot
  );
}
