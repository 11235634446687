/**
 * @file Este arquivo contém funções utilitárias específicas relacionadas a NFes.
 */
import { DeepPartial } from 'redux';
import produce, { Draft } from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';

import { Nfe, ProdutoServico } from '../types/apiNfe';
import { isNumeric } from '../components/common/Campo/validacoes';
import { dataHoraForm, dataHoraMongoose } from '../components/nfe/emissao/util/formatacaoDataHora';

import { anoMesDia, diaMesAno } from './formatacao';

/**
 * Lista de campos de lista que podem ter código igual a zero.
 */
const CAMPOS_LISTA_COM_CODIGO_ZERO: Array<keyof Nfe> = ['consumidorFinal', 'tipoOperacao', 'presencaComprador'];
const CAMPO_PRODUTO_SERVICO: Array<keyof ProdutoServico> = ['valorCompoeTotalNfe', 'origem', 'modalidadeDeterminacaoBcIcms', 'modalidadeDeterminacaoBcIcmsSt'];

/**
 * Lista de campos de dataHora que precisam ser convertidos para formato do formulário.
 */
const CAMPOS_DATA_HORA: Array<keyof Nfe> = ['dataHoraEmissao', 'dataHoraSaida', 'dataPrestacaoServico'];

/**
 * Função para adicionar/editar campos de nota recebida do backend
 * para preencher formulário de emissão de nota.
 */
export function ajustaNfeParaEdicao(nota: Nfe) {
  return produce(nota, (notaEditada) => {
    // série com número zero não aparece no campo série - converter para string zero
    if (notaEditada.serie === 0) notaEditada.serie = '0';

    // valores selecionados em lista que possui código 0 precisam ser String
    ajustaCamposCodigoZero(notaEditada, CAMPOS_LISTA_COM_CODIGO_ZERO);

    // valores selecionados em lista que possui código 0 em produtoServico precisam ser String
    if (notaEditada.produtoServico) {
      notaEditada.produtoServico.forEach((p) => {
        ajustaCamposCodigoZero(p, CAMPO_PRODUTO_SERVICO);

        if (p.lotesProduto) {
          p.lotesProduto.forEach((lp) => {
            ajustaSeNaoNulo(lp, ['fabricacaoData', 'validadeData'], diaMesAno);
          });
        }

        if (p.declaracoesImportacao) {
          p.declaracoesImportacao.forEach((di) => {
            ajustaSeNaoNulo(di, ['registroData', 'desembaracoData'], diaMesAno);
          });
        }
      });
    }

    // valores dataHora para formatar na representação do formulário
    ajustaSeCampoPresente(notaEditada, CAMPOS_DATA_HORA, dataHoraForm);

    // valor de transporte que possui código 0 precisa ser String
    const { transporte } = notaEditada;
    if (transporte) ajustaSeCampoPresente(transporte, ['modalidadeFrete'], String);

    // modifica o valor do campo anoMes da nfe do formato AAMM para MMAA
    if (notaEditada.documentosReferenciados) {
      // eslint-disable-next-line no-restricted-syntax
      for (const d of notaEditada.documentosReferenciados) {
        if (d.anoMes && d.anoMes.length === 4) {
          const { anoMes } = d;
          const ano = anoMes.substring(0, 2);
          const mes = anoMes.substring(2, 4);

          d.anoMes = mes + ano;
        }
      }
    }

    // modifica o valor do campo vencimento de duplicatas da nfe de AAAA-MM-DD para DDMMAAAA
    if (notaEditada.duplicatas) {
      notaEditada.duplicatas.forEach((d) => {
        ajustaSeNaoNulo(d, ['vencimento'], diaMesAno);
      });
    }
  });
}

/* eslint-disable no-restricted-syntax */
/**
 * Função para adicionar/editar campos do formulário da nota
 * que será enviado para o backend.
 */
export function ajustaNfeParaBackend<T extends DeepPartial<Nfe>>(nota: T): T {
  return produce(nota, (notaEditada) => {
    // valores dataHora para formatar na representação do formulário
    ajustaSeCampoPresente(notaEditada, CAMPOS_DATA_HORA, dataHoraMongoose);

    // modifica o valor do campo anoMes da nfe do formato MMAA para AAMM
    if (notaEditada.documentosReferenciados) {
      for (const d of notaEditada.documentosReferenciados) {
        if (d.anoMes && d.anoMes.length === 4) {
          const mesAno = d.anoMes;
          const ano = mesAno.substring(2, 4);
          const mes = mesAno.substring(0, 2);

          d.anoMes = ano + mes;
        }
      }
    }

    if (notaEditada.produtoServico) {
      for (const ps of notaEditada.produtoServico) {
        // modifica o valor dos campos de CFOP para remover a formatação
        if (typeof ps.cfop === 'string') {
          ps.cfop = ps.cfop.replace(/\D+/g, '');
        }

        if (ps.lotesProduto) {
          for (const lp of ps.lotesProduto) {
            ajustaSeNaoNulo(lp, ['fabricacaoData', 'validadeData'], anoMesDia);
          }
        }

        if (ps.declaracoesImportacao) {
          ps.declaracoesImportacao.forEach((di) => {
            ajustaSeNaoNulo(di, ['registroData', 'desembaracoData'], anoMesDia);
          });
        }

        // apaga dados dos campos de ipi se ipi não for verdadeiro
        if (!ps.ipi) {
          delete ps.cnpjProdutor;
          delete ps.codSeloControleIpi;
          delete ps.qtdSeloControleIpi;
          delete ps.situacaoTributariaIpi;
          delete ps.codigoEnquadramento;
          delete ps.tipoCalculoIpi;
          delete ps.aliquotaIpi;
          delete ps.valorUnidadeIpi;
        }
      }
    }

    // modifica o valor do campo vencimento de duplicatas da edição da nfe DDMMAAAA para AAAA-MM-DD
    if (notaEditada.duplicatas) {
      for (const d of notaEditada.duplicatas) {
        ajustaSeNaoNulo(d, ['vencimento'], anoMesDia);
      }
    }
  });
}

function ajustaCamposCodigoZero<T, K extends keyof T>(draft: WritableDraft<T>, campos: readonly K[]) {
  for (const f of campos) {
    if (f in draft) {
      draft[f] = (draft[f] === null ? '' : String(draft[f])) as any;
      if (!draft[f] && !isNumeric(draft[f])) {
        draft[f] = '' as any;
      }
    }
  }
}

function ajustaSeCampoPresente<T, K extends keyof T>(draft: WritableDraft<T>, campos: readonly K[], ajuste: (v: any) => Draft<T[K]>) {
  for (const f of campos) {
    if (f in draft) {
      draft[f] = ajuste(draft[f]);
    }
  }
}

function ajustaSeNaoNulo<T, K extends keyof T>(draft: WritableDraft<T>, campos: readonly K[], ajuste: (v: any) => Draft<T[K]>) {
  for (const f of campos) {
    if (draft[f]) {
      draft[f] = ajuste(draft[f]);
    }
  }
}

/* eslint-enable */
