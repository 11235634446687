import { push } from 'react-router-redux';
import _ from 'lodash';

import { makeThunk, State } from '../../../../types';
import * as servicosActions from '../../../transacionais/servicos';
import * as contasActions from '../../../transacionais/contas';
import api from '../../../../api';
import { obterTituloEdicao } from '../util';
import { alertaModal, confirmaModal } from '../../../../util/dialogos';
import { formToJS } from '../../../../util/listas';
import { Servico } from '../../../../types/api';
import { alertaObrigatorio, obterMsgAlerta, salvaRegistro } from '../salvaRegistro';

const DEFINE_TITULO = 'telas/cadastro/servicos/detalhes/DEFINE_TITULO';
const NOVO = 'telas/cadastro/servicos/detalhes/NOVO';
const DEFINE_ITEM_ATUAL = 'telas/cadastro/servicos/detalhes/DEFINE_ITEM_ATUAL';

const SERVICO_VAZIO = {
  codigo: '',
  descricao: '',
  itemListaServico: '',
  contaId: '',
  ncm: '00',
  ativo: true,
} as Readonly<Servico>;

const SERVICO_VALIDACAO = {
  codigo: false,
  descricao: false,
} as const;

const INITIAL_STATE = {
  titulo: '' as string,
  salvando: false,
  item: SERVICO_VAZIO,
  validacao: SERVICO_VALIDACAO,
  erro: null,
} as const;

type Actions = ReturnType<typeof defineTitulo | typeof defineItemAtual | typeof novoServico>;

export default function reducer(state = INITIAL_STATE, action: Actions): typeof INITIAL_STATE {
  switch (action.type) {
    case DEFINE_TITULO:
      return { ...state, titulo: action.titulo };
    case DEFINE_ITEM_ATUAL:
      return { ...state, item: { ...SERVICO_VAZIO, ...action.servico } };
    case NOVO:
      return { ...state, item: SERVICO_VAZIO };
    default:
      return state;
  }
}

// SELECTORS

export const dadosAtuaisSelector = (state: State) => state.telas.cadastro.servico.detalhes.item;

export const tituloSelector = (state: State): string => state.telas.cadastro.servico.detalhes.titulo;

// ACTION CREATORS

export function defineTitulo(titulo: string) {
  return { type: DEFINE_TITULO, titulo } as const;
}

export function novoServico() {
  return { type: NOVO } as const;
}

export function defineItemAtual(servico: Servico) {
  return { type: DEFINE_ITEM_ATUAL, servico } as const;
}

// THUNK ACTION CREATORS

/**
 * Carrega o servico com o ID selecionado na store transacional, e copia os dados para a tela.
 */
export function carregaRegistro(id: string) {
  return makeThunk(async (dispatch) => {
    const servico = await dispatch(servicosActions.carregaServico(id));
    await dispatch(defineItemAtual(servico));
    await dispatch(defineTitulo(obterTituloEdicao(servico.descricao)));

    await dispatch(contasActions.carregaDados());

    return servico;
  });
}

/**
 * Carrega novo servico e contas na store.
 */
export function carregaNovoServico() {
  return makeThunk(async (dispatch) => {
    await dispatch(novoServico());
    await dispatch(defineTitulo('Novo Serviço'));

    await dispatch(contasActions.carregaDados());
  });
}

export function salva(form: any) {
  const formInJS: Partial<Servico> = formToJS(form);

  const formSemDataCriacao = _.omit(formInJS, 'criadoEm');

  return makeThunk(async (dispatch) => {
    const alertasValidacao = await api.servicos.validar(formSemDataCriacao);

    if (alertaObrigatorio(alertasValidacao)) {
      throw new Error(obterMsgAlerta(alertasValidacao, 'servico'));
    }

    if (await salvaRegistro(alertasValidacao, 'servico')) {
      const r = await dispatch(servicosActions[formInJS._id ? 'atualiza' : 'cria'](formSemDataCriacao));

      dispatch(push(`/app/cadastro/servicos/${r._id}`)); // navega para a tela de detalhes

      return r;
    }
    throw new Error('Envio do formulário cancelado.');
  });
}

export function excluir() {
  return makeThunk(async (dispatch, getState) => {
    // busca o serviço na store, ou um mapa vazio se não encontrar
    const servico = dadosAtuaisSelector(getState());

    if (!servico._id) return;

    if (await confirmaModal('Confirma a exclusão deste serviço?')) {
      let registroExcluido = true;
      try {
        await api.servicos.excluir(servico._id);
      } catch (e) {
        registroExcluido = false;
        await alertaModal(e.message);
      }

      if (registroExcluido) {
        await alertaModal('Serviço excluído.');
        dispatch(push(`/app/cadastro/servicos`)); // navega para a página de serviços
      }
    } else {
      await alertaModal('Operação cancelada.');
    }
  });
}
