import _ from 'lodash';
import { createSelector } from 'reselect';

import api from '../../api';
import { Dispatch, State } from '../../types';
import { Pais, Paises } from '../../types/api';
import { indexBy } from '../../util/listas';
import { FilterableOptions } from '../../components/common/Campo/CampoProps';

const DADOS_RECEBIDOS = 'transacionais/paises/DADOS_RECEBIDOS';

const INITIAL_STATE = {
  todos: [] as readonly Pais[],
  porCodigo: {} as { readonly [k: string]: Pais },
} as const;

type PaisesActions = ReturnType<typeof dadosRecebidos>;

export default function reducer(state = INITIAL_STATE, action: PaisesActions): typeof INITIAL_STATE {
  switch (action.type) {
    case DADOS_RECEBIDOS:
      return { ...state, todos: action.dados.paises, porCodigo: indexBy(action.dados.paises, 'codBacen') };
    default:
      return state;
  }
}

// SELECTORS

export const paisesSelector = (state: State) => state.transacionais.paises;

export const todosPaisesSelector = createSelector(paisesSelector, (paises) => paises.todos);

export const paisPorCodigoSelector = createSelector(paisesSelector, (paises) => paises.porCodigo);

export const paisesParaSelect = createSelector(todosPaisesSelector, (paises) => {
  const options = _(paises)
    .sortBy((c) => c.nome)
    .map((c) => {
      const { codBacen } = c;
      return { value: codBacen, label: c.nome };
    })
    .value();

  return new FilterableOptions(options);
});

// ACTION CREATORS

export function dadosRecebidos(dados: Paises) {
  return { type: DADOS_RECEBIDOS, dados } as const;
}

// THUNK ACTION CREATORS

export function carregaDados() {
  return async function (dispatch: Dispatch) {
    const r = await api.paises.index();
    dispatch(dadosRecebidos(r));
  };
}
